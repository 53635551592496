import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Badge, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getTextColor } from '../../helpers/text-color';
import LangageChooser from '../langage-selector/LangageChooser';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { generateString } from '../../helpers/string-helper';
import { UserContext } from '../../contexts/app/UserContext';
/* eslint-disable */
const JobsInfo = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'top_menu' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

    const { planningCustomFields, jobs, isMultiJobUser, planningSelected } = useContext(PlanningContext);
    const { userPreferences, updateUserPreferences } = useContext(UserContext);
    const [userJobs, setUserJobs] = useState([]);
    const [defaultJobList, setDefaultJobList] = useState([]);
    const [defaultJobs, setDefaultJobs] = useState([]);

    useEffect(() => {
        const jobCustomField = planningCustomFields.find((i) => i.name === JOB_FIELD);
        const jobArray = jobs.map((jobName) => {
            const choiceObj = jobCustomField.type.choices[jobName];
            return {
                value: choiceObj.id,
                label: jobName,
                color: choiceObj?.color ?? '#060606',
            };
        });
        setUserJobs(jobArray);
        let defaultJobsChoices = [];
        if (!isMultiJobUser()) {
          defaultJobsChoices = [...jobArray];
        } else {
          console.log("🚀 ~ defaultJobsChoices=Object.keys ~ Object.keys(jobCustomField.type.choices):", Object.values(jobCustomField.type.choices))
          // can choose whatever job when user have all job right
          defaultJobsChoices = Object.keys(jobCustomField.type.choices).map((jobName)=> {
            const choiceObj = jobCustomField.type.choices[jobName];
            return {
                value: choiceObj.id,
                label: jobName,
                color: choiceObj?.color ?? '#060606',
            };
          });
        }
        setDefaultJobList(defaultJobsChoices);
        if (userPreferences?.planning_default_jobs) {
          // check existence
          const defaultJobsToSelect = [];
          const jobCustomFieldChoicesObj = Object.values(jobCustomField.type.choices);
          (userPreferences?.planning_default_jobs[planningSelected?.id] || []).forEach(jobId => {
            const jobObj = jobCustomFieldChoicesObj.find((job) => job.id === jobId);
            if (jobObj) {
              defaultJobsToSelect.push(jobId);
            }
          });
          if ((userPreferences?.planning_default_jobs[planningSelected?.id] || []).length !== defaultJobsToSelect.length) {
            updateUserPreferences({
              planning_default_jobs:{...userPreferences?.planning_default_jobs,
              [planningSelected.id]: defaultJobsToSelect}
            })
          }
          setDefaultJobs(defaultJobsToSelect);
        }
    }, [planningCustomFields, jobs]);


    const AllJobsChoiceId = useMemo(() => {
      const jobCustomField = planningCustomFields.find((i) => i.name === JOB_FIELD);
      const allJobsInfo = jobCustomField.type.choices[ALL_JOBS];
      return allJobsInfo.id;
    }, [planningCustomFields])

    const handleChangeDefaultJobs = useCallback(
      (value) => {
          // if (value.length === 0) {
          //     return;
          // }
          let jobsToBeSelected = [];

          if (defaultJobs.indexOf(AllJobsChoiceId) === -1 && value.indexOf(AllJobsChoiceId) > -1) {
              jobsToBeSelected = [AllJobsChoiceId];
          } else {
              jobsToBeSelected = [...value];
          }
          if (defaultJobs.indexOf(AllJobsChoiceId) > -1) {
              jobsToBeSelected = value.filter((i) => i !== AllJobsChoiceId);
          }
          setDefaultJobs(jobsToBeSelected);
          updateUserPreferences({
            planning_default_jobs:{...userPreferences?.planning_default_jobs,
            [planningSelected.id]: jobsToBeSelected}
          })
      },
      [defaultJobs, AllJobsChoiceId]
  );

    return (
        <div
            style={{
                position: 'absolute',
                top: -70,
                right: 0,
            }}
        >
            <div className="flex">

                {userJobs.length > 0 && (
                  <>
                    <div className="mr-2">
                        <label>{t('default_job')}: </label>
                        <Select
                        value={defaultJobs}
                        style={{minWidth: '8rem'}}
                        placeholder={generalTranslation('select')}
                        size="small"
                        mode="multiple"
                          options={defaultJobList}
                          onChange={(value) => handleChangeDefaultJobs(value)}
                        />
                        
                    </div>
                    <div className="mr-2">
                        <label>{t('user_jobs')}:</label>
                        {userJobs.map((job) => (
                            <Badge
                                key={generateString(4)}
                                className="ml-2"
                                count={job.label === ALL_JOBS ? generalTranslation('all_jobs') : job.label}
                                style={{ backgroundColor: job.color, color: getTextColor(job.color) }}
                            />
                        ))}
                    </div>
                  </>
                )}
                <div>
                    <LangageChooser />
                </div>
            </div>
        </div>
    );
};

export default JobsInfo;
