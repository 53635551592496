import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button, Table, Input } from 'antd';

import DeleteButton from '../common/tables/DeleteButton';
import EditButton from '../common/tables/EditButton';
import LoadingSpin from '../utils/LoadingSpin';
import { UserContext } from '../../contexts/app/UserContext';
import { searchData } from '../../helpers/array-filter';
// import { notificationInfo } from '../../helpers/notification';

const { Column } = Table;
const { Search } = Input;

const TeamList = ({ addTeam, editTeam, removeTeam }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [teamsList, setTeamsList] = useState([]);
    const { teamList } = useContext(UserContext);

    useEffect(() => {
        const onTeamListChange = async () => {
            setLoading(true);
            // notificationInfo('Mise à jour automatique des données', '')
            const newTeamsList = teamList.filter((team) => !team.single);
            if (newTeamsList.length === teamsList.length) {
                const cloneTeamsList = [...teamsList];
                teamsList.forEach((el, index) => {
                    newTeamsList.forEach((i) => {
                        if (i.id === el.id) {
                            cloneTeamsList[index] = i;
                        }
                    });
                });
                setTeamsList(cloneTeamsList);
            } else {
                setTeamsList(teamList.filter((team) => !team.single));
            }
            setLoading(false);
        };
        onTeamListChange();
    }, [teamList]);
    const handleSearch = (e) => {
        const filteredResult = searchData(teamList, e.target.value);
        setTeamsList(filteredResult);
    };
    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <div>
            <div className="flex mb-5 w-full justify-between">
                <div className="flex">
                    <Search
                        placeholder={t('general.search')}
                        onChange={handleSearch}
                        allowClear
                        style={{ width: 400 }}
                        className="mr-12"
                    />
                </div>
                <Button onClick={addTeam} type="primary" style={{ marginBottom: 16 }}>
                    {t('team_list.add_new_team')}
                </Button>
            </div>

            <Table dataSource={teamsList} rowKey="id">
                <Column
                    title="Nom"
                    dataIndex="name"
                    key="name"
                    sortDirections={['ascend', 'descend']}
                    sorter={(a, b) => a.name.localeCompare(b.name)}
                />
                <Column
                    title=""
                    key="actions"
                    width={100}
                    render={(text, record) => (
                        <>
                            <EditButton onClick={() => editTeam(record)} className="mr-2" />
                            {!record.admin ? <DeleteButton onClick={() => removeTeam(record)} /> : ''}
                        </>
                    )}
                />
            </Table>
        </div>
    );
};

TeamList.propTypes = {
    addTeam: PropTypes.func.isRequired,
    editTeam: PropTypes.func.isRequired,
    removeTeam: PropTypes.func.isRequired,
};

export default TeamList;
