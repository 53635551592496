import React, { forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import { Button, Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FolderService from '../../../services/folder.service';
import { requestError } from '../../../helpers/notification';
import { EXPLORER_ITEM_TYPE } from '../../../constants/ProjectExplorer';

const EditProjectModal = forwardRef(({data, onEditFinished}, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'projects' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [isVisible, setVisible] = useState(false);
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
      if (data) {
        form.setFieldsValue(data)
      }
    }, [data])
    

    const onFinish = (values) => {
      const [type, id] = data.key.split('-');
      if (type === EXPLORER_ITEM_TYPE.FOLDER) {
        FolderService.renameFolder(id, values.title).then(() => {
          if (onEditFinished) onEditFinished({...data, title: values.title});
          setVisible(false);
        })
        .catch((error) => {
            requestError(error, t('name_change_error'));
        });
      }
      if (type === EXPLORER_ITEM_TYPE.PLANNING) {

        FolderService.renamePlanning(id, values.title).then(() => {
          if (onEditFinished) onEditFinished({...data, title: values.title});
          setVisible(false);
        })
        .catch((error) => {
            requestError(error, t('name_change_error'));
        });
        
      }
    };

    return (
        <Modal
            title={t('change_name')}
            className="add-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} layout="vertical" defaultValue={data} onFinish={onFinish}>
                <Form.Item
                    name="title"
                    label={generalTranslation('name')}
                    rules={[{ required: true, message: t('name_required') }]}
                >
                    <Input type="text" placeholder={generalTranslation('name')} />
                </Form.Item>
                <Form.Item className="mb-0">
                    <Button type="primary" htmlType="submit">
                        {generalTranslation('edit')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

EditProjectModal.defaultProps = {
  data: {title: ''},
  onEditFinished: null
};

EditProjectModal.propTypes = {
  data: PropTypes.object,
  onEditFinished: PropTypes.func,
};

export default EditProjectModal;
