import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Row, Col, Button, Form, Input, Checkbox, DatePicker } from 'antd';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import FieldSetting from './FieldSetting';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import DeleteButton from '../../common/tables/DeleteButton';
import { generateString } from '../../../helpers/string-helper';

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const ColorPicker = styled.div`
    position: absolute;
    z-index: 2;
`;

// This component is used for customizing Gantt markers

const GanttMarkerSetting = ({ tabPreferences, setNewPreferences }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'gantt_setting' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [form] = Form.useForm();
    const defaultMarker = {
        show: true,
        showLinePicker: false,
        showLabelPicker: false,
        lineColor: '#FF0000',
        fontColor: '#000000',
        markerDate: moment().startOf('day'),
        markerName: t('new_marker'),
        markerClass: generateString(10),
    };
    const defaultPeriodMarker = {
        show: true,
        showLinePicker: false,
        showLabelPicker: false,
        lineColor: '#FF0000',
        fontColor: '#000000',
        markerStartDate: moment().startOf('day'),
        markerEndDate: moment().startOf('day'),
        markerName: t('new_marker'),
        markerClass: generateString(10),
    };
    // Ligne d'avancement
    const defaultAvancementParameter = {
        showProgressLine: false,
        progressLineColor: '#FF0000',
        progressDate: moment(),
    };
    const [avancementParameters, setAvancementParameters] = useState({});
    const [showAvancementLineColorPicker, setShowAvancementLineColorPicker] = useState(false);
    const [parameters, setParameters] = useState([{ ...defaultMarker }]);
    const parametersRef = useRef(null);
    const { planningSelected } = useContext(PlanningContext);

    useEffect(() => {
        if (avancementParameters && parameters) {
            parametersRef.current = {
                parameters,
                avancementParameters,
            };
            const newPreferences = {
                ...tabPreferences,
                gantt_markers: parametersRef.current.parameters,
                gantt_parameters: {
                    ...(tabPreferences.gantt_parameters || {}),
                    ...parametersRef.current.avancementParameters,
                },
            };
            setNewPreferences(newPreferences);
        }
    }, [parameters, avancementParameters]);

    useEffect(() => {
        if (tabPreferences?.gantt_markers) {
            setParameters(() => {
                const newValue = [
                    ...tabPreferences?.gantt_markers.map((i) => ({
                        ...defaultMarker,
                        ...i,
                        markerDate: moment(i.markerDate),
                    })),
                ];
                return newValue;
            });
            setAvancementParameters(() => {
                const { showProgressLine, progressLineColor, progressDate } = tabPreferences?.gantt_parameters;
                const newValue = {
                    ...defaultAvancementParameter,
                    showProgressLine,
                    progressLineColor,
                    progressDate,
                };
                return newValue;
            });
        }
    }, [planningSelected]);

    return (
        <>
            {parameters && avancementParameters && (
                <Form
                    form={form}
                    layout="horizontal"
                    name="planning_parameter_display"
                    initialValues={parameters}
                    style={{ minWidth: '650px' }}
                >
                    <div style={{ height: '60vh', overflowY: 'auto' }}>
                        <FieldSetting
                            title={t('show_progress_line')}
                            field="showProgressLine"
                            colorField="progressLineColor"
                            showPicker={showAvancementLineColorPicker}
                            showPickerFunction={setShowAvancementLineColorPicker}
                            isColumn={false}
                            hasDatePicker
                            datePickerField="progressDate"
                            parameters={avancementParameters}
                            setParameters={setAvancementParameters}
                        />
                        <Row>
                            <Col span={1} className="flex items-center">
                                &nbsp;
                            </Col>
                            <Col span={22} className="flex justify-evenly">
                                <Form.Item
                                    colon={false}
                                    className="w-1/3 m-0 mr-6"
                                    label={generalTranslation('name')}
                                />
                                <Form.Item colon={false} className=" w-1/5 m-0" label={t('line')} />
                                <Form.Item colon={false} className=" w-1/5 m-0" label={t('text')} />
                                <Form.Item colon={false} label="Date" className=" w-2/3 mr-2 m-0" />
                            </Col>
                            <Col span={1} className="flex items-center">
                                &nbsp;
                            </Col>
                        </Row>
                        {parameters.map((i, index) => (
                            /* eslint-disable-next-line */
                            <Row key={`${index}ganttmarker`} className="mb-3">
                                <Col span={1} className="flex items-center">
                                    <Checkbox
                                        checked={i.show}
                                        className="font-thin"
                                        style={{ fontFamily: 'inherit !important' }}
                                        onChange={(e) => {
                                            setParameters((old) => {
                                                const newVal = [...old];
                                                newVal[index].show = e.target.checked;
                                                return newVal;
                                            });
                                        }}
                                    />
                                </Col>
                                <Col span={22} className="flex justify-between items-center">
                                    <Form.Item className="w-1/3 m-0 mr-6">
                                        <Input
                                            value={i.markerName}
                                            onChange={(e) => {
                                                setParameters((old) => {
                                                    const newVal = [...old];
                                                    newVal[index].markerName = e.target.value;
                                                    return newVal;
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item className="w-1/5 m-0">
                                        <Input
                                            type="color"
                                            value={i.lineColor}
                                            style={{ width: '45px' }}
                                            onClick={(event) => {
                                                setParameters((old) => {
                                                    const newVal = [...old];
                                                    newVal[index].showLinePicker = true;
                                                    return newVal;
                                                });
                                                event.preventDefault();
                                            }}
                                        />
                                        {i.showLinePicker && (
                                            <ColorPicker>
                                                <ColorCover
                                                    onClick={() => {
                                                        setParameters((old) => {
                                                            const newVal = [...old];
                                                            newVal[index].showLinePicker = false;
                                                            return newVal;
                                                        });
                                                    }}
                                                />
                                                <SketchPicker
                                                    color={i.lineColor}
                                                    onChangeComplete={(color) => {
                                                        setParameters((old) => {
                                                            const newVal = [...old];
                                                            newVal[index].lineColor = color.hex;
                                                            return newVal;
                                                        });
                                                    }}
                                                    disableAlpha
                                                />
                                            </ColorPicker>
                                        )}
                                    </Form.Item>
                                    <Form.Item className=" w-1/5 m-0">
                                        <Input
                                            type="color"
                                            style={{ width: '45px' }}
                                            value={i.fontColor ?? '#000'}
                                            onClick={(event) => {
                                                setParameters((old) => {
                                                    const newVal = [...old];
                                                    newVal[index].showLabelPicker = true;
                                                    return newVal;
                                                });
                                                event.preventDefault();
                                            }}
                                        />
                                        {i.showLabelPicker && (
                                            <ColorPicker>
                                                <ColorCover
                                                    onClick={() => {
                                                        setParameters((old) => {
                                                            const newVal = [...old];
                                                            newVal[index].showLabelPicker = false;
                                                            return newVal;
                                                        });
                                                    }}
                                                />
                                                <SketchPicker
                                                    color={i.fontColor ?? '#000'}
                                                    onChangeComplete={(color) => {
                                                        setParameters((old) => {
                                                            const newVal = [...old];
                                                            newVal[index].fontColor = color.hex;
                                                            return newVal;
                                                        });
                                                    }}
                                                    disableAlpha
                                                />
                                            </ColorPicker>
                                        )}
                                    </Form.Item>
                                    <Form.Item className=" w-2/3 mr-2 mb-0">
                                        {i.markerStartDate ? (
                                            <DatePicker.RangePicker
                                                format={window.dateStringFormat.concat(' HH:mm')}
                                                allowClear={false}
                                                showTime={{
                                                    format: 'HH:mm',
                                                    defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                                }}
                                                value={[
                                                    i.markerStartDate ? moment(i.markerStartDate) : null,
                                                    i.markerEndDate ? moment(i.markerEndDate) : null,
                                                ]}
                                                onOk={(value) => {
                                                    setParameters((old) => {
                                                        const newVal = [...old];
                                                        [newVal[index].markerStartDate, newVal[index].markerEndDate] =
                                                            value;
                                                        return newVal;
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <DatePicker
                                                format={window.dateStringFormat.concat(' HH:mm')}
                                                allowClear={false}
                                                showTime={{
                                                    format: 'HH:mm',
                                                    defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                                }}
                                                value={i.markerDate ? moment(i.markerDate) : null}
                                                onChange={(value) => {
                                                    setParameters((old) => {
                                                        const newVal = [...old];
                                                        newVal[index].markerDate = value;
                                                        return newVal;
                                                    });
                                                }}
                                                onSelect={(value) => {
                                                    setParameters((old) => {
                                                        const newVal = [...old];
                                                        newVal[index].markerDate = value;
                                                        return newVal;
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={1} className="flex justify-end items-center">
                                    <DeleteButton
                                        onClick={() =>
                                            setParameters((old) => {
                                                const newVal = [...old];
                                                newVal.splice(index, 1);
                                                return newVal;
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        ))}
                    </div>

                    <Button
                        type="primary"
                        onClick={() =>
                            setParameters((old) => {
                                const newVal = [...old, { ...defaultMarker }];
                                return newVal;
                            })
                        }
                    >
                        {t('add_marker')}
                    </Button>
                    <Button
                        type="primary"
                        className="ml-2"
                        onClick={() =>
                            setParameters((old) => {
                                const newVal = [...old, { ...defaultPeriodMarker }];
                                return newVal;
                            })
                        }
                    >
                        {t('add_period_marker')}
                    </Button>
                </Form>
            )}
        </>
    );
};

GanttMarkerSetting.propTypes = {
    setNewPreferences: PropTypes.func.isRequired,
    tabPreferences: PropTypes.object.isRequired,
};
export default GanttMarkerSetting;
