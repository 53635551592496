import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Modal, Select, Tooltip, Row, Button, Empty, Typography, Progress } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { PrintTemplatesMenuContext } from '../../contexts/app/PrintTemplatesMenuContext';
import { PrintMenuContext } from '../../contexts/app/PrintMenuContext';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { TabContext } from '../../contexts/app/TabContext';
import PrintTemplateService from '../../services/print-template.service';
import { ReactComponent as PrintSvg } from '../../assets/icons/print.svg';
import { downloadPDF } from '../../helpers/pdf';
import { NEED_TYPE } from '../../constants/Needs';


const { Text } = Typography;

const blobStream = require('blob-stream');

// import { zoomGanttToFit } from '../../helpers/zoom';
/* eslint-disable camelcase */
const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const HistogramTabPrintMenu = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'modal_print' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const needsTranslation = useTranslation('translation',  { keyPrefix: 'needs' }).t;
    
    const { printTemplates } = useContext(PrintTemplatesMenuContext);
    const { onModalClose, isModalVisible, setIsModalVisible } = useContext(PrintMenuContext);
    const { planningSelected, ganttHistogramChartNeedList } =
        useContext(PlanningContext);
    const { activeTab } = useContext(TabContext);
    const [form] = Form.useForm();
    const [printLoading, setPrintLoading] = useState(false);
    const [progressLabel, setProgressLabel] = useState(false);
    const [progressCount, setProgressCount] = useState(0);

    const HEADER_FOOTER_HEIGHT = 100;
    const HEADER_FOOTER_PADDING = 10;

    const showModal = () => {
        // refreshPrintTemplate();
        setIsModalVisible(true);
    };
    
    const paperSizes = ['A3', 'A4', 'A5', 'Letter', 'Legal', 'Tabloid'];
    // const paperSizes = ['A0', 'A1', 'A2','A3', 'A4', 'A5', 'Letter', 'Ledger', 'Arch_C', 'Arch_D', 'Arch_E'];
    // const taskCountBySize = {
    //     A3: 46,
    //     A4: 29,
    //     A5: 19,
    // };
    const paperHeight = {
        A0: 840,
        A1: 594,
        A2: 420,
        A3: 297,
        A4: 210,
        A5: 148,
        Letter: 215,
        Ledger: 279,
        Arch_C: 457,
        Arch_D: 609,
        Arch_E: 914,
        Legal: 216,
        Tabloid: 279,
    };
    const paperWidth = {
        A0: 1188,
        A1: 840,
        A2: 594,
        A3: 420,
        A4: 297,
        A5: 210,
        Letter: 279,
        Ledger: 431,
        Arch_C: 609,
        Arch_D: 914,
        Arch_E: 1219,
        Legal: 356,
        Tabloid: 432,
    };

  
    const getTemplateInfoValue = (printTemplateInfo) => {
        switch (printTemplateInfo.type) {
            case 'DATE': {
                const today = new Date();
                return today.toLocaleDateString();
            }
            case 'PROJECT_NAME':
                return planningSelected.name;
            default:
                return printTemplateInfo.value;
        }
    };

    const DPI_CONVERTER = 144 / 72;
    const HISTOGRAM_PDF_HEADER_FOOTER_PADDING = 10 / DPI_CONVERTER;
    const HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT = HEADER_FOOTER_HEIGHT / DPI_CONVERTER;

    /* Computing the position of the image in a container */
    const getImgPosition = ({ alignment, position }, x, y, h, w) => {
        const imgPosition = {};
        switch (alignment) {
            case 'CENTER':
                imgPosition.x = x + w / 4;
                break;
            case 'RIGHT':
                imgPosition.x = x + w / 2 - HISTOGRAM_PDF_HEADER_FOOTER_PADDING;
                break;
            default:
                imgPosition.x = x + HISTOGRAM_PDF_HEADER_FOOTER_PADDING;
                break;
        }
        switch (position) {
            case 'CENTER':
                imgPosition.y = y + h / 4;
                break;
            case 'BOTTOM':
                imgPosition.y = y + h / 2 - HISTOGRAM_PDF_HEADER_FOOTER_PADDING;
                break;
            default:
                imgPosition.y = y + HISTOGRAM_PDF_HEADER_FOOTER_PADDING;
                break;
        }
        return imgPosition;
    };

    /* Computing the position of the text in a container */
    const getTextPosition = ({ alignment, position }, doc, x, y, h, w, text) => {
        const textPosition = {};
        switch (alignment) {
            case 'CENTER':
                textPosition.x = x + w / 2 - doc.widthOfString(text) / 2;
                break;
            case 'RIGHT':
                textPosition.x = x + w - doc.widthOfString(text) - HISTOGRAM_PDF_HEADER_FOOTER_PADDING;
                break;
            default:
                textPosition.x = x + HISTOGRAM_PDF_HEADER_FOOTER_PADDING;
                break;
        }
        switch (position) {
            case 'CENTER':
                textPosition.y = y + (1 / 2) * (h - doc.heightOfString(text));
                break;
            case 'BOTTOM':
                textPosition.y = y + (h - doc.heightOfString(text)) - HISTOGRAM_PDF_HEADER_FOOTER_PADDING;
                break;
            default:
                textPosition.y = y + HISTOGRAM_PDF_HEADER_FOOTER_PADDING;
                break;
        }
        return textPosition;
    };

    /* Printing template informations on PDF document */
    const generatePrintTemplateDoc = (
        printTemplateInfo,
        doc,
        xContainer,
        yContainer,
        heightContainer,
        widthContainer,
        pageInfo
    ) => {
        if (printTemplateInfo) {
            const templateInfo = printTemplateInfo.type !== 'PAGE' ? getTemplateInfoValue(printTemplateInfo) : pageInfo;
            const isImage = printTemplateInfo.type === 'IMAGE';
            if (isImage) {
                const templateInfoPosition = getImgPosition(
                    printTemplateInfo,
                    xContainer,
                    yContainer,
                    heightContainer,
                    widthContainer
                );
                doc.image(templateInfo, templateInfoPosition.x, templateInfoPosition.y, {
                    fit: [widthContainer / 2, heightContainer / 2],
                    align: printTemplateInfo.alignment.toLowerCase(),
                    valign: printTemplateInfo.position.toLowerCase(),
                });
            } else {
                const templateInfoPosition = getTextPosition(
                    printTemplateInfo,
                    doc,
                    xContainer,
                    yContainer,
                    heightContainer,
                    widthContainer,
                    templateInfo
                );
                // lineBreak important so no new page added
                doc.text(templateInfo, templateInfoPosition.x, templateInfoPosition.y, { lineBreak: false });
            }
        }
    };
    
    /**
     * /**
     * Search of SVG element of histogram
     * Compute correct position in PDF from left, and viewBox (height of chart) in PDF
     * Search for Yaxis SVG and compute position in PDF
     * WARNING: PDFKIT library is generating 72dpi PDF and DHTMLX export module is 144 dpi
     * So to make correct position in pixels we divide all measures by 2 (144dpi/72dpi), note that SVGToPDFKit plugin can compute position in pixel by assumePt: false
     * For other elements (text, img) [header and footer, legend], PDFKit is usin pt (PostScript) unit, but we use the same converter because 1pt = 1/72 inch and 1px = 1/72 inch (in 72 dpi).
     * @param {*} printTemplate The template to use for header and footer
     * @param {*} pageInfo Page indicator
     * @param {*} paper The paper format used
     * @param {*} histogramSVG The initial SVG of the histogram (note that the histogram involve according to tasks in Gantt chart, when printing we iterate over tasks)
     * @param {*} canFit Boolean to know if the histogram can be added to the last page
     * @returns Promise buffer of histogram PDF
     */
    const generateHistogramPDFBlob = (printTemplate, pageInfo, paper, histogramSVG, canFit) =>
        new Promise((resolve) => {
            const histogramContainer = document.getElementById('gantt-histogram-container');
            // const histogramSVG = document.querySelector('#gantt-histogram-container .recharts-surface');
            if (histogramSVG) {
                const histogramOptions = document.getElementById('gantt-histogram-options');

                // creating PDFKit document by params
                const doc = new window.PDFDocument({ size: paper, layout: 'landscape' });
                // blobStream() is for handling stream when creating PDF
                const stream = doc.pipe(blobStream());
                // => widthPt = widthPx = widthMM / (25.4 [inch in MM] / 72 [PDF PPI]);
                const converter = 25.4 / 72;
                
                const pageWidthPt = paperWidth[paper] / converter;
                const pageHeightPt = paperHeight[paper] / converter;
                const containerWidth = pageWidthPt / 3;
                if (!canFit) {
                    // adding header
                    let headerX = 0;
                    const headerY = 0;

                    // HEADER LEFT
                    doc.fontSize(8);
                    generatePrintTemplateDoc(
                        printTemplate.headerLeft,
                        doc,
                        headerX,
                        headerY,
                        HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT,
                        containerWidth,
                        pageInfo
                    );
                    headerX += containerWidth;
                    // HEADER CENTER
                    generatePrintTemplateDoc(
                        printTemplate.headerCenter,
                        doc,
                        headerX,
                        headerY,
                        HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT,
                        containerWidth,
                        pageInfo
                    );
                    headerX += containerWidth;
                    // HEADER RIGHT
                    generatePrintTemplateDoc(
                        printTemplate.headerRight,
                        doc,
                        headerX,
                        headerY,
                        HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT,
                        containerWidth,
                        pageInfo
                    );
                }
                // compute histogram position in PDF
                // if no scroll in Gantt, we need to re-compute dimension of histogram because width of gantt will be increased when printing
                    const histogramWidthInPDF = pageWidthPt;
                    const histogramSVGConfig = {
                        width: histogramWidthInPDF,
                        preserveAspectRatio: 'xMinYMin meet',
                        assumePt: false,
                    };
                    const histogramScaleCoefficient = histogramContainer.offsetWidth / histogramContainer.offsetHeight;
                    const histogramHeightInPDF = histogramWidthInPDF / histogramScaleCoefficient;

                // Legend
                const legends = ganttHistogramChartNeedList.filter((i) => i.selected);
                const XLegend = HEADER_FOOTER_PADDING;
                const LEGEND_SQUARE_HEIGHT = 10;
                const LEGEND_SPACE_BETWEEN = 20;
                const LEGEND_WIDTH = 150;
                const LEGEND_FONT_SIZE = 8;
                let YLegend = canFit
                    ? HEADER_FOOTER_PADDING
                    : HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT + HEADER_FOOTER_PADDING;
                const SINGLE_LEGEND_HEIGHT = LEGEND_SQUARE_HEIGHT + LEGEND_FONT_SIZE / 2 + HEADER_FOOTER_PADDING;
                let legendType = '';
        if (legends.length > 0) {
            const {needType} = legends[0];
            switch (needType) {
                case NEED_TYPE.CONSUMMABLE:
                    legendType = needsTranslation('consummable')
                    break;
                case NEED_TYPE.NON_CONSUMMABLE:
                    legendType = needsTranslation('non_consummable')
                    break;
            
                default:
                    break;
            }
        }
                doc.fill('#000').stroke();
                // drawing text
                doc.fontSize(LEGEND_FONT_SIZE);
                doc.font('Helvetica-Bold').text(`${t('legends')} :${legendType}`, XLegend, YLegend, { lineBreak: false });
                doc.font('Helvetica').
                YLegend += LEGEND_FONT_SIZE / 2 + HEADER_FOOTER_PADDING;
                const ALL_LEGEND_HEIGHT = canFit ? histogramHeightInPDF -
                SINGLE_LEGEND_HEIGHT:
                    histogramHeightInPDF +
                    HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT +
                    HEADER_FOOTER_PADDING -
                    4 * SINGLE_LEGEND_HEIGHT;
                const isLegendFit =
                legends.reduce((previous) => previous + SINGLE_LEGEND_HEIGHT, YLegend) <
                ALL_LEGEND_HEIGHT;
                console.log("🚀 ~ newPromise ~ isLegendFit:", isLegendFit, ALL_LEGEND_HEIGHT)
                // isolating in a function because of legend confirmation
                const printLegends = () => {
                    legends.forEach((legend) => {
                        if (YLegend < ALL_LEGEND_HEIGHT) {
                            
                            doc.fill('#000').stroke();
                            // drawing text
                            doc.fontSize(LEGEND_FONT_SIZE);
                            doc.text(legend.name, XLegend, YLegend, { lineBreak: false });
                            // drawing square
                            const squareX = XLegend + LEGEND_WIDTH;
                            const squareY = YLegend + LEGEND_SQUARE_HEIGHT / 2 - LEGEND_FONT_SIZE / 2;
                            doc.rect(squareX, squareY, LEGEND_SQUARE_HEIGHT, LEGEND_SQUARE_HEIGHT).fillAndStroke(
                                legend.color,
                                '#000'
                            );
                            YLegend += LEGEND_SQUARE_HEIGHT + LEGEND_FONT_SIZE / 2 + HEADER_FOOTER_PADDING;
                        }
                    });
                    if (!isLegendFit) {
                        const legendTextX = XLegend + LEGEND_SPACE_BETWEEN;
                        const legendTextY = YLegend + LEGEND_SQUARE_HEIGHT / 2 - LEGEND_FONT_SIZE / 2;
                        doc.fontSize(LEGEND_FONT_SIZE);
                        doc.fill('#000').stroke();
                        doc.text(t('more_legend'), legendTextX, legendTextY, { lineBreak: false });
                    }
                };
                // isolating in a function because of legend confirmation
                const printHistogram = () => {
                    // adding histogram at correct position
                    const histogramSVGToDraw = document.querySelector('#gantt-histogram-container .recharts-surface').cloneNode(true);;

                    window.SVGtoPDF(
                        doc,
                        histogramSVGToDraw,
                        histogramOptions.offsetWidth / DPI_CONVERTER,
                        canFit ? 0 : HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT + HISTOGRAM_PDF_HEADER_FOOTER_PADDING,
                        histogramSVGConfig
                    );

                    if (!canFit) {
                        let footerX = 0;
                        const footerY = pageHeightPt - HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT;
                        // FOOTER LEFT
                        doc.fontSize(8);
                        generatePrintTemplateDoc(
                            printTemplate.footerLeft,
                            doc,
                            footerX,
                            footerY,
                            HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT,
                            containerWidth,
                            pageInfo
                        );
                        footerX += containerWidth;
                        // FOOTER CENTER
                        generatePrintTemplateDoc(
                            printTemplate.footerCenter,
                            doc,
                            footerX,
                            footerY,
                            HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT,
                            containerWidth,
                            pageInfo
                        );
                        footerX += containerWidth;
                        // FOOTER RIGHT
                        generatePrintTemplateDoc(
                            printTemplate.footerRight,
                            doc,
                            footerX,
                            footerY,
                            HISTOGRAM_PDF_HEADER_FOOTER_HEIGHT,
                            containerWidth,
                            pageInfo
                        );
                    }
                    // end of PDF
                    doc.end();
                    // handling the result of PDF making
                    stream.on('finish', () => {
                        // get a blob you can do whatever you like with
                        const blob = stream.toBlob('application/pdf');
                        blob.arrayBuffer().then((res) => resolve(res));
                    });
                };
                if (!isLegendFit) {
                    Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        content:
                            t('print_legend_warning'),
                        okText: t('print_legend_anyway'),
                        cancelText: t('no_print_legend'),
                        centered: true,
                        onOk() {
                            printLegends();
                            printHistogram();
                        },

                        onCancel() {
                            printHistogram();
                        },
                    });
                } else {
                    printLegends();
                    printHistogram();
                }
            }
        });

    const submitForm = async (values) => {
        setPrintLoading(true);
        // getting current chart svg state
        const histogramSVG = document.querySelector('#gantt-histogram-container .recharts-surface').cloneNode(true);

        setProgressCount(0);
        setProgressLabel('');
        const printTemplate = await PrintTemplateService.showTemplate(values.printTemplate);
        // get table column width for gantt
        // let histogramPdfBuffer;
        const canFit = false;
        const totalPages = 1;
            const histogramPdfBuffer = await generateHistogramPDFBlob(
                printTemplate,
                `Page ${totalPages} / ${totalPages}`,
                values.format,
                histogramSVG.outerHTML,
                canFit
            );
            console.log('eto ve')

        
        // must have something to merge
        if (histogramPdfBuffer) {
            setProgressLabel(t('compiling'));
            downloadPDF(histogramPdfBuffer, activeTab.name);
            setProgressCount(100);
        }
        setProgressLabel(t('complete'));

        setPrintLoading(false);
    };

    return (
        <>
            <Tooltip title={t('print_chart')}>
                <QuickButton
                    type="button"
                    className="inline-block bg-primary text-center mr-3 align-top"
                    onClick={showModal}
                >
                    <PrintSvg className="mx-auto my-auto" />
                </QuickButton>
            </Tooltip>

            <Modal
                title={t('print_chart')}
                className="print-modal"
                centered
                open={isModalVisible}
                onCancel={onModalClose}
                footer={null}
                width={390}
                maskClosable={false}
                destroyOnClose
                maskStyle={{ backdropFilter: 'blur(4px)' }}
            >
                {printTemplates.length ? (
                    <Row gutter={[12, 0]} className="w-full">
                        <Col className="w-full">
                            <Form form={form} layout="vertical" onFinish={submitForm}>
                                <div className="flex w-full justify-between">
                                    <Form.Item
                                        style={{ width: 160 }}
                                        className="mb-0"
                                        name="printTemplate"
                                        label={t('print_template')}
                                        rules={[{ required: true, message: generalTranslation('required_field') }]}
                                        initialValue={printTemplates[0].id}
                                    >
                                        <Select
                                            options={printTemplates.reduce((agg, printTemplate) => {
                                                agg.push({ label: printTemplate.name, value: printTemplate.id });

                                                return agg;
                                            }, [])}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: 160 }}
                                        className="mb-0"
                                        name="format"
                                        label={t('document_format')}
                                        rules={[{ required: true, message: generalTranslation('required_field') }]}
                                        initialValue="A3"
                                    >
                                        <Select
                                            options={paperSizes.reduce((agg, documentType) => {
                                                agg.push({ label: documentType, value: documentType });

                                                return agg;
                                            }, [])}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="m-2">
                                    <Text type="secondary">{t('print_tip')}</Text>
                                </div>
                                <div className="mb-2">
                                    <p>{progressLabel}</p>
                                    <Progress
                                        strokeColor="#FBBE3F"
                                        percent={progressCount}
                                        size="small"
                                        showInfo={false}
                                    />
                                </div>
                                <Form.Item className="mb-0">
                                    <div className="flex justify-between">
                                        <Button htmlType="button" className="mr-2" onClick={onModalClose}>
                                            {generalTranslation('cancel')}
                                        </Button>
                                        <Button type="primary" htmlType="submit" loading={printLoading}>
                                            {t('print')}
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                ) : (
                    <Empty description={t('no_template')} />
                )}
            </Modal>
        </>
    );
};

export default HistogramTabPrintMenu;
