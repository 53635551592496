import * as Sentry from '@sentry/browser';
import { useHotkeys } from 'react-hotkeys-hook';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { clone, intersection, sortBy } from 'lodash';
import { Gantt as dxhtmlGantt } from 'dhtmlx-gantt';
import styled, { createGlobalStyle } from 'styled-components';
import { Button, Popconfirm } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ActivityService from '../../services/activity.service';
import { UserContext } from '../../contexts/app/UserContext';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import DrawerActivity from './DrawerActivity';
import { notificationError, requestError } from '../../helpers/notification';
import { formatGanttTask, generateLinkNodes, ACTIVITY_STATE } from '../../helpers/planning';
import {
    warningBase64,
    lockBase64,
    circleBase64,
    checkCircleBase64,
    nonConsumableIcon,
    consumableIcon,
} from '../../helpers/icons';
import GanttToolbar from './GanttToolbar';
import TaskStripes from '../../helpers/task-stripes';
import GanttConfigurations, { GANTT_CUSTOM_PROJECT } from './gantt_config';
import MultipleActivityEdit from './drawer/MultipleActivityEdit';
import GanttEvents, { saveScrollState } from './gantt_events';
import GanttCustomEditors from './gantt_custom_editors';
import GanttTaskLayers from './gantt_layers';
import { quantityUnits } from './activity_config';
import MultipleLinkCreation from './drawer/MultipleLinkCreation';
import MultipleActivityDuplication from './drawer/MultipleActivityDuplication';
import { generateString } from '../../helpers/string-helper';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import linkStyle from '../../helpers/link-style';
import { TabContext } from '../../contexts/app/TabContext';
import DefaultUnifiedFieldId from '../../constants/DefaultPlanningField';
import { getArrayInListByCondition } from '../../helpers/tree-helper';
import { NeedsContext } from '../../contexts/needs/NeedsContext';
import { PlanningNeedsContext } from '../../contexts/needs/PlanningNeedsContext';
import { getTextColor } from '../../helpers/text-color';
import { NEED_TYPE } from '../../constants/Needs';

const { showTaskActions, addGanttEvents } = GanttEvents;
const { ganttCustomEditors } = GanttCustomEditors;
const { DEFAULT_COMUMNS, setGanttConfigurations } = GanttConfigurations;

const GanttWrapper = styled.div`
    height: 100%;
    width: 100%;
    .gantt_grid_scale {
        color: #2a2f35;
        background-color: #f8f8f8;
    }

    .gantt_grid_scale .gantt_grid_head_cell {
        font-weight: bold;
        opacity: 1;
        color: #2a2f35;
        background-color: #f8f8f8;
    }

    .gantt_grid_scale > .gantt_grid_head_add {
        background-image: none;
        pointer-events: none;
    }

    .gantt_task_line {
        &.gantt_milestone {
            background-color: #2a2f35;
            border: 0;
        }

        &.no_link {
            &.gantt_milestone {
                background-color: #fbbe3f;
            }

            .gantt_link_control {
                display: none;
            }
        }
    }
    ${(props) =>
        props.hasBaseLine
            ? `
    .gantt_task_line, .gantt_line_wrapper, .freeSlack, .totalSlack {
        ${props.marginTop ? 'margin-top:-'.concat(props.marginTop, 'px;') : ''}
    }
    .gantt_task_link .gantt_link_arrow {
        ${props.marginTop ? 'margin-top:-'.concat(props.marginTop + 3, 'px;') : ''}
    }
    `
            : ``}
`;

const TasksStripesCSS = createGlobalStyle((props) => TaskStripes.buildStyles(props.tasks));
const LinkBackgroundCSS = createGlobalStyle((props) => linkStyle.buildStyles(props.links));
const CriticalTaskStyle = createGlobalStyle`
.gantt_task_line.critical-task, .gantt_task_line.critical-task .gantt_task_content {
    box-shadow: 0 0 0 3px ${(props) => props.color ?? 'red'} !important;
}
`;

const TaskLevelColor = createGlobalStyle`
.simple-activity .gantt_cell_tree .gantt_tree_indent:nth-of-type(${(props) =>
    +props.level + 1}), .gantt_row_project .gantt_cell_tree .gantt_tree_indent:nth-of-type(${(props) =>
    +props.level + 1}), .gantt-resource-task .gantt_tree_indent:nth-of-type(${(props) => +props.level + 1}), .level-${(
    props
) => props.level} {
    ${(props) => (props.show ? `background: ${props.color} !important;` : '')} 
    ${(props) => (props.show ? `color: ${props.fontColor} !important;` : '')} 
    
}

.level-${(props) => props.level}.gantt-resource-task {
    ${(props) =>
        props.show
            ? `background: ${props.color.concat(40)} !important;
    color: black !important;
    `
            : ''} 
    
}
.level-${(props) => props.level} .gantt_tree_content {
    ${(props) => (props.fontSize && props.show ? `font-size: ${props.fontSize} !important;` : '')} 
    ${(props) => (props.fontStyle === 'bold' && props.show ? `font-weight: 700 !important;` : '')} 
    ${(props) => (props.fontStyle === 'italic' && props.show ? `font-style: ${props.fontStyle} !important;` : '')} 
    ${(props) =>
        props.fontStyle === 'bold-italic' && props.show
            ? `font-style: italic !important; font-weight: 700 !important;`
            : ''} 
}
`;
const TaskResourceColor = createGlobalStyle`
.resource-task-${(props) => props.name.replace(/ /g, '')} {
    ${(props) => `background: ${props.color} !important; color: ${getTextColor(props.color)};`} 
    
}
.resource-subtask-${(props) => props.name.replace(/ /g, '')} {
    ${(props) => `background: ${props.color.concat('90')} !important;`} 
    
}
`;

const SimpleTaskColor = createGlobalStyle`
.simple-activity {
    ${(props) => (props.show ? `background: ${props.color} !important;` : '')} 
    ${(props) => (props.show ? `color: ${props.fontColor} !important;` : '')} 
    
}

.simple-task.gantt-resource-task {
    ${(props) =>
        props.show && props.color
            ? `background: ${props.color.concat(40)} !important;
    color: black !important;
    `
            : ''} 
    
}

.simple-activity .gantt_tree_content {
    ${(props) => (props.fontSize && props.show ? `font-size: ${props.fontSize} !important;` : '')} 
    ${(props) => (props.fontStyle === 'bold' && props.show ? `font-weight: 700 !important;` : '')} 
    ${(props) => (props.fontStyle === 'italic' && props.show ? `font-style: ${props.fontStyle} !important;` : '')} 
    ${(props) =>
        props.fontStyle === 'bold-italic' && props.show
            ? `font-style: italic !important; font-weight: 700 !important;`
            : ''} 
}
`;

const MarkerStyle = createGlobalStyle`
.${(props) => props.class}, .${(props) => props.class}.gantt_marker_content {
    background:  ${(props) => props.lineColor ?? 'red'} !important;
    color:  ${(props) => props.fontColor ?? 'red'} !important;
}
`;

const GridHeaderColor = createGlobalStyle`
.gantt_grid_scale, .gantt_grid_head_cell {
    ${(props) => `background: ${props.gridBackgroundColor} !important;`} 
    ${(props) => `color: ${props.gridTextColor} !important;`} 
    
}
`;

window.ganttInstance = null;
window.selectedSimpleTasks = [];
// used for disabling saving scroll to preference (printing)
window.saveScrollState = true;

const Gantt = () => {
    let ganttContainer = useRef(null);
    const [taskDrawerVisible, setTaskDrawerVisible] = useState(false);
    const [taskDrawerTab, setTaskDrawerTab] = useState('activity_details');
    const [multiTaskBannerVisible, setMultiTaskBannerVisible] = useState(false);
    const [canDuplicateSelection, setCanDuplicateSelection] = useState(false);
    const [updateMultipleActivityActionComponent, setUpdateMultipleActivityActionComponent] = useState(0);
    const [multiTaskIds, setMultiTaskIds] = useState([]);
    const [linksData, setLinksData] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const {
        planningSelected,
        refreshPlanningSelected,
        planningCustomFields,
        loadingGantt,
        modeSelected,
        calendarsDictionary,
        tasksStripesStyles,
        setTasksStripesStyles,
        setProcessingGantt,
        updateActivitiesDictionary,
        refreshGantt,
        timeUnits,
        reRenderGantt,
        fetchCalendars,
        setReRenderGantt,
        planningFilter,
        isUserJob,
        isMultiJobUser,
        canAccess,
        removeActivityFromDictionary,
        allCustomFields,
        setRefreshGantt,
        setSpinProgress,
        filteredFlattenActivities,
        activitiesDictionary,
        generateFilteredFlattenActivities,
        updateActivityByBroadcast,
    } = useContext(PlanningContext);
    const { tabPreferences, updateTabPreferences, activeTabFilterData } = useContext(TabContext);
    const { userPreferences } = useContext(UserContext);
    const globalNeedList = useContext(NeedsContext).NeedList;
    const planningNeedList = useContext(PlanningNeedsContext).NeedList;
    const addNewResourceRef = useRef();
    const { i18n, t } = useTranslation();
    const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t;

    // loading dhtmlx api for import and export
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/dhtmlx_api.js';
        script.async = true;
        script.id = 'api_script';
        document.body.appendChild(script);
        return () => {
            const element = document.getElementById('api_script');
            element.parentNode.removeChild(element);
        };
    }, []);

    const hasBaseLine = tabPreferences?.gantt_baseline?.showBaseLine;

    // functions for custom value display of data in dhtmlx table

    const ganttScaleCellClassTemplate = (date) => {
        const now = new Date();

        if (
            date.getFullYear() === now.getFullYear() &&
            date.getMonth() === now.getMonth() &&
            date.getDate() === now.getDate()
        ) {
            return 'current_day';
        }

        return '';
    };

    const ganttTimelineCellClassTemplate = (task, date) => {
        if (tabPreferences?.gantt_parameters?.showNoWorkingDay === false) {
            return '';
        }
        const now = new Date();

        if (
            date.getFullYear() === now.getFullYear() &&
            date.getMonth() === now.getMonth() &&
            date.getDate() === now.getDate()
        ) {
            return 'current_day';
        }
        let noWorkingPeriod = calendarsDictionary?.[task.calendarId]?.noWorkingDays ?? new Map();
        if (['quarter', 'year'].includes(window.ganttInstance.config.scaleName)) {
            noWorkingPeriod = calendarsDictionary?.[task.calendarId]?.noWorkingMonth ?? new Map();
        }
        if (window.ganttInstance.config.scaleName === 'month') {
            noWorkingPeriod = calendarsDictionary?.[task.calendarId]?.noWorkingWeek ?? new Map();
        }
        if (window.ganttInstance.config.scaleName === 'hours') {
            noWorkingPeriod = calendarsDictionary?.[task.calendarId]?.noWorkingHours ?? new Map();
        }
        const momento = moment(date).format('YYYY-MM-DD HH:mm:ss');
        const found = noWorkingPeriod.get(momento);

        if (found) {
            return 'no_working_day';
        }

        return '';
    };

    const ganttColumnStartDateTemplate = (task) =>
        task.isExtra
            ? ''
            : moment(task.start_date).format(
                  tabPreferences?.gantt_parameters?.hideHours ||
                      tabPreferences?.gantt_parameters?.hideHours === undefined
                      ? window.dateStringFormat
                      : window.dateStringFormat.concat(' HH:mm')
              );
    const ganttColumnEndDateTemplate = (task) =>
        task.isExtra
            ? ''
            : moment(task.end_date).format(
                  tabPreferences?.gantt_parameters?.hideHours ||
                      tabPreferences?.gantt_parameters?.hideHours === undefined
                      ? window.dateStringFormat
                      : window.dateStringFormat.concat(' HH:mm')
              );
    const ganttColumnDurationTemplate = (task) =>
        task.isExtra || task.isResource ? '' : `${task.durationApi ? Number(task.durationApi).toFixed(1) : '0'}`;
    // yield unit
    const getDurationUnitName = (id) => {
        const timeUnit = timeUnits.find((time) => time.id === id);
        return timeUnit ? timeUnit.name : '-';
    };
    const getQuantityUnitName = (id) => {
        const quantityUnit = quantityUnits.find((time) => time.value === id);
        return quantityUnit ? quantityUnit.label : '-';
    };

    const getYieldUnit = (quantityId, durationId) =>
        `${getQuantityUnitName(quantityId)}/${getDurationUnitName(durationId)}`;
    const columnWidthPreferences = tabPreferences.gantt_column_widths ?? {};
    const planningColumnWidths = columnWidthPreferences ?? {};

    const columnAlignmentPreferences =
        tabPreferences?.gantt_columns_alignment || userPreferences?.default_gantt_columns_alignment || {};
    const baseGanttColumns = [
        {
            id: 'id',
            name: 'identity',
            label: t('columns.id'),
            serverName: 'identity',
            width: planningColumnWidths.id ?? 200,
            resize: true,
            align: columnAlignmentPreferences.id ?? 'left',
            template: (task) => (task.isExtra ? '' : task.identity),
            editor: { type: 'text', map_to: 'identity' },
        },
        {
            id: 'description',
            name: 'description',
            label: t('columns.description'),
            serverName: 'description',
            width: planningColumnWidths.description ?? 100,
            resize: true,
            align: columnAlignmentPreferences.description ?? 'center',
            editor: { type: 'text', map_to: 'description' },
        },
        {
            id: 'name',
            name: 'text',
            label: t('columns.name'),
            serverName: 'name',
            width: planningColumnWidths.name ?? 180,
            resize: true,
            align: columnAlignmentPreferences.name ?? 'left',
            template: (task) =>
                task.isResource
                    ? `<div class="italic" style="padding-left: 20px; font-style: italic;">${task.text}</div>`
                    : task.text,
            editor: { type: 'text', map_to: 'text' },
        },
        {
            id: 'startDate',
            name: 'start_date',
            label: t('columns.start_date'),
            serverName: 'startDate',
            width: planningColumnWidths.startDate ?? 110,
            resize: true,
            align: columnAlignmentPreferences.startDate ?? 'center',
            template: ganttColumnStartDateTemplate,
            editor:
                tabPreferences?.gantt_parameters?.hideHours || tabPreferences?.gantt_parameters?.hideHours === undefined
                    ? { type: 'custom_datepicker_editor', map_to: 'start_date' }
                    : {
                          type: 'datetime_editor',
                          map_to: 'start_date',
                      },
        },
        {
            id: 'endDate',
            name: 'end_date',
            label: t('columns.end_date'),
            serverName: 'endDate',
            width: planningColumnWidths.endDate ?? 110,
            resize: true,
            align: columnAlignmentPreferences.endDate ?? 'center',
            template: ganttColumnEndDateTemplate,
            editor:
                tabPreferences?.gantt_parameters?.hideHours || tabPreferences?.gantt_parameters?.hideHours === undefined
                    ? { type: 'custom_datepicker_editor', map_to: 'end_date' }
                    : {
                          type: 'datetime_editor',
                          map_to: 'end_date',
                      },
        },
        {
            id: 'duration',
            name: 'duration',
            label: t('columns.duration'),
            serverName: 'duration',
            width: planningColumnWidths.duration ?? 50,
            resize: true,
            align: columnAlignmentPreferences.duration ?? 'center',
            template: ganttColumnDurationTemplate,
            editor: {
                type: 'number',
                map_to: 'roundedDuration',
                min: 0,
            },
        },
        {
            id: 'progress',
            name: 'progress',
            label: t('columns.progress'),
            serverName: 'avancement',
            width: planningColumnWidths.duration ?? 50,
            resize: true,
            align: columnAlignmentPreferences.duration ?? 'center',
            template: (task) => (task.isExtra || task.virtual ? '' : (task.progress * 100).toFixed(0)),
            editor: {
                type: 'progress_editor',
                map_to: 'avancement',
            },
        },
        {
            id: 'duration_unit',
            name: 'duration_unit',
            serverName: 'dayDefinitionName',
            label: t('columns.duration_unit'),
            width: planningColumnWidths.duration_unit ?? 50,
            resize: true,
            align: columnAlignmentPreferences.duration_unit ?? 'center',
            template: (task) => timeUnits.find((timeUnit) => timeUnit.id === Number(task.dayDefinitionId))?.name,
            editor: {
                type: 'select',
                map_to: 'dayDefinitionId',
                options: timeUnits.map((val) => ({ key: val.id, label: val.name })),
            },
        },
        {
            id: 'calendar',
            name: 'calendar',
            label: t('columns.calendar'),
            serverName: 'calendarName',
            width: planningColumnWidths.calendar ?? 100,
            resize: true,
            align: columnAlignmentPreferences.calendar ?? 'center',
            template: (task) => calendarsDictionary?.[task.calendarId]?.name,
            editor: {
                type: 'select',
                map_to: 'calendarId',
                options: Object.values(calendarsDictionary).reduce((agg, calendar) => {
                    agg.push({ label: calendar.name, key: calendar.id });

                    return agg;
                }, []),
            },
        },
        {
            id: 'calcul',
            name: 'calcul',
            serverName: 'champPMAutoId',
            label: t('columns.calcul'),
            width: planningColumnWidths.calcul ?? 50,
            resize: true,
            align: columnAlignmentPreferences.calcul ?? 'center',
            template: (task) =>
                task.isExtra ? '' : allCustomFields.find((field) => field.id === Number(task.champPMAutoId))?.name,
            editor: {
                type: 'select',
                map_to: 'champPMAutoId',
                options: allCustomFields
                    .filter((customField) =>
                        [
                            DefaultUnifiedFieldId.quantity,
                            DefaultUnifiedFieldId.quantity,
                            DefaultUnifiedFieldId.yield,
                        ].includes(customField.id)
                    )
                    .map((val) => ({ key: val.id, label: val.name })),
            },
        },
        {
            id: 'yield',
            name: 'yield',
            label: t('columns.yield'),
            serverName: 'yield',
            width: planningColumnWidths.yield ?? 50,
            resize: true,
            align: columnAlignmentPreferences.yield ?? 'center',
            template: (task) =>
                task.isExtra || task.virtual || task.isResource
                    ? ''
                    : `${Number(task.yield).toFixed(2)} ${getYieldUnit(task.quantityUnit, task.dayDefinitionId)}`,
            editor: {
                type: 'number',
                map_to: 'yield',
            },
        },
        {
            id: 'quantity',
            name: 'quantity',
            label: t('columns.quantity'),
            serverName: 'quantity',
            width: planningColumnWidths.quantity ?? 120,
            min_width: 80,
            resize: true,
            align: columnAlignmentPreferences.quantity ?? 'center',
            template: (task) =>
                task.isExtra
                    ? ''
                    : `${Number(task.quantity).toFixed(2) ?? '-'} ${getQuantityUnitName(task.quantityUnit)}`,
            editor: {
                type: 'quantity_editor',
                map_to: 'quantity',
                selectOptions: quantityUnits,
            },
        },
        {
            id: 'predecessors',
            name: 'predecessors',
            label: t('columns.predecessors'),
            serverName: 'predecessors',
            width: planningColumnWidths.predecessors ?? 100,
            resize: true,
            align: columnAlignmentPreferences.predecessors ?? 'left',
            template: (task) => {
                const text = [];
                if (task.type === 'task') {
                    task.$target.forEach((linkId) => {
                        const linkInfo = window.ganttInstance.getLink(linkId);
                        if (linkInfo?.dataAPI) {
                            const predecessorTask = window.ganttInstance.getTask(linkInfo.source);
                            const timeUnitLabel = timeUnits.find(
                                (item) => item.id === linkInfo.dataAPI.dayDefinitionId
                            )?.name;
                            if (linkInfo.dataAPI.decalage !== 0) {
                                text.push(
                                    `${predecessorTask.identity} ${linkInfo.dataAPI.type}${
                                        linkInfo.dataAPI.decalage > 0 ? '+' : ''
                                    }${linkInfo.dataAPI.decalage}(${timeUnitLabel})`
                                );
                            } else {
                                text.push(`${predecessorTask.identity}`);
                            }
                        }
                    });
                }
                return `<span title="${text.join('&#013;')}">${text.join(';')}</span>`;
            },
            editor: null,
            sort: false,
        },
        {
            id: 'successors',
            name: 'successors',
            label: t('columns.successors'),
            serverName: 'successors',
            width: planningColumnWidths.successors ?? 100,
            resize: true,
            align: columnAlignmentPreferences.successors ?? 'left',
            template: (task) => {
                const text = [];
                if (task.type === 'task') {
                    task.$source.forEach((linkId) => {
                        const linkInfo = window.ganttInstance.getLink(linkId);
                        if (linkInfo?.dataAPI) {
                            const successorTask = window.ganttInstance.getTask(linkInfo.target);
                            const timeUnitLabel = timeUnits.find(
                                (item) => item.id === linkInfo.dataAPI.dayDefinitionId
                            )?.name;
                            if (linkInfo.dataAPI.decalage !== 0) {
                                text.push(
                                    `${successorTask.identity} ${linkInfo.dataAPI.type}${
                                        linkInfo.dataAPI.decalage > 0 ? '+' : ''
                                    }${linkInfo.dataAPI.decalage}(${timeUnitLabel})`
                                );
                            } else {
                                text.push(`${successorTask.identity}`);
                            }
                        }
                    });
                }
                return `<span title="${text.join('&#013;')}">${text.join(';')}</span>`;
            },
            editor: null,
            sort: false,
        },
        // {
        //     id: 'skills',
        //     name: 'skills',
        //     label: t('columns.skills'),
        //     serverName: 'neededSkillsQuantity',
        //     width: planningColumnWidths.successors ?? 100,
        //     resize: true,
        //     align: columnAlignmentPreferences.successors ?? 'left',
        //     template: (task) => {
        //         const text = [];
        //         if (!task.isResource) {
        //             (Object.keys(task.data_api.neededSkillsQuantity) ?? []).forEach((skillId) => {
        //                 const skill = skillList.find((i) => i.id === Number(skillId));
        //                 text.push(skill?.name);
        //             });
        //         } else {
        //             (task.skills ?? []).forEach((skill) => {
        //                 text.push(skill.name);
        //             });
        //         }
        //         return `<span title="${text.join('&#013;')}">${text.join(',')}</span>`;
        //     },
        //     editor: null,
        //     sort: false,
        // },
    ];

    const ganttColumnExtraColumns = [];

    if (modeSelected === 'live') {
        ganttColumnExtraColumns.push({
            id: 'valid',
            name: 'valid',
            label: t('columns.state'),
            serverName: 'activityState',
            width: planningColumnWidths.validate ?? 44,
            resize: true,
            align: columnAlignmentPreferences.validate ?? 'center',
            template: (task) => {
                let html = '';
                if (task.isExtra || task.virtual || task.isResource) {
                    return '';
                }
                let iconSize = tabPreferences.gantt_parameters?.rowHeight || 20;
                if (iconSize > 20) {
                    iconSize = 20;
                }
                if (!task.virtual && !task.locked) {
                    const icon = task.status === ACTIVITY_STATE.UNVALID ? circleBase64 : checkCircleBase64;
                    // if (task.status === ACTIVITY_STATE.VALID) {
                    //     // make icon warning when has conflict incoming link
                    //     const targetlinksCategories = task.$target.map(
                    //         (linkId) => window.ganttInstance.getLink(linkId).dataAPI?.category?.id
                    //     );
                    //     if (targetlinksCategories.filter((i) => i === -2).length > 0) {
                    //         icon = warningBase64;
                    //     }
                    // }

                    html = `<div style="display: flex; align-items: center; justify-content: center; height: 100%; cursor: pointer;"><div data-action="${
                        task.status === ACTIVITY_STATE.UNVALID ? 'validate' : 'invalidate'
                    }" style=" height: ${iconSize}px; width: ${iconSize}px; background-image: url(${icon})" background-size: auto 100%; background-repeat: no-repeat; cursor: pointer;"></div></div>`;
                } else {
                    html = `<div style="display: flex; align-items: center; justify-content: center; height: 100%;"> <div style=" height: ${iconSize}px; width: ${iconSize}px; background-image: url(${lockBase64})" background-size: auto 100%; background-repeat: no-repeat;"></div></div>`;
                }
                return html;
            },
        });
    }

    const isConflict = (task) => {
        const sourcelinks = task.$source.map((linkId) => ({
            categoryId: window.ganttInstance.getLink(linkId)?.dataAPI?.category?.id,
            ...window.ganttInstance.getLink(linkId),
        }));
        let isTaskConflict = false;
        sourcelinks.forEach((link) => {
            if (
                link.categoryId === -2 &&
                window.ganttInstance.isTaskExists(link.target) &&
                window.ganttInstance.getTask(link.target)
                // Fonctionnalité desactivé temporairement
                // &&
                // window.ganttInstance.getTask(link.target).locked
            ) {
                isTaskConflict = true;
            }
        });
        // Fonctionnalité desactivé temporairement
        // if (task.status === ACTIVITY_STATE.VALID) {
        // make icon warning when has conflict incoming link
        const targetlinksCategories = task.$target.map(
            (linkId) => window.ganttInstance.getLink(linkId).dataAPI?.category?.id
        );
        if (targetlinksCategories.filter((i) => i === -2).length > 0) {
            isTaskConflict = true;
        }
        // }
        return isTaskConflict;
    };

    const ganttTaskTextTemplate = (start, end, task) => {
        if (window.ganttInstance) {
            const parameters = tabPreferences?.gantt_parameters;
            if (parameters?.centerTextColumn) {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters.centerTextColumn
                );
                let text = '';
                if (column && column.template) {
                    text = column.template(task) || '';
                } else {
                    text = task[parameters.centerTextColumn] || '';
                }
                if (parameters.centerTextColumn !== 'valid') {
                    return `<span class="px-2" title="${text}" style="color: ${
                        parameters.centerTextColor ?? 'black'
                    };">${text}</span>`;
                }
                return text;
            }
            return `<span class="px-2" title="${task.text}" >${task.text}</span>`;
        }
        return '';
    };

    const ganttTaskLeftTextTemplate = (start, end, task) => {
        if (window.ganttInstance) {
            const parameters = tabPreferences?.gantt_parameters;
            const isTaskConflict = isConflict(task);
            let text = ``;
            let html = ``;
            if (task.type === window.ganttInstance.config.types.milestone) {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters?.milestoneLeftTextColumn
                );
                if (column && column.template) {
                    text = column.template(task) || '';
                } else {
                    text = task[parameters?.milestoneLeftTextColumn] || '';
                }
                if (parameters?.milestoneLeftTextColumn && parameters?.milestoneLeftTextColumn !== 'valid') {
                    html =
                        text !== ''
                            ? `<span class="px-2 gantt_side_text" title="${text}" style="color: ${
                                  parameters.milestoneLeftTextColor ?? 'black'
                              };">${text}</span>`
                            : ``;
                }
            } else {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters?.leftTextColumn
                );
                if (column && column.template) {
                    text = text.concat(column.template(task) || '');
                } else {
                    text = task[parameters?.leftTextColumn] || '';
                }
                if (parameters?.leftTextColumn && parameters?.leftTextColumn !== 'valid') {
                    html =
                        text !== ''
                            ? `
                    <span class="px-2 gantt_side_text" title="${text}" style="color: ${
                                  parameters.leftTextColor ?? 'black'
                              };">
                    
                    ${text}</span>`
                            : ``;
                }
            }
            return `<div style="display: flex; align-items: center; justify-content: start"> ${
                isTaskConflict
                    ? `<div style=" height: 20px; width: 20px; background-image: url(${warningBase64})" background-size: auto 100%; background-repeat: no-repeat;"></div>`
                    : ''
            }${html}</div>`;
        }
        return '';
    };

    const ganttTaskRightTextTemplate = (start, end, task) => {
        if (window.ganttInstance) {
            const parameters = tabPreferences?.gantt_parameters;
            let text = '';
            if (task.type === window.ganttInstance.config.types.milestone) {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters?.milestoneRightTextColumn
                );
                if (column && column.template) {
                    text = column.template(task) || '';
                } else {
                    text = task[parameters?.milestoneRightTextColumn] || '';
                }
                if (parameters?.milestoneRightTextColumn && parameters?.milestoneRightTextColumn !== 'valid') {
                    return text !== ''
                        ? `<div style="display: flex; align-items: center; justify-content: start"><span class="px-2 gantt_side_text" title="${text}" style="color: ${
                              parameters.milestoneLeftTextColor ?? 'black'
                          };">${text}</span></div>`
                        : ``;
                }
            } else {
                const column = window.ganttInstance.config.fullColumns.find(
                    (col) => col.id === parameters?.rightTextColumn
                );
                if (column && column.template) {
                    text = column.template(task) || '';
                } else {
                    text = task[parameters?.rightTextColumn] || '';
                }
                if (parameters?.rightTextColumn && parameters?.rightTextColumn !== 'valid') {
                    return text !== ''
                        ? `<div style="display: flex; align-items: center; justify-content: start"><span class="px-2 gantt_side_text" title="${text}" style="color: ${
                              parameters.rightTextColor ?? 'black'
                          };">${text}</span></div>`
                        : '';
                }
            }
            return text;
        }
        return '';
    };

    const openTaskDrawer = (taskId, tab) => {
        const task = window.ganttInstance.getTask(taskId);

        if (task.$new) {
            task.identity = null;
            task.description = null;
            task.jobId = null;
        }
        setTaskDrawerTab(tab);
        setSelectedTask(task);
        setTaskDrawerVisible(true);
    };

    const closeTaskDrawer = () => {
        setSelectedTask((oldVal) => {
            const selectedTaskId = oldVal.id;
            if (window.ganttInstance.isTaskExists(selectedTaskId)) {
                window.ganttInstance.showTask(selectedTaskId);
            }
            return null;
        });
        setTaskDrawerVisible(false);
    };

    const updateGanttColumns = (ganttColumnsPreferences) => {
        const ganttColumns = baseGanttColumns
            .concat(
                planningCustomFields.reduce(
                    (agg, customField) =>
                        agg.concat({
                            id: `${customField.name === JOB_FIELD ? 'jobs' : `customField#${customField.id}`}`,
                            name: `customField#${customField.id}`,
                            label: `${customField.name === JOB_FIELD ? t('columns.jobs') : `${customField.name}`}`,
                            serverName: `customField#${customField.id}`,
                            width: planningColumnWidths[`customField#${customField.id}`] ?? 60,
                            resize: true,
                            align: columnAlignmentPreferences[`customField#${customField.id}`] ?? 'center',
                            template: (task) => {
                                const customFieldData =
                                    'customFields' in task && Array.isArray(task.customFields)
                                        ? task.customFields.find((item) => item.id === customField.id)
                                        : null;
                                let text = null;
                                if (customField.name === JOB_FIELD) {
                                    const jobsArray = (task.jobId || []).map((job) => {
                                        if (job === ALL_JOBS) {
                                            return t('general.all_jobs');
                                        }
                                        return job;
                                    });
                                    text = `<span title="${jobsArray.join('&#013')}">${jobsArray.join(', ')}</span>`;
                                } else {
                                    text = customFieldData?.value;
                                }
                                return text;
                            },
                            editor:
                                customField.name === JOB_FIELD
                                    ? null
                                    : {
                                          // eslint-disable-next-line
                                          type: customField.type.choice
                                              ? 'select'
                                              : customField.type.text
                                              ? 'custom_text_editor'
                                              : 'number',
                                          map_to: `customField#${customField.id}`,
                                          options: sortBy(
                                              Object.keys(customField.type.choices).reduce(
                                                  (options, customFieldChoiceName) => {
                                                      options.push({
                                                          label:
                                                              customFieldChoiceName === ALL_JOBS
                                                                  ? t('general.all_jobs')
                                                                  : customFieldChoiceName,
                                                          key: customFieldChoiceName,
                                                      });

                                                      return options;
                                                  },
                                                  []
                                              ),
                                              (obj) => obj.label
                                          ),
                                      },
                        }),
                    []
                )
            )
            .concat(ganttColumnExtraColumns);
        // storing all columns config (using in left, center and right text templates)
        window.ganttInstance.config.fullColumns = [...ganttColumns];
        const columnsOrder = tabPreferences?.gantt_columns_order || userPreferences?.default_gantt_columns_order || {};
        const fullColumns = ganttColumns.reduce((agg, column) => {
            if (ganttColumnsPreferences && Object.keys(ganttColumnsPreferences).length) {
                if (ganttColumnsPreferences.includes(column.id)) {
                    agg.push({ ...column, order: columnsOrder[column?.id] ?? 99 });
                }
            } else if (DEFAULT_COMUMNS.includes(column.id)) {
                agg.push({ ...column, order: columnsOrder[column?.id] ?? 99 });
            }

            if (['add', 'action_buttons', 'split_task'].includes(column.id)) {
                agg.push({ ...column, order: columnsOrder[column?.id] ?? 99 });
            }

            return agg;
        }, []);

        window.ganttInstance.config.columns = fullColumns
            .sort((a, b) => a.order - b.order)
            .map((column, index) => ({ ...column, tree: index === 0, align: index === 0 ? 'left' : column.align }));
        ganttCustomEditors(tabPreferences);
    };

    const addNewTask = async (taskId, isDragged = false, index) => {
        window.saveScrollState = false;
        window.saveScrollState = false;
        const task = clone(window.ganttInstance.getTask(taskId));
        setProcessingGantt(true);
        // const job = userJobsWithoutMulti()[0];
        const newTaskObject = {
            activityParentId: window.ganttInstance.getTask(task.parent)?.serverId,
            name: task.text,
            // job: [job],
        };
        // assigning default jobs
        const defaultJobsId = userPreferences?.planning_default_jobs?.[planningSelected?.id] || [];
        if (defaultJobsId.length > 0) {
            const jobCustomField = planningCustomFields.find((i) => i.name === JOB_FIELD);
            const jobCustomFieldChoicesObj = Object.values(jobCustomField.type.choices);
            const jobCustomFieldChoices = Object.keys(jobCustomField.type.choices);

            const jobsToAssign = defaultJobsId.flatMap((jobId) => {
                const jobObjIndex = jobCustomFieldChoicesObj.findIndex((job) => job.id === jobId);
                if (jobObjIndex !== -1) {
                    return jobCustomFieldChoices[jobObjIndex];
                }
                return [];
            });

            if (jobsToAssign.length > 0) {
                newTaskObject.job = jobsToAssign;
            }
        }
        if (isDragged) {
            newTaskObject.startDate = moment(task.start_date).utc(true).valueOf();
            newTaskObject.endDate = moment(task.end_date).utc(true).valueOf();
        }
        let activity = null;
        const activityUpdated = await ActivityService.createActivity(newTaskObject)
            .catch((error) => {
                if (window.ganttInstance.isTaskExists(taskId)) {
                    window.ganttInstance.deleteTask(taskId);
                }
                requestError(error, `Erreur lors la création de l'activité`);
                Sentry.captureException(error);
                setProcessingGantt(false);
            });
            // refetch
            // const activityUpdated = await ActivityService.showActivity(newActivity.id)
            console.log("🚀 ~ addNewTask ~ activityUpdated:", activityUpdated)
            if (window.ganttInstance.isTaskExists(taskId)) {
                window.ganttInstance.deleteTask(taskId);
            }
            updateActivitiesDictionary(activityUpdated.id, {
                ...activityUpdated,
                baseLineStartDate: null,
                baseLineEndDate: null,
                baseLineProgress: 0,
            });
            const durationUnit = timeUnits.find(
                (timeUnit) => timeUnit.id === Number(activityUpdated.dayDefinitionId)
            );
                console.log('adding task')
                window.ganttInstance.unselectTask();
                const taskColor = tabPreferences.gantt_parameters?.simpleTaskBarColor;

            const newId = window.ganttInstance.addTask(
                formatGanttTask(activityUpdated, {
                    durationApi: Number(activityUpdated.duration / durationUnit.duration),
                    realChildren: [],
                    color: taskColor,
                    parent: task.parent,
                }),
                undefined,
                index ?? null
            );
            console.log('refreshing', newId)
            // focus on name
            setTimeout(() => {
                // window.ganttInstance.refreshData();
                window.ganttInstance.render();
                window.ganttInstance.showTask(newId);
                window.ganttInstance.selectTask(newId);
                console.log('focus on name')
                window.ganttInstance.ext.inlineEditors.startEdit(newId, 'text');
                window.saveScrollState = false;
                setProcessingGantt(false);
            }, 100);
            activity = activityUpdated;
        return new Promise((resolve) => {
            resolve(activity);
        });
    };

    const ganttConfigure = (ganttColumnsPreferences) => {
        // eslint-disable-next-line consistent-return
        window.ganttInstance.createDataProcessor(async (entity, action, data) => {
            // const isGroupement =
            //     userPreferences?.filtered_group?.[planningSelected.rootActivityId]?.groupingType === 'custom_fields';
            // entity - "task"|"link"
            // action - "create"|"update"|"delete"
            // data - an object with task or link data
            if (!data.no_update) {
                switch (entity) {
                    case 'link':
                        switch (action) {
                            case 'create': {
                                // eslint-disable-next-line no-case-declarations
                                const sourceActivity = window.ganttInstance.getTask(data.source);
                                // eslint-disable-next-line no-case-declarations
                                const targetActivity = window.ganttInstance.getTask(data.target);
                                try {
                                    setProcessingGantt(true);

                                    const createdLink = await ActivityService.createLink(
                                        sourceActivity.serverId,
                                        targetActivity.serverId,
                                        0,
                                        ['FD', 'DD', 'FF', 'DF'][parseInt(data.type, 10)],
                                        sourceActivity.dayDefinitionId
                                    );
                                    updateActivitiesDictionary(sourceActivity.serverId, {
                                        successorLinksId: [...sourceActivity.data_api.successorLinksId, createdLink.id],
                                    });
                                    updateActivitiesDictionary(targetActivity.serverId, {
                                        predecessorLinksId: [
                                            ...targetActivity.data_api.predecessorLinksId,
                                            createdLink.id,
                                        ],
                                    });
                                    setRefreshGantt((val) => val + 1);

                                    // // updating front data
                                    // // delete generatedLink
                                    // window.ganttInstance.getLink(data.id).is_virtual = true;
                                    // window.ganttInstance.deleteLink(data.id);
                                    // window.ganttInstance.render();
                                    // // add new link
                                    // const linksToAdd = generateLinks(sourceActivity.serverId, targetActivity.serverId, createdLink);
                                    // window.ganttInstance.parse({
                                    //     data: window.ganttInstance.serialize().data,
                                    //     links: [
                                    //         ...window.ganttInstance.getLinks(),
                                    //         ...linksToAdd
                                    //     ],
                                    // });

                                    // window.ganttInstance.changeLinkId(data.id, createdLink.id);
                                    return 'ok';
                                } catch (error) {
                                    window.ganttInstance.getLink(data.id).is_virtual = true;
                                    window.ganttInstance.deleteLink(data.id);
                                    Sentry.captureException(error);
                                    requestError(error, 'Erreur lors de la création du lien');
                                    setProcessingGantt(false);
                                }
                                break;
                            }

                            case 'delete':
                                if (!data.is_virtual) {
                                    try {
                                        setProcessingGantt(true);
                                        if (
                                            window.ganttInstance.isTaskExists(data.source) &&
                                            window.ganttInstance.isTaskExists(data.target)
                                        ) {
                                            const sourceActivity = window.ganttInstance.getTask(data.source);
                                            const targetActivity = window.ganttInstance.getTask(data.target);

                                            await ActivityService.deleteLink(data.serverId);
                                            updateActivitiesDictionary(data.sourceId, {
                                                successorLinksId: sourceActivity.data_api.successorLinksId.filter(
                                                    (i) => i !== data.serverId
                                                ),
                                            });
                                            updateActivitiesDictionary(data.targetId, {
                                                predecessorLinksId: targetActivity.data_api.predecessorLinksId.filter(
                                                    (i) => i !== data.serverId
                                                ),
                                            });
                                            setProcessingGantt(false);

                                            setRefreshGantt((val) => val + 1);
                                        }
                                        setProcessingGantt(false);
                                    } catch (error) {
                                        Sentry.captureException(error);
                                        if (error?.response?.data?.type === 'forbiden_job_operation') {
                                            notificationError(
                                                'Erreur lors de la suppression du lien',
                                                'Vous n’avez pas les droits pour supprimer le lien concerné.'
                                            );
                                        } else {
                                            requestError(error, 'Erreur lors de la suppression du lien');
                                        }
                                        setRefreshGantt((val) => val + 1);
                                    }
                                }
                                break;
                            default:
                        }
                        break;
                    default:
                }
            }
        });
        updateGanttColumns(ganttColumnsPreferences);
        // console.log('🚀 ~ file: Gantt.jsx:1150 ~ ganttConfigure ~ tabPreferences', tabPreferences);
        setGanttConfigurations(tabPreferences, planningSelected, modeSelected, canAccess, generalTranslation);
        // create on drag
        const onDragEnd = async (startPoint, endPoint, startDate, endDate, tasksBetweenDates, tasksInRow) => {
            if (tasksInRow.length === 1) {
                const currentTask = tasksInRow[0];
                if (window.ganttInstance.config.readonly) {
                    notificationError(t('gantt_events.new_activity'), t('general.operation_not_permitted'));
                    return;
                }
                if (currentTask.status !== ACTIVITY_STATE.UNVALID) {
                    notificationError(t('gantt_events.new_activity'), t('general.operation_not_permitted'));
                    return;
                }
                if (+currentTask.serverId === planningSelected.rootActivityId) {
                    notificationError(t('gantt_events.new_activity'), t('general.operation_not_permitted'));
                    return;
                }
                if (currentTask.isExtra) {
                    return;
                }
                if (currentTask.type === window.ganttInstance.config.types.customProject) {
                    const newTaskId = window.ganttInstance.addTask(
                        {
                            text: t('gantt_events.new_activity'),
                            start_date: startDate,
                            end_date: endDate,
                        },
                        currentTask.id
                    );
                    await addNewTask(newTaskId, true);
                } else {
                    // on ajoute la récap
                    // on place la récap à la position de la tache actuelle
                    // on déplace la tache actuelle dans la récap
                    // on ajoute la nouvelle tache dans la récap
                    const prevTaskId = window.ganttInstance.getPrevSibling(currentTask.id);
                    const projectName = t('gantt_events.new_summary_task');
                    const newProjectId = window.ganttInstance.addTask(
                        {
                            text: projectName,
                            // render: ganttParameters?.splitTask ? 'split' : '',
                            type: window.ganttInstance.config.types.customProject,
                        },
                        currentTask.parent
                    );
                    const projectIndex = window.ganttInstance.getTaskIndex(currentTask.id);
                    const newProjectTask = await addNewTask(newProjectId, false, projectIndex);
                    const projectTask = window.ganttInstance.getTaskByServerId(newProjectTask.id);
                    setProcessingGantt(true);
                    if (prevTaskId) {
                        const prevTask = window.ganttInstance.getTask(prevTaskId);
                        await ActivityService.moveAfter(prevTask.serverId, [newProjectTask.id]);
                    }
                    // SHOULD DO MOVE AFTER WHEN NO PREV TASK TO PLACE RECAP ON TOP
                    await ActivityService.updateActivityParent(newProjectTask.id, [currentTask.serverId]);
                    currentTask.parent = projectTask.id;
                    window.ganttInstance.refreshTask(currentTask.id);
                    setProcessingGantt(false);
                    const newTaskId = window.ganttInstance.addTask(
                        {
                            text: t('gantt_events.new_activity'),
                            start_date: startDate,
                            end_date: endDate,
                        },
                        projectTask.id
                    );
                    const newChildTask = await addNewTask(newTaskId, true);
                    // window.ganttInstance.calculateTaskLevel(window.ganttInstance.getTask(newTask.id));
                    if (projectTask.render !== 'split') {
                        const newChildTaskObj = window.ganttInstance.getTaskByServerId(newChildTask.id);

                        window.ganttInstance.open(projectTask.id);
                        window.ganttInstance.ext.inlineEditors.startEdit(newChildTaskObj.id, 'text');
                    }
                }
            } else if (tasksInRow.length === 0) {
                // should never happened
                window.ganttInstance.createTask({
                    text: t('gantt_events.new_activity'),
                    start_date: window.ganttInstance.roundDate(startDate),
                    end_date: window.ganttInstance.roundDate(endDate),
                });
            }
        };
        // Active le plugin pour les tooltips.
        window.ganttInstance.plugins({
            tooltip: true,
        });
        // Desactive l'affichage par defaut sur les taches de la table.
        window.ganttInstance.templates.tooltip_text = () => null;
        // Active la création d'activité par ctrl+click sur un espace vide de la timeline.
        window.ganttInstance.config.click_drag = {
            callback: onDragEnd,
            useKey: 'ctrlKey',
            singleRow: true,
        };
        // Active le deplacement dans le gantt par drag sur un espace vide de la timeline.
        window.ganttInstance.config.drag_timeline = {
            ignore: '.gantt_task_line, .gantt_task_link',
            useKey: false,
        };
        // Active un tooltip sur l'espace vide de la timeline pour rappeler la possibilité de faire ctrl+click pour creer une activite.
        window.ganttInstance.ext.tooltips.tooltipFor({
            selector: '.gantt_task_cell',
            html(event) {
                if (canAccess() && modeSelected !== 'archive') {
                    const positionX = event.target.offsetLeft + event.offsetX;
                    const date = window.ganttInstance.dateFromPos(positionX);
                    const taskId = event.target.parentNode.attributes.task_id.nodeValue;
                    if (window.ganttInstance.isTaskExists(taskId)) {
                        const task = window.ganttInstance.getTask(taskId);
                        const format = window.dateStringFormat ?? 'HH\\h MM\\m SS\\s \\a\\t DD/MM/YY';
                        return `${task.text} - ${moment(date).format(format)} <br/> ${t('gantt.create_activity')}`;
                    }
                }
                return false;
            },
        });
        ganttContainer.style.height = '100%';
        window.ganttInstance.init(ganttContainer);
        GanttTaskLayers.addGanttTaskLayers(tabPreferences, i18n);
    };

    const ganttLabellise = () => {
        window.ganttInstance.locale.labels.type_folder = 'Dossier';
        window.ganttInstance.locale.labels.type_planning = 'Planning';
        window.ganttInstance.locale.labels.type_job = JOB_FIELD;
    };

    const ganttTemplates = () => {
        if (window.ganttInstance) {
            window.ganttInstance.templates.scale_cell_class = ganttScaleCellClassTemplate;
            window.ganttInstance.templates.timeline_cell_class = ganttTimelineCellClassTemplate;
            window.ganttInstance.templates.task_text = ganttTaskTextTemplate;
            window.ganttInstance.templates.leftside_text = ganttTaskLeftTextTemplate;
            window.ganttInstance.templates.rightside_text = ganttTaskRightTextTemplate;
            // grid class coloration
            const colorTemplate = (start, end, task) => {
                if (task.isResource) {
                    const taskParent = window.ganttInstance.getTask(task.parent);
                    if (taskParent?.type === 'task' || taskParent?.type === 'milestone') {
                        return `simple-task gantt-resource-task resource-task-row-${task.id}`;
                    }
                    if (taskParent?.type === 'custom-project') {
                        return `level-${taskParent.$level} gantt-resource-task`;
                    }
                }
                if (window.ganttInstance.getAllChildren(task.id).length > 0) {
                    return `level-${task.$level} gantt_row_project`;
                }
                if (task.virtual) {
                    return '';
                }
                return 'simple-activity';
            };
            window.ganttInstance.templates.grid_row_class = colorTemplate;
            window.ganttInstance.templates.task_row_class = colorTemplate;

            // hide or show links
            window.ganttInstance.templates.link_class = (link) => {
                const parameters = tabPreferences?.gantt_parameters;
                if (parameters) {
                    return parameters.showLinks === false ? 'hidden' : `link-id-${link.serverId}`;
                }
                return `link-id-${link.serverId}`;
            };

            window.ganttInstance.templates.task_class = (start, end, task) => {
                if (task.isResource) return 'hidden';
                const parameters = tabPreferences?.gantt_parameters;
                const classes = [];
                // Critical Path Shadow
                if (parameters?.splitTask) {
                    classes.push('box-shadow');
                }
                // gantt project
                if (task.type === window.ganttInstance.config.types.customProject) {
                    classes.push('gantt_project');
                }
                // Fix : display critical path on leaf node or on node if it s closed
                if (task.totalMargin === task.endDate && (!task.$open || !window.ganttInstance.hasChild(task.id))) {
                    classes.push('critical-task');
                }

                if (window.ganttInstance.isSplitTask(task)) {
                    classes.push('is_split_task');
                }
                if (task.hidden) {
                    classes.push('hidden');
                }
                // adding custom classes to gantt-task
                // if (parseInt(task.duration, 10) !== 0) {
                classes.push(`task-id-${task.serverId ?? task.id}`);
                // }
                return classes.join(' ');
            };

            // window.ganttInstance.templates.task_row_class = (start, end, task) => {
            //     if (task.isResource || task.resources?.length > 0) {
            //         return `task-row-${task.id}`;
            //     }
            //     return '';
            // };
            // custom icons on tree (conflict icon)

            window.ganttInstance.templates.grid_file = (task) => {
                if (task.isResource) {
                    const icon = task.needType === NEED_TYPE.NON_CONSUMMABLE ? nonConsumableIcon : consumableIcon;
                    return `<div class='gantt_tree_icon' style="opacity: 0.6; width: 20px; background-image: url(${icon});"></div>`;
                }

                if(task?.calendarId && calendarsDictionary[task.calendarId]?.noWorkingReals){
                    // Check if activity is on unworked calendar time; its happens if calendar has been modified after the activity placement
                    const workOnNoWorkingTime = calendarsDictionary[task.calendarId].noWorkingReals.find(slice =>
                                                                 (slice.start < task.startDate && task.startDate < slice.end) ||
                                                                 (slice.start < task.endDate && task.endDate < slice.end));
                    if(workOnNoWorkingTime) return `<div class="gantt_tree_icon" style="background-image: url(${warningBase64});"></div>`;
                }
                if (task.isExtra) return '';
                return "<div class='gantt_tree_icon gantt_file'></div>";
            };
            window.ganttInstance.templates.grid_folder = (task) => {
                // make icon warning when has conflict incoming link
                const isLockConflict = isConflict(task);
                if (isLockConflict && task.taskType === 'task') {
                    return `<div class="gantt_tree_icon" style="background-image: url(${warningBase64});"></div>`;
                }
                return `<div class='gantt_tree_icon ${
                    task.realChildren?.length > 0 ? 'gantt_folder_closed' : 'gantt_file'
                }'></div>`;
            };
        }
    };

    const addVirtualTasks = () => {
        const ganttParameters = tabPreferences?.gantt_parameters;
        if (ganttParameters?.showProgressLine || (tabPreferences?.gantt_markers ?? []).filter((i) => i.show).length) {
            if (window.ganttInstance.isTaskExists('virtual_top')) {
                window.ganttInstance.deleteTask('virtual_top');
                window.ganttInstance.deleteTask('virtual_bottom');
            }
            const virtualTask = {
                id: 'virtual_top',
                text: '',
                type: 'task',
                virtual: true,
                isExtra: true, // indicateur des lignes ajoutées
                unscheduled: true,
                parent: 0,
                row_height: 30,
                bar_height: 25,
            };
            // ajout des tâches virtuelles
            window.ganttInstance.addTask({ ...virtualTask }, 0, 0);
            window.ganttInstance.addTask({ ...virtualTask, id: 'virtual_bottom' }, 0);
        }
    };

    const checkDuplicationState = useCallback(() => {
        const selectedTasks = window.ganttInstance.getSelectedTasks().map((i) => window.ganttInstance.getTask(i));
        if (selectedTasks.findIndex((task) => task.serverId === planningSelected.rootActivityId) !== -1) {
            setCanDuplicateSelection(false);
            return false;
        }
        let canDuplicate = true;
        // we only check child tasks because selected tasks is sorted from the top of the tree
        selectedTasks.every((task) => {
            if (!canDuplicate) return false;
            // we don't test simple tasks because they don't have children
            if (task.type === 'task') return true;
            const taskChildrenIds = [];
            let checkChildrenSelected = [];
            window.ganttInstance.eachTask((child) => taskChildrenIds.push(child.id), task.id);
            checkChildrenSelected = intersection(window.ganttInstance.getSelectedTasks(), taskChildrenIds);
            canDuplicate = checkChildrenSelected.length === 0;
            return true;
        });
        // console.log("🚀 ~ file: Gantt.jsx:1336 ~ checkDuplicationState ~ canDuplicate:", canDuplicate)
        setCanDuplicateSelection(canDuplicate);
        return canDuplicate;
    }, [canDuplicateSelection]);

    const ganttEvents = () => {
        addGanttEvents(
            tabPreferences,
            planningSelected,
            planningFilter,
            fetchCalendars,
            setReRenderGantt,
            setRefreshGantt,
            requestError,
            refreshPlanningSelected,
            updateActivitiesDictionary,
            removeActivityFromDictionary,
            updateTabPreferences,
            planningCustomFields,
            isUserJob,
            isMultiJobUser,
            canAccess,
            notificationError,
            addNewTask,
            openTaskDrawer,
            closeTaskDrawer,
            addVirtualTasks,
            timeUnits,
            setTasksStripesStyles,
            setSelectedTask,
            addNewResourceRef,
            hasBaseLine,
            i18n,
            updateActivityByBroadcast
        );
        // we use global variable because of asynchronous setState in React that slowing the process
        window.ganttInstance.attachEvent('onTaskSelected', (id) => {
            const task = window.ganttInstance.getTask(id);
            if (task.type !== window.ganttInstance.config.types.customProject) {
                const currentTaskIndex = window.selectedSimpleTasks.findIndex((i) => i.serverId === task.serverId);
                if (currentTaskIndex === -1) {
                    window.selectedSimpleTasks.push(task);
                }
            }
        });
        window.ganttInstance.attachEvent('onTaskUnSelected', (id) => {
            if (window.ganttInstance.isTaskExists(id)) {
                const currentTaskIndex = window.selectedSimpleTasks.findIndex(
                    (i) => i.serverId === window.ganttInstance.getTask(id).serverId
                );
                // existing and being unselected
                if (currentTaskIndex !== -1) {
                    window.selectedSimpleTasks.splice(currentTaskIndex, 1);
                }
            }
        });
        window.ganttInstance.attachEvent('onMultiSelect', (e) => {
            const selectedTasks = window.ganttInstance.getSelectedTasks();
            if (selectedTasks.length > 1) {
                window.ganttInstance.ext.quickInfo.hide(true);
                setMultiTaskIds(selectedTasks.map((id) => window.ganttInstance.getTask(id).serverId));
                // if selecting with shift order will be from top to bottom
                if (e.shiftKey) {
                    // setSelectedSimpleTasks(
                    window.selectedSimpleTasks = selectedTasks
                        .map((i) => window.ganttInstance.getTask(i))
                        .filter((act) => act.type !== window.ganttInstance.config.types.customProject);
                    // );
                }
                setMultiTaskBannerVisible(true);
                setUpdateMultipleActivityActionComponent((val) => val + 1);
                checkDuplicationState();
            } else {
                setUpdateMultipleActivityActionComponent(0);
                setMultiTaskIds([]);
                setMultiTaskBannerVisible(false);
            }
            return true;
        });
        // window.ganttInstance.attachEvent('onBeforeMultiSelect', (e) => {
        //     if (e.shiftKey) {
        //         return false;
        //     }
        //     return true;
        // });
    };

    /**
     * Changing tab preferences
     * @param {*} dictionary Activities dictionary
     */
    const migrateTabPreferences = async (dictionary) => {
        let doRefresh = false;
        // MIGRATE TO USE IDENTITY
        if (!tabPreferences.gantt_split_tasks) {
            const splitTasks = (tabPreferences.gantt_split_task || []).map(
                (serverId) => dictionary[serverId]?.identity
            );
            console.log('🚀 AFTER MIGRATE SPLIT~ file: Gantt.jsx:1526 ~ .then ~ splitTasks:', splitTasks);
            if (splitTasks.length > 0) {
                await updateTabPreferences({ gantt_split_tasks: splitTasks, gantt_split_task: ['UNUSED'] });
                doRefresh = true;
            }
        }
        if (!tabPreferences.gantt_collapsed_tasks) {
            const collapsedTasks = (tabPreferences.gantt_collapsed_task || []).map(
                (serverId) => dictionary[serverId]?.identity
            );
            console.log('🚀 AFTER MIGRATE COLLAPSE~ file: Gantt.jsx:1526 ~ .then ~ splitTasks:', collapsedTasks);
            if (collapsedTasks.length > 0) {
                await updateTabPreferences({ gantt_collapsed_tasks: collapsedTasks, gantt_collapsed_task: ['UNUSED'] });
                doRefresh = true;
            }
        }
        if (doRefresh) {
            refreshPlanningSelected();
        }
    };

    const removeAllGanttTooltips = () => {
        document.querySelectorAll('.gantt_tooltip').forEach((tooltip) => tooltip.remove());
    };

    const loadGantt = useCallback(async (filteredActivities) => {
        if (planningSelected && window.ganttInstance) {
            // remove all tooltips
            removeAllGanttTooltips();
            await migrateTabPreferences(activitiesDictionary);
            ganttTemplates();
            // window.ganttInstance.render();
            window.ganttInstance.config.initial_scroll = false;

                let flattenActivities = [...filteredActivities];
                setTasksStripesStyles(
                    flattenActivities
                        .filter((flattenActivity) => TaskStripes.hasStripes(flattenActivity))
                        .map((flattenActivity) => TaskStripes.formatForState(flattenActivity))
                );
                // global parameter for split task

            const splitTasks = tabPreferences.gantt_split_tasks || [];
            flattenActivities = flattenActivities.map((i) => {
                let taskColor = i.type === GANTT_CUSTOM_PROJECT ? tabPreferences.gantt_parameters?.parentTaskBarColor: tabPreferences.gantt_parameters?.simpleTaskBarColor;
                if (i.type === 'milestone') {
                    taskColor = tabPreferences.gantt_parameters?.milestoneColor;
                }
                return {
                ...i,
                color: taskColor,
                text: i.virtual && i.text === ALL_JOBS ? generalTranslation('all_jobs'): i.text,
                textColor: getTextColor(taskColor), 
                bar_height: hasBaseLine ? window.ganttInstance.config.bar_height / 2 : null,
                render: splitTasks.indexOf(i.identity) !== -1 ? 'split' : '',
            }});

                // filtre sur les dates prioritaire
                if (hasBaseLine && activeTabFilterData.dateFilter) {
                    window.ganttInstance.config.start_date = window.ganttInstance.date.add(
                        new Date(
                            Math.min(
                                flattenActivities[0].planned_start?.valueOf() || Number.MAX_VALUE,
                                flattenActivities[0].start_date.valueOf()
                            )
                        ),
                        -1,
                        'day'
                    );
                    window.ganttInstance.config.end_date = window.ganttInstance.date.add(
                        new Date(
                            Math.max(
                                flattenActivities[0].planned_end?.valueOf() || Number.MIN_VALUE,
                                flattenActivities[0].end_date.valueOf()
                            )
                        ),
                        1,
                        'day'
                    );
                }
                // console.log(
                //     '🚀 ~ file: Gantt.jsx ~ line 1384 ~ ).then ~ flattenActivities',
                //     activitiesWithResources
                // );
                setSpinProgress(80);

            const links = await generateLinkNodes(planningSelected.rootActivityId, flattenActivities);
            // dashed links
            setLinksData(
                links
                    .filter((link) => linkStyle.isDotted(link.dataAPI))
                    .map((link) => linkStyle.formatForState(link.dataAPI))
            );
            // console.log('🚀 ~ file: Gantt.jsx ~ line 1392 ~ ).then ~ links', links);
            window.ganttInstance.silent(() => {
                console.log('SILENT')
                window.ganttInstance.clearAll();
            });
            setSpinProgress(90);
            window.ganttInstance.parse({
                data: [...flattenActivities],
                links: JSON.parse(JSON.stringify(links)),
            });
                console.log("🚀 ~ loadGantt ~ flattenActivities:", flattenActivities)
            // window.ganttInstance.init(ganttContainer);
        
        }
        // const ganttStartDates
        // collapse task preference
        const collapsedTasks = tabPreferences.gantt_collapsed_tasks || [];
        
        window.ganttInstance.eachTask((act) => {
            const task = act;
            task.$open = collapsedTasks.indexOf(task.identity) === -1;
            if (task.$open === false && window.ganttInstance.isSplitTask(task)) {
                window.ganttInstance.close(t.id);
            }
            // reselect task if multiselect before
            if (multiTaskIds.length > 0 && multiTaskIds.includes(task.serverId)) {
                window.ganttInstance.selectTask(task.id);
            }
        })

            // markers
            const markers = tabPreferences?.gantt_markers ?? [];
            markers
                .filter((i) => i.show && !i.markerStartDate)
                .forEach((marker) => {
                    window.ganttInstance.addMarker({
                        start_date: moment(marker.markerDate),
                        css: marker.markerClass,
                        text: marker.markerName,
                        title: moment(marker.markerDate).format('YYYY-MM-DD'),
                    });
                });
            // ajouter un espace au début et ala fin du gantt lorsqu'il y a des marqueurs ou on affiche la ligne rouge
            addVirtualTasks();

            const sort = tabPreferences?.planning_sort;
            if (sort && window.ganttInstance.config.columns.find((c) => c.name === sort.field)) {
                window.ganttInstance.sort(sort.field, sort.isDesc);
                // eslint-disable-next-line no-underscore-dangle
                window.ganttInstance._sort = {
                    name: sort.field,
                    direction: sort.isDesc ? 'asc' : 'desc',
                };
            }

        window.ganttInstance.render();
        setProcessingGantt(false);
    }, [multiTaskIds, activitiesDictionary]);

    // const reloadGantt = useCallback(
    //     async (caller) => {
    //         setProcessingGantt(true);
    //         console.log("🚀 ~ filteredFlattenActivities:", filteredFlattenActivities)
    //         await loadGantt(filteredFlattenActivities, caller);
    //     },
    //     [filteredFlattenActivities, activitiesDictionary]
    // );

    const onGanttRefresh = useCallback(async () => {
        await saveScrollState(updateTabPreferences);
        const filtered = await generateFilteredFlattenActivities(activitiesDictionary, planningCustomFields);
        await loadGantt(filtered);
        
    }, [activitiesDictionary]);

    useEffect(() => {
        if (refreshGantt > 0) {
            onGanttRefresh();
        }
    }, [refreshGantt]);

    // used when updating no working days
    useEffect(() => {
        const onGanttRerender = async () => {
            window.ganttInstance?.render();
        };
        if (reRenderGantt > 0) {
            onGanttRerender();
        }
    }, [reRenderGantt]);

    // KEYBOARD SHORTCUTS
    // useHotkeys(
    //     'f6',
    //     async (e) => {
    //         e.preventDefault();
    //         setDisableReloading(async (current) => {
    //             if (!current) {
    //                 setProcessingGantt(true);
    //                 await loadGantt(cloneActivityDictionary.current);
    //                 return true;
    //             }
    //             return current;
    //         });
    //     }
    //     // { filterPreventDefault: true }
    // );
    useHotkeys(
        'shift+w',
        async (e) => {
            e.preventDefault();
            showTaskActions();
        }
        // { filterPreventDefault: true }
    );

    useEffect(() => {
        const onGanttLoading = async () => {
            try {
                if (ganttContainer && loadingGantt === false && planningSelected) {
                    console.log('ON INIT INSIDE');
                    setProcessingGantt(true);

                    // if (window.ganttInstance) {
                    //     console.log('destroying 1')

                    //     window.ganttInstance.destructor();
                    //     gantt?.destructor();
                    // }

                    window.ganttInstance = dxhtmlGantt.getGanttInstance({ container: ganttContainer });
                    /* Sauvegarde de la préférence lors de changement de l’ordre des colonnes (Drag n Drop sur colonnes du Gantt) */
                    window.ganttInstance.attachEvent('onGanttReady', () => {
                        const grid = window.ganttInstance.$ui.getView('grid');
                        grid.attachEvent('onAfterColumnReorder', () => {
                            updateTabPreferences({
                                gantt_columns_order: window.ganttInstance.config.columns.reduce((agg, el, index) => {
                                    const oldObject = { ...agg };
                                    // avoid falsy values, avoiding 0 value of order
                                    oldObject[el.id] = index + 1;
                                    return oldObject;
                                }, {}),
                            }).then(() => {
                                if (window.ganttInstance.config.columns[0].tree === false) {
                                    refreshPlanningSelected();
                                }
                            });
                        });
                    });

                    ganttConfigure(tabPreferences?.gantt_columns || userPreferences?.default_gantt_columns || []);
                    ganttLabellise();
                    ganttEvents();
                    await loadGantt(filteredFlattenActivities, 'ON INIT');
                    setTaskDrawerVisible(false);
                    console.log('ON INIT INSIDE 2');
                }
                if (loadingGantt && window.ganttInstance) {
                    saveScrollState(updateTabPreferences);
                }
            } catch (error) {
                console.log('cathed', error);
                requestError(error, 'Gantt');
                setProcessingGantt(false);
            }
        };
        onGanttLoading();
        // return () => {
        //     if (window.ganttInstance) {
        //         console.log('destroying')
        //         window.ganttInstance.destructor();
        //         // gantt?.destructor();
        //     }
        // }
    }, [loadingGantt]);

    const handleDeleteMultipleActivity = async () => {
        try {
            await ActivityService.deleteMultipleActivity(multiTaskIds);
            // multiTaskIds.forEach((taskId) => window.ganttInstance.deleteTask(taskId));
            setMultiTaskIds([]);
            refreshPlanningSelected(true);
        } catch (error) {
            requestError(error, t('gantt.delete_multiple'));
        }
    };

    return (
        <>
            {/* <Spin spinning={loadingGantt || processingGantt} size="large"> */}
            <TasksStripesCSS tasks={tasksStripesStyles} />
            <LinkBackgroundCSS links={linksData} />
            {tabPreferences?.gantt_parameters?.showCriticalPath && (
                <CriticalTaskStyle color={tabPreferences?.gantt_parameters?.criticalPathColor ?? 'red'} />
            )}
            {userPreferences?.gantt_style?.gridHeaderColors && (
                <GridHeaderColor
                    gridBackgroundColor={userPreferences?.gantt_style?.gridHeaderColors.gridBackgroundColor}
                    gridTextColor={userPreferences?.gantt_style?.gridHeaderColors.gridTextColor}
                />
            )}
            {tabPreferences?.gantt_simple_activity_parameters && (
                <SimpleTaskColor
                    show={tabPreferences?.gantt_simple_activity_parameters.show}
                    fontColor={tabPreferences?.gantt_simple_activity_parameters.fontColor}
                    color={tabPreferences?.gantt_simple_activity_parameters.color}
                    fontSize={tabPreferences?.gantt_simple_activity_parameters.fontSize}
                    fontStyle={tabPreferences?.gantt_simple_activity_parameters.fontStyle}
                />
            )}
            {(tabPreferences?.gantt_level_parameters ?? []).map((p, index) => (
                <TaskLevelColor
                    key={`level-style${generateString(5)}`}
                    level={index}
                    show={p.show}
                    fontColor={p.fontColor}
                    color={p.color}
                    fontSize={p.fontSize}
                    fontStyle={p.fontStyle}
                />
            ))}
            {(getArrayInListByCondition(globalNeedList, 'isLevel', false) ?? []).map((res) => (
                <TaskResourceColor
                    key={`resource-style${generateString(5)}`}
                    color={res.color ?? '#000000'}
                    name={res.id.toString()}
                />
            ))}
            {(getArrayInListByCondition(planningNeedList, 'isLevel', false) ?? []).map((res) => (
                <TaskResourceColor
                    key={`resource-style${generateString(5)}`}
                    color={res.color ?? '#000000'}
                    name={res.id.toString()}
                />
            ))}
            {(tabPreferences?.gantt_markers ?? [])
                .filter((i) => i.show)
                .map((p) => (
                    <MarkerStyle
                        key={`marker-style${generateString(5)}`}
                        class={p.markerClass}
                        fontColor={p.fontColor}
                        lineColor={p.lineColor}
                    />
                ))}
            {/* <div className="w-full flex justify-between items-center p-1">
                <ValidatePlanningModal />
            </div> */}
            <GanttToolbar isGantt />
            <div
                className="flex-grow"
                style={{
                    fontFamily: tabPreferences?.gantt_parameters?.fontFamily ?? 'inherit',
                    height: '120px',
                    minHeight: '0px',
                }}
            >
                <GanttWrapper
                    hasBaseLine={hasBaseLine}
                    marginTop={((tabPreferences.gantt_parameters?.rowHeight || 35) * 0.7) / 4}
                    id="gantt-wrapper"
                    ref={(element) => {
                        ganttContainer = element;
                    }}
                    className={`h-full w-full ${loadingGantt ? 'hidden' : ''}`}
                />
            </div>
            {/* </Spin> */}
            {multiTaskBannerVisible && multiTaskIds.length > 1 && modeSelected !== 'archive' && (
                <div
                    className="absolute flex items-center justify-between w-1/2 p-2 border border-gray-700 bg-white"
                    style={{ bottom: '-16px', left: '50%', zIndex: 10 }}
                >
                    <p>
                        {multiTaskIds.length} {t('gantt.selected_activities')}
                    </p>
                    <div className="flex items-center">
                        <MultipleActivityEdit activitiesId={multiTaskIds} />
                        {canDuplicateSelection && <MultipleActivityDuplication activitiesId={multiTaskIds} />}

                        <MultipleLinkCreation shouldUpdate={updateMultipleActivityActionComponent} />
                        {canDuplicateSelection && (
                            <Popconfirm
                                title={
                                    <span style={{ width: '230px', display: 'block' }}>
                                        {t('gantt.delete_multiple_text')}
                                    </span>
                                }
                                onCancel={() => {}}
                                onConfirm={() => handleDeleteMultipleActivity()}
                                okText={t('general.yes')}
                                cancelText={t('general.no')}
                                placement="topRight"
                            >
                                <Button danger type="text">
                                    {t('general.delete')}
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </div>
            )}

            {selectedTask && (
                <DrawerActivity activity={selectedTask} drawerVisible={taskDrawerVisible} drawerTab={taskDrawerTab} />
            )}
            {/* {selectedTask && (
                <AddResourceModal ref={addNewResourceRef} activity={selectedTask} onResourceAdded={() => {}} />
            )} */}
        </>
    );
};
export default Gantt;
