import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import useMailTo from '../../hooks/useMailTo.hook';


const ReportProblemMenu = () => {
    const { t } = useTranslation();
    const {sendProblemMail} = useMailTo()

    const handleReportProblem = () => {
        sendProblemMail('');
    }


    return (
        <>
            <Menu.Item key="logout_menu" onClick={handleReportProblem}>
                <FontAwesomeIcon icon={faMailBulk} fixedWidth />
                {t('menu.report_problem')}
            </Menu.Item>
        </>
    );
};

export default ReportProblemMenu;
