import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import CustomFieldService from '../../services/custom-field.service';
import { CustomFieldsMenuContext } from '../../contexts/custom-fields/CustomFieldsMenuContext';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { requestError } from '../../helpers/notification';

const CustomFieldEditionForm = ({ customField }) => {
    const {t} = useTranslation('translation', {keyPrefix: 'modal_custom_fields'})
    const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { setModalContent, setShouldUpdatePlanning } = useContext(CustomFieldsMenuContext);
    const { planningSelected, refreshPlanningCustomFields } = useContext(PlanningContext);
    const [customFieldType, setCustomFieldType] = useState('text');

    const createCustomField = (values) => {
        setLoading(true);
        CustomFieldService.createCustomField(values.name, customFieldType, planningSelected.id)
            .then((result) => {
                setLoading(false);
                refreshPlanningCustomFields('new', result);
                setShouldUpdatePlanning(true);
                setModalContent(null);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('error_add_custom_field'));
            });
    };

    const updateCustomField = (values) => {
        setLoading(true);
        CustomFieldService.updateCustomFieldName(planningSelected.id, customField.id, values.name)
            .then((result) => {
                setLoading(false);
                refreshPlanningCustomFields('edit', result);
                setShouldUpdatePlanning(true);
                setModalContent(null);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('error_edit_custom_field'));
            });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }
        if (customField) {
            updateCustomField(values);
        } else {
            createCustomField(values);
        }
    };

    const convertCustomFieldType = (type) => {
        if (type.text) {
            return 'text';
        }
        if (type.numeric) {
            return 'numeric';
        }
        return 'choice';
    };

    const entity = {
        name: customField ? customField.name : null,
        type: customField ? convertCustomFieldType(customField.type) : 'text',
    };

    return (
        <Form form={form} layout="vertical" name="custom_field_edit_form" initialValues={entity} onFinish={submitForm}>
            <Form.Item label={generalTranslation('name')} name="name" rules={[{ required: true, message: generalTranslation('required_field') }]}>
                <Input />
            </Form.Item>
            <Form.Item label={t('custom_field_type')} name="type">
                <Radio.Group
                    onChange={(event) => setCustomFieldType(event.target.value)}
                    value={customFieldType}
                    disabled={!!customField}
                >
                    <Radio value="text">{t('type_text')}</Radio>
                    <Radio value="numeric">{t('type_numeric')}</Radio>
                    <Radio value="choice">{t('type_choice')}</Radio>
                </Radio.Group>
            </Form.Item>
            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={() => setModalContent(null)}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                    {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

CustomFieldEditionForm.propTypes = {
    customField: PropTypes.object,
};

CustomFieldEditionForm.defaultProps = {
    customField: null,
};

export default CustomFieldEditionForm;
