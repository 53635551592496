// Function to store logs locally
function storeLogsLocally(logs) {
    const timestamp = new Date().toISOString();
    const logEntry = `${timestamp}: ${logs}`;
    // Store the log entry in a local variable or an array
    const storedLogs = localStorage.getItem('consoleLogs') ? JSON.parse(localStorage.getItem('consoleLogs')) : [];
    storedLogs.push(logEntry);
  
    // Store the updated logs in localStorage
    localStorage.setItem('consoleLogs', JSON.stringify(storedLogs));
  }
  
  export default storeLogsLocally;