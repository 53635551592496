import React, { useRef, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import {
    Row,
    Select,
    Popconfirm,
    Button,
    Tooltip,
    List,
    Breadcrumb,
    Segmented,
    Space,
    Input,
    Spin,
    Empty,
    Checkbox,
} from 'antd';
import styled from 'styled-components';
import {
    FileOutlined,
    EditOutlined,
    EyeOutlined,
    LockOutlined,
    UserOutlined,
    FolderOutlined,
    HomeOutlined,
    ArrowLeftOutlined,
    BarsOutlined,
    AppstoreOutlined,
    SortAscendingOutlined,
    FolderAddOutlined,
    DeleteOutlined,
    FileZipOutlined,
    BookOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusSvg } from '../../assets/icons/plus.svg';
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';
import AddProjectModal from '../projects/modals/AddProjectModal';
import EditProjectModal from '../projects/modals/EditProjectModal';
import AddProjectPermissionModal from '../projects/modals/AddProjectPermissionModal';
import FolderService from '../../services/folder.service';
import { requestError } from '../../helpers/notification';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { USER_ACCESS, getPlanningAccess } from '../../helpers/planning';
import { generateString } from '../../helpers/string-helper';
import activityService from '../../services/activity.service';
import customFieldService from '../../services/custom-field.service';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import { EXPLORER_ITEM_TYPE, EXPLORER_VIEW_MODE, EXPLORER_VIEW_TYPE } from '../../constants/ProjectExplorer';
import ExplorerItem from './ExplorerItem';
import { UserContext } from '../../contexts/app/UserContext';

const AddButton = styled.button`
    font-size: 13px;
    line-height: 16px;
    height: 32px;
`;

const PlusIcon = styled(PlusSvg)`
    left: 8px;
`;

const CloseIcon = styled(CloseSvg)`
    width: 16px;
    height: 16px;
`;

const SectionHeader = styled.div`
    font-size: 13px;
`;

const SectionTitle = styled.p`
    // line-height: 16px;
`;

const SectionContent = styled.div`
    // background: #f8f8f8;
`;

const Table = styled.table`
    color: #828282;
    font-size: 12px;
`;

const TableRow = styled.tr`
    border-top: 1px solid #f8f8f8;
`;

const TableData = styled.td`
    padding: 8px 9px;
`;

const ProjectExplorer = ({ folder = { id: null }, onItemSelected, onlyExplorer = false, specificMode }) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'projects' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [allFolders, setAllFolders] = useState(null);
    const [explorerData, setExplorerData] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(folder);
    const [explorerViewType, setExplorerViewType] = useState('grid');
    const [explorerBreadcrumb, setExplorerBreadcrumb] = useState([]);
    const explorerViewModes = EXPLORER_VIEW_MODE(i18n);
    const [selectedViewModes, setSelectedViewModes] = useState(specificMode || [explorerViewModes.LIVE.value]);

    const [isSorted, setIsSorted] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [selected, setSelected] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState([]);
    const addProjectModalRef = useRef();
    const editProjectModalRef = useRef();
    const addProjectPermissionModalRef = useRef();
    const {
        requestPlanningsInFolderForMode,
        rootFolders,
        updatedFolderBroadcast,
        subFoldersList,
        updateSubFoldersList,
    } = useContext(PlanningContext);
    const { currentUser } = useContext(UserContext);
    const [planningJobs, setPlanningJobs] = useState([]);

    const explorerItemMapper = (item, type, icon = <FileOutlined />, subType = '1') => ({
        ...item,
        id: item.id,
        key: `${type}-${item.id}`,
        title: item.name,
        parentId: type === EXPLORER_ITEM_TYPE.FOLDER ? item.parentId : item.folderId,
        type,
        subType,
        rootActivityId: item.rootActivityId,
        icon: type === EXPLORER_ITEM_TYPE.FOLDER ? <FolderOutlined /> : icon,
    });

    const switchFolder = async (itemId, folderIdManual = null) => {
        setLoading(true);
        const idFolder = folderIdManual || itemId;
        let data = [];
        let plannings = [];
        if (idFolder) {
            if (subFoldersList[idFolder]) {
                console.log('=> ProjectList USING CACHE DATA');
                data = subFoldersList[idFolder];
                // plannings = await FolderService.listPlannings(idFolder);
            } else {
                data = await FolderService.listSubFolders(idFolder);
                updateSubFoldersList(idFolder, data);
                // plannings = await FolderService.listPlannings(idFolder);
            }
            data = data.childFolders.map((item) => explorerItemMapper(item, EXPLORER_ITEM_TYPE.FOLDER));
            /* eslint-disable */
            for (const mode of selectedViewModes) {
                switch (mode) {
                    case explorerViewModes.ARCHIVE.value:
                        const archives = await requestPlanningsInFolderForMode(idFolder, mode);
                        plannings.push(
                            ...archives.map(
                                (item) => explorerItemMapper({...item, mode: explorerViewModes.ARCHIVE.value}, EXPLORER_ITEM_TYPE.PLANNING, <FileZipOutlined />),
                                '2'
                            )
                        );

                        break;
                    case explorerViewModes.SANDBOX.value:
                        const sandboxPlannings = await requestPlanningsInFolderForMode(idFolder, mode);
                        plannings.push(
                            ...sandboxPlannings.map((item) =>
                                explorerItemMapper({...item, mode: explorerViewModes.SANDBOX.value}, EXPLORER_ITEM_TYPE.PLANNING, <BookOutlined />, '3')
                            )
                        );

                        break;
                    case explorerViewModes.LIVE.value:
                        const livePlannings = await requestPlanningsInFolderForMode(idFolder, mode);
                        plannings.push(
                            ...livePlannings.map((item) => explorerItemMapper({...item, mode: explorerViewModes.LIVE.value}, EXPLORER_ITEM_TYPE.PLANNING))
                        );

                        break;
                    default:
                        break;
                }
            }
            /* eslint-enable */
            if (onlyExplorer) {
                plannings = plannings.map((p) => {
                    const planningAccess = getPlanningAccess(p, currentUser);

                    return {
                        ...p,
                        access: planningAccess,
                        disabled: planningAccess === USER_ACCESS.NONE,
                    };
                });
            }
            setExplorerData([...data, ...plannings]);
        } else {
            // if null, we are on root
            setExplorerData(rootFolders.map((item) => explorerItemMapper(item, EXPLORER_ITEM_TYPE.FOLDER)));
        }
        setSelected(null);
        setLoading(false);
    };

    const computeBreadcrumb = useCallback(
        async (parent) => {
            // push current folder
            const breadcrumb = [];
            if (parent.id) {
                breadcrumb.push({ id: parent.id, name: parent.title || parent.name, parentId: parent.parentId });
                let newParentId = parent.parentId;
                /* eslint-disable */
                while (newParentId !== null) {
                    const newParentData = await FolderService.showFolder(newParentId);
                    breadcrumb.push({
                        id: newParentData.id,
                        name: newParentData.name,
                        parentId: newParentData.parentId,
                    });
                    newParentId = newParentData.parentId;
                }
            }
            /* eslint-enable */
            breadcrumb.push({ id: null, name: <HomeOutlined />, parentId: null });
            setExplorerBreadcrumb(breadcrumb.reverse());
        },
        [currentFolder, subFoldersList]
    );

    useEffect(() => {
        if (onItemSelected) onItemSelected(selected);
    }, [selected]);

    useEffect(() => {
        if (currentFolder) {
            setSearchVal('');
            switchFolder(currentFolder.id);
            computeBreadcrumb(currentFolder);
        } else {
            switchFolder(null);
            setExplorerBreadcrumb([]);
        }
    }, [currentFolder, selectedViewModes]);

    const currentFolderData = useMemo(() => {
        let result = [...explorerData];
        if (searchVal) {
            result = result.filter((item) => item.title.toLowerCase().includes(searchVal.toLowerCase()));
        }
        if (isSorted) {
            result = orderBy(result, ['type', 'title']);
        }
        return result;
    }, [explorerData, isSorted, searchVal]);

    const lastBreadcrumbFolder = useMemo(
        () => (explorerBreadcrumb.length >= 2 ? explorerBreadcrumb[explorerBreadcrumb.length - 2] : null),
        [explorerBreadcrumb]
    );

    const flattenFolders = (folderList) =>
        folderList.reduce((agg, folderData) => {
            agg.push({
                id: folderData.id,
                name: folderData.name,
                parentId: folderData.parentId,
            });

            if (folderData.childFolders.length) {
                const children = flattenFolders(folderData.childFolders);

                children.forEach((childFolder) => {
                    agg.push({
                        id: childFolder.id,
                        name: childFolder.name,
                    });
                });
            }
            return agg;
        }, []);

    const loadRootFolders = () => {
        setLoading(true);
        setAllFolders(flattenFolders(rootFolders));
        setLoading(false);
    };

    // updating list of folder in addproject modal
    useEffect(() => {
        if (rootFolders.length) {
            setAllFolders(flattenFolders(rootFolders));
        }
    }, [rootFolders]);

    const loadFolderPermissions = (id) => {
        FolderService.listFolderPermissions(id)
            .then((data) => {
                setPermissions(orderBy(data, [(o) => o.team.name]));
            })
            .catch((error) => {
                requestError(error, t('permission_loading_error'));
            });
    };

    const updateFolderPermission = (permissionId, teamId, access) => {
        const [, folderId] = selected.key.split('-');

        FolderService.deleteFolderPermission(folderId, permissionId)
            .then(() =>
                FolderService.createFolderPermission(folderId, teamId, access).then(() => {
                    loadFolderPermissions(folderId);
                })
            )
            .catch((error) => {
                requestError(error, t('permission_update_error'));
            });
    };

    const deleteFolderPermission = (folderId, permissionId) => {
        FolderService.deleteFolderPermission(folderId, permissionId)
            .then(() => {
                loadFolderPermissions(folderId);
            })
            .catch((error) => {
                requestError(error, t('permission_remove_error'));
            });
    };

    const handleDeleteFolder = (folderId) => {
        const indexObject = explorerData.findIndex((i) => i.id === folderId);
        if (indexObject > -1) {
            console.log('remove');
            const newExplorerData = [...explorerData];
            newExplorerData.splice(indexObject, 1);
            setExplorerData(newExplorerData);
        }
    };

    const deleteFolder = () => {
        const [, folderId] = selected.key.split('-');
        setSelected(null);
        FolderService.deleteFolder(folderId)
            .then(() => {
                handleDeleteFolder(+folderId);
            })
            .catch((error) => {
                if (error.response.data.code === 422)
                    requestError(error, t('folder_contain_warning').concat(' \n ', error.response.data.value));
                else requestError(error, t('folder_delete_error'));
            });
    };

    const loadPlanningPermissions = (selectedItem) => {
        activityService.listPlanningTeamJobs(selectedItem.rootActivityId).then((teamJobs) =>
            FolderService.listPlanningPermissions(selectedItem.id)
                .then((response) => {
                    const permissionsJobs = response.map((permission) =>
                        Object.assign(permission, {
                            jobNames: teamJobs.find((team) => team.teamId === permission.team.id)?.jobNames || [],
                            teamId: permission.team.id,
                        })
                    );
                    setPermissions(orderBy(permissionsJobs, [(o) => o.team.name]));
                })
                .catch((error) => {
                    requestError(error, t('permission_loading_error'));
                })
        );
    };

    const updatePlanningPermission = (permissionId, teamId, access) => {
        const [, planningId] = selected.key.split('-');

        FolderService.deletePlanningPermission(planningId, permissionId)
            .then(() =>
                FolderService.createPlanningPermission(planningId, teamId, access).then(() => {
                    loadPlanningPermissions(selected);
                })
            )
            .catch((error) => {
                requestError(error, t('permission_update_error'));
            });
    };

    const deletePlanningPermission = (planningId, permissionId) => {
        FolderService.deletePlanningPermission(planningId, permissionId)
            .then(() => {
                loadPlanningPermissions(selected);
            })
            .catch((error) => {
                requestError(error, t('permission_remove_error'));
            });
    };

    const updatePermission = (permissionId, teamId, access) => {
        const [type] = selected.key.split('-');

        switch (type) {
            case EXPLORER_ITEM_TYPE.FOLDER:
                updateFolderPermission(permissionId, teamId, access);
                break;
            case EXPLORER_ITEM_TYPE.PLANNING:
                updatePlanningPermission(permissionId, teamId, access);
                break;
            default:
        }
    };

    const deletePermission = (permissionId) => {
        const [type, id] = selected.key.split('-');

        switch (type) {
            case EXPLORER_ITEM_TYPE.FOLDER:
                deleteFolderPermission(id, permissionId);
                break;
            case EXPLORER_ITEM_TYPE.PLANNING:
                deletePlanningPermission(id, permissionId);
                break;
            default:
        }
    };

    //--------------------------------------------------
    // Handlers
    //--------------------------------------------------
    const onSelectTreeData = (itemSelected) => {
        setPermissions([]);
        setSelected(itemSelected);
        if (!onlyExplorer) {
            switch (itemSelected.type) {
                case EXPLORER_ITEM_TYPE.FOLDER:
                    loadFolderPermissions(itemSelected.id);
                    break;
                case EXPLORER_ITEM_TYPE.PLANNING:
                    loadPlanningPermissions(itemSelected);
                    break;
                default:
            }
        }
    };

    const onFolderAdded = (folderData) => {
        console.log('folder added', folderData);
    };

    const isFolder = () => selected?.key?.split('-')[0] === EXPLORER_ITEM_TYPE.FOLDER;
    const isPlanning = () => !isFolder();

    useEffect(() => {
        const getPlanningJobs = async () => {
            if (selected && isPlanning()) {
                const planningFields = await customFieldService.planningCustomFields(selected?.id);
                const jobsList = planningFields
                    .filter((unifiedfield) => unifiedfield.name === JOB_FIELD)
                    .map((unifiedfield) => unifiedfield.type.choices)
                    .flatMap((choices) => Object.keys(choices));
                setPlanningJobs(jobsList);
            }
        };
        getPlanningJobs();
    }, [selected]);

    useEffect(() => {
        loadRootFolders();
    }, []);

    // broadcast

    const handleAutoUpdate = (updatedObject) => {
        setLoading(true);
        if (updatedObject.name) {
            // test if existing in treeData
            const indexObject = explorerData.findIndex((i) => i.id === updatedObject.id);
            if (indexObject !== -1) {
                const newExplorerData = [...explorerData];
                newExplorerData[indexObject].title = updatedObject.name;
                setExplorerData(newExplorerData);
            } else {
                const newExplorerData = [...explorerData];
                if (currentFolder.id === updatedObject.parentId) {
                    newExplorerData.push(explorerItemMapper(updatedObject, EXPLORER_ITEM_TYPE.FOLDER));
                    setExplorerData(newExplorerData);
                }
            }
            setLoading(false);
        }
    };

    const getPermissionIcon = (planningAccess, access) => {
        let icon = <></>;
        const color =
            access === planningAccess || (planningAccess === USER_ACCESS.FULLACCESS && access === USER_ACCESS.READWRITE)
                ? '#00ff00'
                : '#ff0000';
        switch (planningAccess) {
            case USER_ACCESS.READ:
                icon = <EyeOutlined className="mr-2" style={{ color }} />;
                break;
            case USER_ACCESS.READWRITE:
                icon = <EditOutlined className="mr-2" style={{ color }} />;
                break;
            case USER_ACCESS.NONE:
                icon = <LockOutlined className="mr-2" style={{ color }} />;
                break;
            case USER_ACCESS.FULLACCESS:
                icon = <UserOutlined className="mr-2" style={{ color }} />;
                break;
            default:
                break;
        }
        return icon;
    };

    useEffect(() => {
        if (allFolders && updatedFolderBroadcast) {
            console.log('HAHAHAHA🚀 ~ useEffect ~ updatedFolderBroadcast:', updatedFolderBroadcast);
            if (updatedFolderBroadcast.updatedData) {
                if (
                    selected &&
                    updatedFolderBroadcast.updatedData.name &&
                    selected.id === updatedFolderBroadcast.updatedData.id
                ) {
                    setSelected({ ...selected, title: updatedFolderBroadcast.updatedData.name });
                }
                let explorerItemObject;
                if (updatedFolderBroadcast.type === 'Folder') {
                    explorerItemObject = explorerItemMapper(
                        updatedFolderBroadcast.updatedData,
                        EXPLORER_ITEM_TYPE.FOLDER
                    );
                }
                if (updatedFolderBroadcast.type === 'Planning') {
                    explorerItemObject = explorerItemMapper(
                        updatedFolderBroadcast.updatedData,
                        EXPLORER_ITEM_TYPE.PLANNING,
                        <FileOutlined />
                    );
                }
                handleAutoUpdate(explorerItemObject);
            } else {
                handleDeleteFolder(+updatedFolderBroadcast.id);
                if (selected?.id === +updatedFolderBroadcast.id) {
                    setSelected(null);
                    setPermissions([]);
                    setLoading(false);
                }
            }
        }
    }, [updatedFolderBroadcast]);

    const handleJobsChange = (value, permission) => {
        // if (value.length === 0) {
        //     return;
        // }
        let jobsToBeSelected = [];

        if (permission.jobNames.indexOf(ALL_JOBS) === -1 && value.indexOf(ALL_JOBS) > -1) {
            jobsToBeSelected = [ALL_JOBS];
        } else {
            jobsToBeSelected = [...value];
        }
        if (permission.jobNames.indexOf(ALL_JOBS) > -1) {
            jobsToBeSelected = value.filter((i) => i !== ALL_JOBS);
        }
        if (permission.access === USER_ACCESS.READWRITE && jobsToBeSelected.length > 0) {
            activityService
                .updateJob(selected.rootActivityId, permission.team.id, jobsToBeSelected)
                .then(() => loadPlanningPermissions(selected));
        }
    };

    return (
        <>
            <Row className=" divide-x h-full w-full">
                <div className={`${onlyExplorer ? 'w-full' : 'w-4/6'} flex flex-grow  flex-col p-2 h-full`}>
                    <div style={{ minHeight: '2.5rem' }} className="border-b-2 mb-1 flex justify-between">
                        <div className="flex items-center">
                            <Space>
                                <Button
                                    disabled={!lastBreadcrumbFolder}
                                    size="small"
                                    onClick={() => setCurrentFolder(lastBreadcrumbFolder)}
                                    icon={<ArrowLeftOutlined />}
                                />
                                <Breadcrumb separator=">">
                                    {/* <Breadcrumb.Item
                                        onClick={() => setCurrentFolder(null)}
                                        className="cursor-pointer hover:text-primary"
                                    >
                                        <HomeOutlined />
                                    </Breadcrumb.Item> */}
                                    {explorerBreadcrumb.map((breadcrumbData) => (
                                        <Breadcrumb.Item
                                            key={generateString(6)}
                                            className="cursor-pointer hover:text-primary"
                                            onClick={() => setCurrentFolder(breadcrumbData)}
                                        >
                                            {breadcrumbData.name}
                                        </Breadcrumb.Item>
                                    ))}
                                </Breadcrumb>
                            </Space>
                        </div>
                        <div className="flex items-center">
                            <Space>
                                {!onlyExplorer && (
                                    <>
                                        <Button
                                            className="flex items-center"
                                            type="primary"
                                            size="small"
                                            icon={<FolderAddOutlined />}
                                            onClick={
                                                addProjectModalRef.current ? addProjectModalRef.current.openModal : null
                                            }
                                        >
                                            {generalTranslation('add')}
                                        </Button>
                                        <Tooltip
                                            title={generalTranslation('rename')}
                                            onClick={
                                                editProjectModalRef.current && editProjectModalRef.current.openModal
                                            }
                                        >
                                            <Button disabled={!selected} size="small" icon={<EditOutlined />} />
                                        </Tooltip>
                                        <Popconfirm
                                            title={t('project_delete_warning').concat(` ${selected?.title} ?`)}
                                            okText={generalTranslation('yes')}
                                            cancelText={generalTranslation('no')}
                                            onConfirm={() => deleteFolder(selected.key)}
                                        >
                                            <Button
                                                disabled={!selected || isPlanning()}
                                                size="small"
                                                type="danger"
                                                icon={<DeleteOutlined />}
                                            />
                                        </Popconfirm>
                                    </>
                                )}
                                
                                    <Checkbox.Group
                                        options={Object.values(explorerViewModes)}
                                        value={selectedViewModes}
                                        onChange={(values) => setSelectedViewModes(values)}
                                    />
                                
                                <Button
                                    size="small"
                                    className={`${isSorted ? 'bg-primary' : null}`}
                                    onClick={() => setIsSorted(!isSorted)}
                                    icon={<SortAscendingOutlined />}
                                />

                                <Input.Search
                                    allowClear
                                    size="small"
                                    placeholder={generalTranslation('search')}
                                    onSearch={(value) => setSearchVal(value)}
                                    style={{ width: 200 }}
                                />
                                <Segmented
                                    size="small"
                                    value={explorerViewType}
                                    options={[
                                        {
                                            value: EXPLORER_VIEW_TYPE.GRID,
                                            icon: <AppstoreOutlined />,
                                        },
                                        {
                                            value: EXPLORER_VIEW_TYPE.LIST,
                                            icon: <BarsOutlined />,
                                        },
                                    ]}
                                    onChange={(val) => setExplorerViewType(val)}
                                />
                            </Space>
                        </div>
                    </div>
                    <div className="flex-grow overflow-auto" id="explorerItemContainer">
                        {explorerViewType === EXPLORER_VIEW_TYPE.GRID ? (
                            <List
                                loading={loading}
                                grid={{
                                    xs: 1,
                                    sm: 3,
                                    md: 4,
                                    lg: 6,
                                    xl: 8,
                                    xxl: 10,
                                }}
                                dataSource={currentFolderData}
                                renderItem={(item) => (
                                    <List.Item className="mx-1">
                                        <ExplorerItem
                                            viewType={explorerViewType}
                                            onClick={() => {
                                                onSelectTreeData(item);
                                            }}
                                            onlyExplorer={onlyExplorer}
                                            onDblClick={() => setCurrentFolder(item)}
                                            explorerItem={item}
                                            isSelected={selected?.id === item.id}
                                        />
                                    </List.Item>
                                )}
                            />
                        ) : (
                            <Spin spinning={loading}>
                                <div className="h-full grid">
                                    {currentFolderData.map((item) => (
                                        <span className="w-1/5" key={item.key}>
                                            <ExplorerItem
                                                viewType={explorerViewType}
                                                onClick={() => {
                                                    onSelectTreeData(item);
                                                }}
                                                onlyExplorer={onlyExplorer}
                                                onDblClick={() => setCurrentFolder(item)}
                                                explorerItem={item}
                                                isSelected={selected?.id === item.id}
                                            />
                                        </span>
                                    ))}
                                </div>
                            </Spin>
                        )}
                    </div>
                </div>
                {!onlyExplorer && (
                    <div className="w-2/6 p-2">
                        <div className="flex flex-col h-full">
                            <SectionHeader className=" flex flex-col h-10">
                                <SectionTitle className="font-extrabold text-primary" style={{ cursor: 'pointer' }}>
                                    {selected ? selected.title : t('no_project_selected')}
                                </SectionTitle>
                                <p className="text-gray-500">{t('concerned_teams')}</p>
                            </SectionHeader>
                            <SectionContent className="flex-1 rounded" style={{ height: '90%' }}>
                                <div className="h-full flex flex-col">
                                    <div style={{ overflowY: 'scroll' }}>
                                        <Table className="w-full">
                                            <thead>
                                                <tr>
                                                    <th className="font-sans" style={{ width: 15 }}>
                                                        &nbsp;
                                                    </th>
                                                    <th className="font-sans">{generalTranslation('name')}</th>
                                                    <th className="font-sans">{generalTranslation('rights')}</th>
                                                    {isPlanning() && (
                                                        <th className="font-sans">{generalTranslation('jobs')}</th>
                                                    )}
                                                    <th className="font-sans" style={{ width: 32 }}>
                                                        &nbsp;
                                                    </th>
                                                </tr>
                                            </thead>
                                            {selected ? (
                                                <tbody>
                                                    {permissions.map((permission) => (
                                                        <TableRow key={generateString(4)}>
                                                            <TableData className="bg-white">
                                                                {getPermissionIcon(
                                                                    permission.realAccess,
                                                                    permission.access
                                                                )}
                                                            </TableData>
                                                            <TableData className="bg-white">
                                                                {permission.team.name}
                                                            </TableData>
                                                            <TableData className="bg-white">
                                                                <Select
                                                                    defaultValue={permission.access}
                                                                    style={{ width: 150 }}
                                                                    onChange={(value) =>
                                                                        updatePermission(
                                                                            permission.id,
                                                                            permission.team.id,
                                                                            value
                                                                        )
                                                                    }
                                                                >
                                                                    <Select.Option value="NONE">
                                                                        {generalTranslation('access_none')}
                                                                    </Select.Option>
                                                                    <Select.Option value="READ">
                                                                        {generalTranslation('access_read')}
                                                                    </Select.Option>
                                                                    <Select.Option value="WRITE">
                                                                        {generalTranslation('access_write')}
                                                                    </Select.Option>
                                                                </Select>
                                                            </TableData>
                                                            {isPlanning() && (
                                                                <TableData className="bg-white">
                                                                    <Select
                                                                        mode="multiple"
                                                                        value={permission.jobNames}
                                                                        disabled={
                                                                            permission.access !== USER_ACCESS.READWRITE
                                                                        }
                                                                        status={
                                                                            permission.jobNames.length === 0 &&
                                                                            permission.access === USER_ACCESS.READWRITE
                                                                                ? 'error'
                                                                                : ''
                                                                        }
                                                                        style={{ width: 150 }}
                                                                        onChange={(val) =>
                                                                            handleJobsChange(val, permission)
                                                                        }
                                                                    >
                                                                        {planningJobs.map((choice) => (
                                                                            <Select.Option
                                                                                key={generateString(6)}
                                                                                value={choice}
                                                                            >
                                                                                {choice}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </TableData>
                                                            )}
                                                            <TableData>
                                                                <Popconfirm
                                                                    title={t('permission_remove_warning')}
                                                                    okText={generalTranslation('yes')}
                                                                    cancelText={generalTranslation('no')}
                                                                    onConfirm={() => deletePermission(permission.id)}
                                                                >
                                                                    <CloseIcon className="cursor-pointer" />
                                                                </Popconfirm>
                                                            </TableData>
                                                        </TableRow>
                                                    ))}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="4">
                                                            <Empty />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                    <div className="mt-auto">
                                        {selected && (
                                            <>
                                                {addProjectPermissionModalRef.current && (
                                                    <AddButton
                                                        className="relative text-primary font-uniSansBold bg-white rounded-sm px-16"
                                                        onClick={addProjectPermissionModalRef.current.openModal}
                                                    >
                                                        {t('add_team')}
                                                        <PlusIcon className="absolute top-1/2 transform -translate-y-1/2" />
                                                    </AddButton>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </SectionContent>
                        </div>
                    </div>
                )}
            </Row>
            <AddProjectModal
                parent={currentFolder?.id}
                ref={addProjectModalRef}
                items={allFolders || []}
                onFolderAdded={onFolderAdded}
            />
            <EditProjectModal data={selected} ref={editProjectModalRef} />
            <AddProjectPermissionModal
                ref={addProjectPermissionModalRef}
                selected={selected}
                permissions={permissions}
                planningJobs={planningJobs}
                onPermissionAdded={() => onSelectTreeData(selected)}
            />
        </>
    );
};

export default ProjectExplorer;
