import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Row } from 'antd';
import CustomFieldService from '../../services/custom-field.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { CustomFieldsMenuContext } from '../../contexts/custom-fields/CustomFieldsMenuContext';
import { requestError } from '../../helpers/notification';

const CustomFieldDeleteForm = ({ customField }) => {
    const [loading, setLoading] = useState(false);
    const { setModalContent, setShouldUpdatePlanning } = useContext(CustomFieldsMenuContext);
    const { planningSelected, refreshPlanningCustomFields } = useContext(PlanningContext);

    const submitForm = () => {
        setLoading(true);
        CustomFieldService.deleteCustomField(planningSelected.id, customField.id)
            .then(() => {
                setLoading(false);
                refreshPlanningCustomFields('delete', {id: customField.id});
                setShouldUpdatePlanning(true);
                setModalContent(null);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, 'Erreur lors de la suppression du champ personnalisé');
            });
    };

    return (
        <Form name="period_remove_form" onFinish={submitForm}>
            <p>
                Êtes-vous sûr de vouloir supprimer le champ personnalisé <b>{customField.name}</b> ?
            </p>

            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={() => setModalContent(null)}>
                        Annuler
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Confirmer
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

CustomFieldDeleteForm.propTypes = {
    customField: PropTypes.object,
};

CustomFieldDeleteForm.defaultProps = {
    customField: null,
};

export default CustomFieldDeleteForm;
