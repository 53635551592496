import moment from 'moment';
import { range } from 'lodash';
import * as d3 from 'd3';
import {
    mergeActivityCalendarSliceWithTemporalSlice,
    mergeActivitySliceWithSimpleSlice,
    NeedSlice,
    SimpleSlice,
    sliceJoined,
} from 'slicing-gm';
import { NEED_QUANTITY_MODE, NEED_TYPE, NEED_VISIBILITY, SLICE_DATA_TYPE } from '../constants/Needs';
import { xAxisDateScaleFunction } from '../components/charts/CustomHistogramDateXaxis';
import { dateToTimestamp } from './planning';

moment.defineLocale(window.localStorage.getItem('lng') || 'fr', {
    week: {
        dow: 1, // First day of week is Monday
        doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
    },
});

// eslint-disable-next-line no-unused-vars
export const timer = (name, funct) => {
    const startTime = window.performance.now();
    const result = funct();
    const duration = window.performance.now() - startTime;
    console.log(name, ' : ', duration);
    return [result, duration];
};

/**
 * Genere un timestamp par unite de temps entre start et end.
 * @param startTime la date de debut de la generation.
 * @param endTime la date de fin de la generation.
 * @param timeUnit l'unite de temps
 * @returns {unknown[]|(*|number)[]} une liste de timestamp
 */
export const generatorTimestamp = (startTime, endTime, timeUnit) => {
    // @TODO unifié avec une ecrire du style new Date().add(1, timeUnit) ? (atttention au timing)
    // OPTI : Le calcul des timestamps manuellement est plus rapide.
    if (timeUnit === 'hours') {
        const hour = 3_600_000;
        const ceilStartTime = Math.ceil(startTime / hour) * hour;
        const floorEndTime = Math.floor(endTime / hour) * hour;
        return range(0, (floorEndTime - ceilStartTime) / hour).map((i) => ceilStartTime + i * hour);
    }
    // OPTI : Le calcul des timestamps manuellement est plus rapide.
    if (timeUnit === 'days') {
        const day = 3_600_000 * 24;
        const ceilStartTime = Math.ceil(startTime / day) * day;
        const floorEndTime = Math.floor(endTime / day) * day;
        return range(0, (floorEndTime - ceilStartTime) / day).map((i) => ceilStartTime + i * day);
    }
    // On utilise d3 pour le calcul plus complexe (non lineaire) comme les moins.
    // const startTime = isHourScale
    //                 ? dateToUTCDate(moment.utc(start).startOf('minute').valueOf())
    //                 : moment.utc(start).subtract(1, timeUnit).startOf('minute').valueOf();
    //             const endTime = isHourScale
    //                 ? dateToUTCDate(moment.utc(end).endOf('minute').valueOf())
    //                 : moment.utc(end).endOf('minute').valueOf();
    return d3
        .scaleTime()
        .domain([
            moment.utc(startTime).startOf('minute').subtract(1, timeUnit).valueOf(),
            moment.utc(endTime).add(1, 'minute').endOf('minute').valueOf(),
        ])
        .ticks(xAxisDateScaleFunction[timeUnit]())
        .map((v) => dateToTimestamp(v));
};

/**
 * Transforme une liste de timestamp en tableau d'objet {start,end}.
 * La date de début represente les timetamp impair, la date de fin les timestamps pair.
 * @param timestamps une liste de timestamp
 * @returns {{start: *, end: *}[]} un tableau contenant des objets { start, end}
 */
export const toSlice = (timestamps) =>
    range(0, timestamps.length / 2).map((i) => ({ start: timestamps[2 * i], end: timestamps[2 * i + 1] }));

/**
 * Transforme une liste d'objet {start,end} en une liste d'objet [start,end]
 * ou les plages continues sont fusionnees en une seul plage.
 * @param slices une liste d'objet {start, end}
 * @returns {*[]} une list d'objet {start, end}
 */
export const toContiniousSlice = (slices) => {
    if (slices.length <= 0) return [];
    const result = [];
    let previousSlice = slices[0];
    for (let i = 0; i < slices.length; i += 1) {
        if (previousSlice.end === slices[i].start) previousSlice.end = slices[i].end;
        else {
            result.push(previousSlice);
            previousSlice = slices[i];
        }
    }
    result.push(previousSlice);
    return result;
};

/**
 * Decoupe une liste d'objet {start;end} quelconques
 * en liste d'objet {start;end} de taille inférieur ou egal a l'unite de temps.
 * @param slices une liste d'objet {start,end}
 * @param timeUnit l'unite de temps de la decoupe.
 * @returns {*} une liste d'objet {start; end} de duree maximum egal a l unite de temps.
 */
export const transformTo = (slices, timeUnit) => {
    const minimalDurationByUnit = {
        hours: 1000 * 60 * 60,
        days: 1000 * 60 * 60 * 24,
        week: 1000 * 60 * 60 * 24 * 7,
        month: 1000 * 60 * 60 * 24 * 7 * 28,
        quarter: 1000 * 60 * 60 * 24 * 7 * 28 * 3,
        year: 1000 * 60 * 60 * 24 * 7 * 364,
    };
    // decoupe les plages superieur a l'unite de temps
    return slices
        .filter((slice) => minimalDurationByUnit[timeUnit] <= slice.end - slice.start)
        .flatMap((slice) => generatorTimestamp(slice.start, slice.end, timeUnit));
};

/**
 * Decoupe une liste d'objet {start,end} par une unite de temps
 * en s'assurant que les plages continues soit fusionné.
 * @see toContiniousSlice + @see transformTo
 * @param slices une liste d'objet {start,end}
 * @param timeUnit l'unite de temps de la decoupe.
 * @returns {*} une liste d'objet {start; end}
 */
export const toCalendar = (slices, timeUnit) => transformTo(toContiniousSlice(slices), timeUnit);

/**
 * Transforme un calendrier de temps non travaillé réelle avec des plages non fixe,
 * en une liste de slice réelle avec des plages non fixe.
 * @param calendar une liste de timestamp
 * @param start  la date minimal du premier slice voulu optionel.
 * @param end la date maximal du dernier slice voulu, optionel.
 * @returns {*[]} une liste de slice {start, end}
 */
const realCalendarToRealSlice = (calendar, start, end) => {
    const slices = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const slice of calendar.values()) {
        if (slice.start < end && start < slice.end) slices.push(new SimpleSlice(slice.start, slice.end));
        // Condition d'arret Optimisation
        if (slice.start > end) break;
    }
    return slices;
};

/**
 * Transforme un calendrier de temps non travaillé en une liste de slice,
 * une liste de timestamp en liste de slice selon l'unite de temps definie.
 * @param calendar une liste de timestamp
 * @param temporalityUnit une unite correspondant au type de calendrier fourni
 * @param start  la date minimal du premier slice voulut optionel.
 * @param end la date maximal du dernier slice voulu, optionel.
 * @returns {*[]} une liste de slice {start, end}
 */
const timeCalendarToSlice = (calendar, temporalityUnit, start, end) => {
    const slices = [];
    const minimalStartSlice = start ?? 0;
    const maximalStartSlice = end ?? 8_640_000_000_000_000; // Maximal timestamp
    // eslint-disable-next-line
    for (const sliceStart of calendar.values()) {
        const sliceEnd = moment.utc(sliceStart).add(1, temporalityUnit).valueOf();
        if (sliceStart < maximalStartSlice && minimalStartSlice < sliceEnd)
            slices.push(new SimpleSlice(sliceStart, sliceEnd));
        // Condition d'arret Optimisation
        if (sliceStart > maximalStartSlice) break;
    }
    return slices;
};

/**
 * Transforme un n'importe quel calendrier de temps non travaillé en une liste de slice.
 * @param calendar une liste de timestamp
 * @param temporalityUnit une unite correspondant au type de calendrier fourni
 * @param start  la date minimal du premier slice voulut optionel.
 * @param end la date maximal du dernier slice voulu, optionel.
 * @returns {*[]} une liste de slice {start, end}
 */
const calendarToSlice = (calendar, temporalityUnit, start, end) =>
    calendar[0]?.start
        ? realCalendarToRealSlice(calendar, start, end)
        : timeCalendarToSlice(calendar, temporalityUnit, start, end);

const computeTemporalSlice = (temporalityUnit, start, end) => {
    const temporalSlice = [];
    const temporalSliceStart = moment.utc(start).startOf(temporalityUnit);
    const temporalSliceEnd = moment.utc(end).endOf(temporalityUnit);
    let currentDate = temporalSliceStart.clone();
    while (currentDate.isBefore(temporalSliceEnd)) {
        const sliceEnd = moment.utc(currentDate).add(1, temporalityUnit);
        temporalSlice.push(new SimpleSlice(currentDate.valueOf(), sliceEnd.valueOf()));
        currentDate = currentDate.add(1, temporalityUnit);
    }
    return temporalSlice;
};

/**
 * Génère la liste des slice d'une activité travaillé selon un calendrier.
 * @param activitySlice  une liste de slice d'activite.
 * @param calendar : le calendrier.
 * @param temporalUnit  l'unite de temps du calendrier.
 * @param {TimeStamp} start la date de début de la liste de slice d'activite.
 * @param {Timestamp} end la date de fin de la liste de slice d'activite.
 * @returns Liste de slice d'activité travaillé en fonction d'un calendrier.
 */
export const computeActivityCalendarSlice = (activitySlice, calendar, temporalUnit, start, end) => {
    const [calendarSlice] = timer('extractNoWorkingCalendarSlice', () =>
        calendarToSlice(calendar, temporalUnit, start, end)
    );
    const [activityCalendarSlice] = timer(`sliceJoined+ ${calendarSlice.length}`, () =>
        sliceJoined(activitySlice, calendarSlice, mergeActivitySliceWithSimpleSlice)
    );
    return activityCalendarSlice;
};

/**
 * Génère les slices d'une activité selon la temporalité
 * @param {Array<ActivitySlice>} activityCalendarSlices
 * @param {String} temporalityUnit
 * @param {Timestamp} start
 * @param {Timestamp} end
 * @returns
 */
export const computeActivityCalendarTemporalSlice = (activityCalendarSlices, temporalityUnit, start, end) =>
    sliceJoined(
        activityCalendarSlices,
        computeTemporalSlice(temporalityUnit, start, end),
        mergeActivityCalendarSliceWithTemporalSlice
    );

/**
 * Génère les NeedSlice d'un type de besoin à partir des slices d'activités déja découpés par une temporalité en ajustant les dates de début et fin selon la temporalité
 * @param  allocatedNeeds Les allocations pour l'activité
 * @param  needType Type des besoin
 * @param  needVisibility Visibilité des besoins
 * @param  activityCalendarTemporalSlices Slices d'activités
 * @param  temporalityUnit Unité de temporatlité
 * @param  computeQuantityFunction Fonction de calcul des valeurs d'allocation (pour les besoins consommables)
 * @returns
 */
export const computeActivityTemporalNeedSlices = (
    allocatedNeeds,
    needType,
    needVisibility,
    activityCalendarTemporalSlices,
    temporalityUnit,
    computeQuantity
) => {
    const currentActivityCalendarTemporalNeedSlices = [];
    activityCalendarTemporalSlices.forEach((slice, index) => {
        let sliceStart = slice.start;
        let sliceEnd = slice.end;
        const realSliceDuration = slice.end - slice.start;
        if (index === 0) {
            sliceStart = moment.utc(slice.start).startOf(temporalityUnit).valueOf();
        }
        if (index === activityCalendarTemporalSlices.length - 1) {
            sliceEnd = moment.utc(slice.start).endOf(temporalityUnit).valueOf();
        }
        allocatedNeeds.forEach((allocatedNeed) => {
            const quantity = computeQuantity(allocatedNeed, realSliceDuration) ?? 0;
            currentActivityCalendarTemporalNeedSlices[allocatedNeed.needId] =
                currentActivityCalendarTemporalNeedSlices[allocatedNeed.needId] ?? [];
            currentActivityCalendarTemporalNeedSlices[allocatedNeed.needId].push(
                new NeedSlice(sliceStart, sliceEnd, quantity, {
                    needType,
                    needVisibility,
                    allocatedNeedId: allocatedNeed.id,
                    needLabel: allocatedNeed.needLabel,
                    needId: allocatedNeed.needId,
                })
            );
        });
    });
    return currentActivityCalendarTemporalNeedSlices;
};

/**
 * Computes allocations quantities for activity
 *
 * @param {Object} allocatedNeed  Allocated Needed
 * @param {Timestamp} sliceDuration  Duration of the slice
 * @param {Timestamp} realActivityDuration  Real Duration of the activity
 * @param {Object} activity  Activity where the need is allocated.
 * @param {number} allocatedNeedTimeUnitDuration Itme unit duration for allocated Need
 * @param {number} activityTimeUnitDuration  Time unit duration for the Activity
 * @returns
 */
export const computeQuantityForConsummable = (
    allocatedNeed,
    sliceDuration,
    realActivityDuration
    // activity,
    // durationUnit
) => {
    let result = 0;
    switch (allocatedNeed.mode) {
        case NEED_QUANTITY_MODE.QUANTITY:
            result = Number(allocatedNeed.quantity) * Number(sliceDuration / realActivityDuration);
            break;
        case NEED_QUANTITY_MODE.TEMPORAL: {
            // const activityTimeUnit = durationUnit(activity.dayDefinitionId);
            // const allocatedNeedTimeUnit = durationUnit(allocatedNeed.timeUnitId);
            // if (activityTimeUnit && allocatedNeedTimeUnit) {
            result = allocatedNeed.quantity * (sliceDuration / realActivityDuration);
            break;
            // }
            // return undefined;
        }
        case NEED_QUANTITY_MODE.FIXE:
            result = Number(allocatedNeed.quantity * Number(sliceDuration / realActivityDuration));
            break;
        // return (sliceDuration * allocatedNeed.factor)/ activity.duration;
        default:
            break;
    }

    return result;
};

export const computeQuantityForNonConsummable = (allocatedNeed, realSliceDuration, chartOptions, durationUnit) => {
    let result = 0;
    switch (chartOptions.sliceDataType) {
        case SLICE_DATA_TYPE.QUANTITY:
            result = allocatedNeed.quantity;
            break;

        case SLICE_DATA_TYPE.EFFORT: {
            const choosenEffortTimeUnit = durationUnit(chartOptions?.effortTimeUnitId);
            if (choosenEffortTimeUnit) {
                result = (allocatedNeed.quantity * realSliceDuration) / choosenEffortTimeUnit.duration;
            }
            break;
        }
        default:
            break;
    }
    return result;
};

/**
 * Divise les slices d'activités selon la temporalité et génère les NeedSlice selon les allocations de besoins de l'activité
 * @param  activityCalendarSlices Les slices de l'activités
 * @param  temporalityUnit Unité de temporalité
 * @param  activity Les informations de l'activité
 * @param  chartOptions Options de l'histogramme pour le calcul des données
 * @returns Liste des NeedSlices
 */
export const generateActivityTemporalNeedSliceFromActivityCalendarSlice = (
    activityCalendarSlices,
    temporalityUnit,
    activity,
    durationUnit,
    chartOptions
) => {
    if (activity.allocatedNeed) {
        const activityCalendarTemporalSlices = computeActivityCalendarTemporalSlice(
            activityCalendarSlices,
            temporalityUnit,
            activity.startDate,
            activity.endDate
        );
        const realActivityDuration = activityCalendarSlices.reduce((prev, current) => {
            const currentSliceDuration = current.end - current.start;
            return prev + currentSliceDuration;
        }, 0);

        // const calendarReal = calendarsDictionary?.[activity.calendarId]?.noWorkingReals;
        const typeofNeed = {
            GLOBAL_NON_CONSOMMABLE: {
                allocatedNeeds: activity.allocatedNeed.nonConsumable.global,
                type: NEED_TYPE.NON_CONSUMMABLE,
                visibility: NEED_VISIBILITY.GLOBAL,
                computeFunc: (allocatedNeed, sliceDuration) => 
                    computeQuantityForNonConsummable(allocatedNeed, sliceDuration, chartOptions, durationUnit)
                
            },
            GLOBAL_CONSOMMABLE: {
                allocatedNeeds: activity.allocatedNeed.consumable.global,
                type: NEED_TYPE.CONSUMMABLE,
                visibility: NEED_VISIBILITY.GLOBAL,
                computeFunc: (allocatedNeed, sliceDuration) =>
                    computeQuantityForConsummable(
                        allocatedNeed,
                        sliceDuration,
                        realActivityDuration,
                        activity,
                        durationUnit
                    ),
            },
            PLANNING_NON_CONSOMMABLE: {
                allocatedNeeds: activity.allocatedNeed.nonConsumable.planning,
                type: NEED_TYPE.NON_CONSUMMABLE,
                visibility: NEED_VISIBILITY.PLANNING,
                computeFunc: (allocatedNeed, sliceDuration) => 
                    computeQuantityForNonConsummable(allocatedNeed, sliceDuration, chartOptions, durationUnit)
            },
            PLANNING_CONSOMMABLE: {
                allocatedNeeds: activity.allocatedNeed.consumable.planning,
                type: NEED_TYPE.CONSUMMABLE,
                visibility: NEED_VISIBILITY.PLANNING,
                computeFunc: (allocatedNeed, sliceDuration) =>
                    computeQuantityForConsummable(
                        allocatedNeed,
                        sliceDuration,
                        realActivityDuration,
                        activity,
                        durationUnit
                    ),
            },
        };
        // const fct1 = (a,b,c,d) => {}
        // const fct2 = (c,d) => fct1(a,b,c,d)

        const currentActivityCalendarTemporalNeedSlices = Object.values(typeofNeed).flatMap((type) => {
            // const consommable = type.type === NEED_TYPE.CONSUMMABLE;
            let result = activityCalendarTemporalSlices;
            result = computeActivityTemporalNeedSlices(
                type.allocatedNeeds,
                type.type,
                type.visibility,
                result,
                temporalityUnit,
                type.computeFunc
            );

            return result.flatMap((needsIds) => needsIds);
        });
        return currentActivityCalendarTemporalNeedSlices;
    }

    return [];
};
