import React, {useState, useContext, useEffect} from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AuthService from '../../services/auth.service';
import WalletService from '../../services/wallet.service';
import { UserContext } from '../../contexts/app/UserContext';

const ActionsRow = styled.div`
    text-align: right;
    margin-bottom: 20px;
`;

const ProfileModification = ({ onCancel, showPasswordForm }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { currentUser, updatingData } = useContext(UserContext);
    const service = WalletService;
    const validationError = (error) => {
        console.log(error.response);
        setLoading(false);
    };

    const updateProfile = async (values) => {
        try {
            await service.updateProfile(values);
            await AuthService.getSession();
            setLoading(false);
            onCancel();
        } catch (error) {
            validationError(error);
        }
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }

        setLoading(true);

        updateProfile(values);
    };

    useEffect(() => {
        form.setFieldsValue({ ...currentUser });
    }, [currentUser]);

    return (
        <>
            <Spin spinning={updatingData} size="large">
                <ActionsRow>
                    <Button type="primary" htmlType="button" onClick={showPasswordForm}>
                        {t('profile_modification_modal.update_password')}
                    </Button>
                </ActionsRow>
                <Form
                    form={form}
                    layout="vertical"
                    name="profile_form"
                    initialValues={currentUser}
                    onFinish={submitForm}
                >
                    <Form.Item
                        label={t('user_infos.last_name')}
                        name="lastName"
                        rules={[{ required: true, message: t('general.required_field') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('user_infos.first_name')}
                        name="firstName"
                        rules={[{ required: true, message: t('general.required_field') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('user_infos.phone_number')}
                        name="phoneNumber"
                        rules={[
                            {
                                pattern: new RegExp(/^(?:[+][0-9]{0,3}[0-9]|[0-9]{2})[0-9]{8}$/),
                                message: t('general.invalid_format'),
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('user_infos.company')}
                        name="company"
                        rules={[{ required: true, message: t('general.required_field')  }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('user_infos.notes')} name="notes">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Row>
                        <Col span={24} className="text-right">
                            <Button htmlType="button" className="mr-2" onClick={onCancel}>
                                {t('general.cancel')}
                            </Button>
                            <Button type="primary" htmlType="submit">
                                {t('general.save')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>
    );
};

ProfileModification.propTypes = {
    onCancel: PropTypes.func.isRequired,
    showPasswordForm: PropTypes.func.isRequired,
};

export default ProfileModification;
