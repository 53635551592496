import * as Sentry from '@sentry/browser';
import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'antd';
import FolderService from '../../services/folder.service';
import ActivityService from '../../services/activity.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { notificationError, requestError } from '../../helpers/notification';

// eslint-disable-next-line no-empty-pattern
const DeletePlanningModal = forwardRef(({}, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'modal_delete_planning' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [isVisible, setVisible] = useState(false);
    const { setFolderSelected, folderSelected, updatePlanning, modeSelected, planningSelected } =
        useContext(PlanningContext);

    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    const onFinish = () => {
        const deletePlanning =
            modeSelected === 'sandbox'
                ? FolderService.deleteSandboxPlanning(planningSelected.id)
                : FolderService.deletePlanning(planningSelected.id);
        deletePlanning
            .then(() => {
                if (modeSelected !== 'sandbox') {
                    if (folderSelected) {
                        if (modeSelected === 'live') {
                            FolderService.listPlannings(folderSelected.id).then((plannings) => {
                                setFolderSelected({ ...folderSelected, plannings });
                                updatePlanning(plannings.first());
                            });
                        }
                        if (modeSelected === 'archive') {
                            FolderService.listArchives(folderSelected.id).then((plannings) => {
                                setFolderSelected({ ...folderSelected, plannings });
                                updatePlanning(plannings.first());
                            });
                        }
                    }
                } else {
                    FolderService.listSandboxPlannings().then((planning) => {
                        updatePlanning(planning.first());
                        setFolderSelected({ ...folderSelected });
                    });
                }

                form.resetFields();
                setVisible(false);
            })
            .catch(async (error) => {
                if (error.response.status === 403) {
                    ActivityService.showActivity(planningSelected.rootActivityId).then((activity) => {
                        notificationError(t('delete_planning'), t('activity_validated', { name: activity.name }));
                    });
                } else {
                    requestError(error, t('delete_planning'));
                }
                Sentry.captureException(error);
            });
    };

    return (
        <Modal
            title={t('delete_planning')}
            className="delete-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <p className="mb-4">
                {t('confirm_delete_planning')} <b>{planningSelected.name}</b> ?
            </p>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item className="text-right mb-0">
                    <Button htmlType="button" className="mr-2" onClick={onCancel}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button type="danger" htmlType="submit">
                        {generalTranslation('confirm')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default DeletePlanningModal;
