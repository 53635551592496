import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Result, Space, Typography } from 'antd';
import ErrorHeader from './ErrorHeader';
import useMailTo from '../hooks/useMailTo.hook';

const ErrorFallback = ({ error }) => {
    
    const sseTranslation = useTranslation('translation', { keyPrefix: 'sse_test' }).t;
    const { t } = useTranslation('translation', { keyPrefix: 'error_fallback' });
    const notFoundTranslation = useTranslation('translation', { keyPrefix: 'not_found' }).t;
    const { sendWhitePageMail } = useMailTo()
    return (
    	<>
        	<ErrorHeader />
        	<div className="w-screen h-full flex justify-center items-center">
            	<Result
                	className="w-screen border-y-2 border"
                	status="error"
                	title={t('something_wrong')}
                	subTitle={t('apologize_msg')}
                	extra={[
						<Button type="primary" key="reload-page" onClick={() => window.location.reload()}>
							{notFoundTranslation('goback_msg')}
						</Button>,
                    	<Button
                        	type="primary"
                        	key="send-email"
                        	onClick={() => sendWhitePageMail()}
                    	>
                        	{sseTranslation('contact_support_team')}
                    	</Button>,
                	]}
            	>
                	<Space direction="vertical">
                    	<Typography.Text type="danger">
                        {t('message')}: <Typography.Text code>{error.message}</Typography.Text>
                    	</Typography.Text>
                        {/* <Typography.Text type="danger">
                            Stack trace: <Typography.Text code>{error.stack}</Typography.Text>
                        </Typography.Text> */}
                	</Space>
            	</Result>
        	</div>
    	</>
	);

}
export default ErrorFallback;
