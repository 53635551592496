import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flushSync } from 'react-dom';
import {Modal, Select, Form, Button, Row, Col, Input, Tooltip, Spin, Popconfirm, Checkbox} from 'antd';
import {
    PlusOutlined,
    DeleteOutlined,
    CopyOutlined,
    EyeOutlined,
    EditOutlined,
    LockOutlined,
    UserOutlined
} from '@ant-design/icons';
import { clone, isEqual } from 'lodash';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { UserContext } from '../../contexts/app/UserContext';
import FolderService from '../../services/folder.service';
import ActivityService from '../../services/activity.service';
import { notificationError, notificationSuccess, requestError } from '../../helpers/notification';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import { generateString } from '../../helpers/string-helper';
import { USER_ACCESS } from '../../helpers/planning';

const SharePlanningModal = forwardRef((props, ref) => {
    const {t} = useTranslation()
    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [multipleTeams, setMultipleTeams] = useState([]);
    const [singleTeams, setSingleTeams] = useState([]);
    const [planningPermissions, setPlanningPermissions] = useState([]);
    const [currentPlanningPermissions, setCurrentPlanningPermissions] = useState([]);
    const { planningSelected, modeSelected } = useContext(PlanningContext);
    const { planningCustomFields } = useContext(PlanningContext);
    const { teamList } = useContext(UserContext);
    const [form] = Form.useForm();
    const planningUrl = `${window.location.protocol}//${window.location.host}/program/${
        JSON.parse(localStorage.getItem('prg')).id
    }/${modeSelected}/planning/${planningSelected.id}`;

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const onOK = () => {
        form.submit();
    };

    const isArchive = modeSelected === 'archive';

    const onSubmit = useCallback(() => {
        const emptyPermissions = currentPlanningPermissions.filter(
            (i) =>
                !i.teamId ||
                !i.access ||
                ((i.access === USER_ACCESS.READWRITE && !isArchive) && i.jobNames.length === 0)
        );
        console.log('🚀 ~ file: SharePlanningModal.jsx:47 ~ onSubmit ~ emptyPermissions', emptyPermissions);
        if (emptyPermissions.length > 0) {
            notificationError(t('modal_share_planning.share_planning'), t('general.fill_fields'));
            return;
        }
        const toAdd = currentPlanningPermissions.filter(
            ({ teamId }) => !planningPermissions.map((planningPermission) => planningPermission.teamId).includes(teamId)
        );
        console.log('🚀 ~ file: SharePlanningModal.jsx:52 ~ onSubmit ~ toAdd', toAdd);
        const toUpdate = currentPlanningPermissions.filter(({ access, jobNames, teamId }) => {
            const oldPermission = planningPermissions.find((i) => i.teamId === teamId);
            return oldPermission && (access !== oldPermission.access || !isEqual(jobNames, oldPermission.jobNames));
        });
        console.log('🚀 ~ file: SharePlanningModal.jsx:63 ~ onSubmit ~ toUpdate', toUpdate);
        const toDelete = planningPermissions
            .map((planningPermission) =>
                !currentPlanningPermissions.map(({ teamId }) => teamId).includes(planningPermission.teamId)
                    ? planningPermission
                    : false
            )
            .filter((item) => item !== false);
        console.log('🚀 ~ file: SharePlanningModal.jsx:69 ~ onSubmit ~ toDelete', toDelete);
        setLoading(true);
        Promise.all([
            ...toAdd.map(({ teamId, access, jobNames, isPropagate }) =>
                FolderService.createPlanningPermission(planningSelected.id, teamId, access, isPropagate).then(() => {
                    if (access === USER_ACCESS.READWRITE) {
                        ActivityService.updateJob(planningSelected.rootActivityId, teamId, jobNames);
                    }
                })
            ),
            ...toUpdate.map(({ teamId, access, jobNames, id, isPropagate }) =>
                FolderService.deletePlanningPermission(planningSelected.id, id).then(() =>
                    FolderService.createPlanningPermission(planningSelected.id, teamId, access, isPropagate).then(() => {
                            ActivityService.updateJob(planningSelected.rootActivityId, teamId, access === USER_ACCESS.READWRITE ? jobNames : []);
                    })
                )
            ),
            ...toDelete.map(({ id, teamId }) =>
                FolderService.deletePlanningPermission(planningSelected.id, id).then(() =>
                    ActivityService.deleteJob(planningSelected.rootActivityId, teamId)
                )
            ),
        ])
            .then(() => {
                setVisible(false);
                setLoading(false);
            })
            .catch((error) => {
                requestError(error, t('general.generic_error'));
                setLoading(false);
            });
    }, [currentPlanningPermissions, planningPermissions]);

    useEffect(() => {
        if (!isVisible) {
            return;
        }
        setLoading(true);
        ActivityService.listPlanningTeamJobs(planningSelected.rootActivityId).then((teamJobs) =>
            FolderService.listPlanningPermissions(planningSelected.id)
                .then((permissions) => {
                    const permissionsJobs = permissions.map((permission) =>
                        Object.assign(permission, {
                            jobNames: teamJobs.find((team) => team.teamId === permission.team.id)?.jobNames || [],
                            teamId: permission.team.id,
                        })
                    );

                    setPlanningPermissions(permissionsJobs);
                    setCurrentPlanningPermissions(clone(permissionsJobs));
                    setMultipleTeams(teamList.filter((team) => !team.single));
                    setSingleTeams(teamList.filter((team) => team.single));
                    setLoading(false);
                })
                .catch((error) => requestError(error, `${t('modal_share_planning.share_planning')}: ${t('general.generic_error')}`))
        );
    }, [isVisible]);

    useEffect(() => {
        setMultipleTeams(teamList.filter((team) => !team.single));
        setSingleTeams(teamList.filter((team) => team.single));
    }, [teamList]);

    const refreshCurrentPermissions = (action, oldObject, newObject) => {
        const object = { ...oldObject, ...newObject };
        const newPermissions = [];
        flushSync(() => {
            setCurrentPlanningPermissions((old) => {
                newPermissions.push(...JSON.parse(JSON.stringify(old)));
                return old;
            });
        });
        switch (action) {
            case 'new': {
                newPermissions.push({
                    id: new Date().getTime(),
                    teamId: null,
                    jobNames: [],
                    access: null,
                    isPropagate: false
                });
                break;
            }
            case 'edit': {
                const indexFilter = newPermissions.findIndex((i) => i.id === object.id);
                if (indexFilter > -1) {
                    newPermissions[indexFilter] = object;
                }
                break;
            }
            case 'delete': {
                const indexFilter = newPermissions.findIndex((i) => i.id === object.id);
                if (indexFilter > -1) {
                    newPermissions.splice(indexFilter, 1);
                }
                break;
            }
            default:
        }

        setCurrentPlanningPermissions([...newPermissions]);
    };

    const handleTeamChange = useCallback(
        (val, permission) => {
            if (currentPlanningPermissions.filter((i) => i.teamId === val).length > 0) {
                notificationError(
                    t('modal_share_planning.share_planning'),
                    t('modal_share_planning.option_exists')
                );
            } else {
                refreshCurrentPermissions('edit', permission, { teamId: val });
            }
        },
        [currentPlanningPermissions]
    );

    const handleJobsChange = (value, permission) => {
        // if (value.length === 0) {
        //     return;
        // }
        let jobsToBeSelected = [];

        if (permission.jobNames.indexOf(ALL_JOBS) === -1 && value.indexOf(ALL_JOBS) > -1) {
            jobsToBeSelected = [ALL_JOBS];
        } else {
            jobsToBeSelected = [...value];
        }
        if (permission.jobNames.indexOf(ALL_JOBS) > -1) {
            jobsToBeSelected = value.filter((i) => i !== ALL_JOBS);
        }
        refreshCurrentPermissions('edit', permission, { jobNames: jobsToBeSelected });
    };

    const handlePropagateChange = (value, permission) => {
        console.log(currentPlanningPermissions, permission);
        refreshCurrentPermissions('edit', permission, { isPropagate: value });
    };

    const getPermissionIcon = (planningAccess, access) => {
        let icon = <></>;
        const color = (access === planningAccess) ||
                     (planningAccess === USER_ACCESS.FULLACCESS && access === USER_ACCESS.READWRITE) ? '#00ff00' : '#ff0000';
        switch (planningAccess) {
            case USER_ACCESS.READ:
                icon = <EyeOutlined className="mr-2" style={{color}}/>;
                break;
            case USER_ACCESS.READWRITE:
                icon = <EditOutlined className="mr-2" style={{color}}/>;
                break;
            case USER_ACCESS.NONE:
                icon = <LockOutlined className="mr-2" style={{color}}/>;
                break;
            case USER_ACCESS.FULLACCESS:
                icon = <UserOutlined className="mr-2" style={{color}}/>;
                break;
            default:
                break;
        }
        return icon;
    };

    return (
        <Modal
            title={`${t('modal_share_planning.share_planning')} "${planningSelected.name}"`}
            class="share-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            onOk={onOK}
            width={800}
        >
            <Spin spinning={loading}>
                <Form form={form} onFinish={onSubmit}>
                    <Input.Group compact className="mb-2">
                        <Input disabled addonBefore="URL" value={planningUrl} style={{ width: 'calc(100% - 32px)' }} />
                        <Tooltip title={t('modal_share_planning.copy_url')}>
                            <Button
                                icon={<CopyOutlined />}
                                onClick={() => {
                                    navigator.clipboard.writeText(planningUrl);
                                    notificationSuccess(
                                        t('modal_share_planning.share_planning'),
                                        t('modal_share_planning.url_copied')
                                    );
                                }}
                            />
                        </Tooltip>
                    </Input.Group>
                    <Row gutter={16}>
                        <Col span={1}>
                            <label> </label>
                        </Col>
                        <Col span={7}>
                            <label>{t('general.users')}</label>
                        </Col>
                        <Col span={5}>
                            <label>{t('general.rights')}</label>
                        </Col>
                        {!isArchive && (
                            <Col span={7}>
                                <label>{t('general.jobs')}</label>
                            </Col>
                        )}
                        <Col span={3}>
                            <label>{t('projects.propagate')}</label>
                        </Col>
                    </Row>
                    {currentPlanningPermissions.map((permission) => (
                        <Row gutter={16} key={generateString(6)}>
                            <Col span={1}>{getPermissionIcon(permission.realAccess, permission.access)}</Col>
                            <Col span={7}>
                                <Form.Item required validateStatus={permission.teamId ?? 'error'}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={permission.teamId}
                                        onChange={(val) => handleTeamChange(val, permission)}
                                    >
                                        <Select.OptGroup label={t('general.teams_plural')}>
                                            {multipleTeams.map((team) => (
                                                <Select.Option key={generateString(5)} value={team.id}>
                                                    {team.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                        <Select.OptGroup label={t('general.users_plural')}>
                                            {singleTeams.map((team) => (
                                                <Select.Option key={generateString(5)} value={team.id}>
                                                    {team.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item required validateStatus={permission.access ?? 'error'}>
                                    <Select
                                        value={permission.access}
                                        onChange={(val) =>
                                            refreshCurrentPermissions('edit', permission, { access: val })
                                        }
                                    >
                                        <Select.Option value="NONE">{t('general.access_none')}</Select.Option>
                                        <Select.Option value="READ">{t('general.access_read')}</Select.Option>
                                        {!isArchive && (
                                            <Select.Option value="WRITE">{t('general.access_write')}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!isArchive && (
                                <Col span={7}>
                                    <Form.Item
                                        required
                                        validateStatus={
                                            permission.jobNames.length === 0 &&
                                            permission.access === USER_ACCESS.READWRITE
                                                ? 'error'
                                                : ''
                                        }
                                    >
                                        <Select
                                            mode="multiple"
                                            value={permission.jobNames}
                                            disabled={permission.access !== USER_ACCESS.READWRITE || isArchive}
                                            onChange={(val) => handleJobsChange(val, permission)}
                                        >
                                            {planningCustomFields
                                                .filter((unifiedfield) => unifiedfield.name === JOB_FIELD)
                                                .map((unifiedfield) => unifiedfield.type.choices)
                                                .flatMap((choices) => Object.keys(choices))
                                                .map((choice) => (
                                                    <Select.Option key={generateString(6)} value={choice}>
                                                        {choice}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={2}>
                                <Popconfirm
                                    title={
                                        isArchive
                                            ? t('projects.propagate_archive_question')
                                            : t('projects.propagate_live_question')
                                    }
                                    disabled={permission.isPropagate === 'undefined'}
                                    okText={permission.isPropagate ? t('general.no') : t('general.yes')}
                                    showCancel={false}
                                    onConfirm={() => handlePropagateChange(!permission.isPropagate, permission)}
                                >
                                    <Button>
                                        <Checkbox
                                            checked={permission.isPropagate}
                                            disabled={typeof permission.isPropagate === 'undefined'}
                                            onChange={() => null}
                                        />
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col span={2}>
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        refreshCurrentPermissions('delete', permission, { id: permission.id })
                                    }
                                    block
                                    icon={<DeleteOutlined />}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Form.Item className="mb-0">
                        <Button
                            type="dashed"
                            onClick={() => refreshCurrentPermissions('new', {}, {})}
                            block
                            icon={<PlusOutlined />}
                        >
                            {t('modal_share_planning.add_permission')}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
});

export default SharePlanningModal;
