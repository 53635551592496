import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { ArcherContainer, ArcherElement } from 'react-archer';
import { Button, Col, Form, InputNumber, Row, Select, Spin, Switch, Modal } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import ActivityService from '../../../services/activity.service';
import { notificationError, notificationSuccess } from '../../../helpers/notification';
import LinksPreview from './LinksPreview';

const { Option } = Select;
const DrawerRow = styled(Row)`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;

    & > * {
        flex-grow: 1;
    }
`;
// const TaskBox = styled.div`
//     width: 50px;
//     height: 20px;
//     font-size: 12px;
//     background: #828282;
//     padding-left: 4px;
//     margin-bottom: 10px;
//     text-align: center;
//     color: white;
//     border: solid #000 1px;
//     p {
//         width: 100%;
//         text-overflow: ellipsis;
//         overflow: hidden;
//         white-space: nowrap;
//     }
// `;

const MultipleLinkCreation = ({ shouldUpdate }) => {
    const {t} = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [canLinkTasks, setCanLinkTasks] = useState(false);
    const { timeUnits, setRefreshGantt } = useContext(PlanningContext);
    const defaultFormValue = {
        linkMode: 'LINK_BETWEEN',
        type: 'FD',
        decalage: 0,
        dayDefinitionId: timeUnits[0]?.id ?? null,
        unused: false,
    };
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [orderedActivities, setOrderedActivities] = useState([]);

    const [linkMode, setLinkMode] = useState('LINK_BETWEEN');
    const [activityOrder, setActivityOrder] = useState([]);
    const [linkType, setLinkType] = useState('FD');
    const [form] = Form.useForm();
    const archerContainerRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        form.setFieldsValue(defaultFormValue);
        setLinkMode('LINK_BETWEEN');
        setLinkType('FD');
        setLoading(false);
    }, []);
    
    useEffect(() => {
        console.log("🚀 ~ file: MultipleLinkCreation.jsx ~ line 70 ~ useEffect ~ activities", window.selectedSimpleTasks);
        console.log("🚀 ~ file: MultipleLinkCreation.jsx:79 ~ useEffect ~ indow.ganttInstance.getSelectedTasks():", window.ganttInstance.getSelectedTasks())

        if (window.selectedSimpleTasks.length > 0) {
                setCanLinkTasks(window.selectedSimpleTasks.length === window.ganttInstance.getSelectedTasks().length);
            setActivityOrder(window.selectedSimpleTasks.map((i) => i.serverId));
            setOrderedActivities(JSON.parse(JSON.stringify(window.selectedSimpleTasks)));
        }
    }, [shouldUpdate]);

    useEffect(() => {
        if (activityOrder.length > 0) {
            setIsPreviewLoading(true);
            setOrderedActivities(activityOrder.map((i) => window.ganttInstance.getTaskByServerId(i)));
            setTimeout(() => {
                setIsPreviewLoading(false);
            }, 200);
        }
    }, [activityOrder, linkMode, linkType]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const submitForm = async ({ type, decalage, dayDefinitionId, unused }) => {
        setSaving(true);
        try {
            const result = await ActivityService.createMultipleLinks(activityOrder, decalage, type, dayDefinitionId, linkMode, !unused);
            if (result.length > 0) {
                // window.ganttInstance.parse({
                //     data: window.ganttInstance.serialize().data,
                //     links: [
                //         ...window.ganttInstance.getLinks(),
                //         ...result.map((response) => ({
                //             id: response.id,
                //             source: response.activityPredecessorId,
                //             target: response.activitySuccessorId,
                //             type: { FD: 0, DD: 1, FF: 2, DF: 3 }[response.type],
                //             color: response.category.color,
                //             dataAPI: response,
                //         })),
                //     ],
                // });
                // window.ganttInstance.showTask(activities[0]?.id);
                setRefreshGantt((val) => val + 1)
                notificationSuccess(t('gantt.link_multiple'), t('gantt.link_added'));
                setIsModalVisible(false);
                window.selectedSimpleTasks = [];
            } else {
                notificationError(t('gantt.link_multiple'), t('gantt.no_link_added'));
            }
        } catch (error) {
            console.log("🚀 ~ file: MultipleLinkCreation.jsx:120 ~ submitForm ~ error", error)
            if (error.response.data.type === 'activity_cycle') {
                notificationError(t('gantt.link_multiple'), t('gantt.cycle_detected'));
            } else {
                notificationError(t('gantt.link_multiple'), t('general.generic_error'));
            }
        }
        setSaving(false);
    };

    return (
        <Spin spinning={loading}>
            {canLinkTasks && (
                <div className="mr-2">
                    <Button type="primary" onClick={() => setIsModalVisible(true)}>
                        {t('gantt.link')}
                    </Button>
                </div>
            )}
            <Modal
                title={t('gantt.link_multiple')}
                // className="calendar-modal"
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width="50vw"
                bodyStyle={{ paddingTop: '0px' }}
                height="auto"
            >
                <Spin spinning={loading}>
                    <div style={{ minHeight: '40vh', maxHeight: '60vh', overflowY: 'auto' }}>
                        <DrawerRow>
                            <Form
                                form={form}
                                layout="vertical"
                                labelAlign="left"
                                name="task_form"
                                className="mt-3 w-full h-full"
                                onFinish={submitForm}
                                colon={false}
                            >
                                <div className="flex w-full divide-x">
                                    <div
                                        className="w-3/4"
                                        style={{ minHeight: '38vh', maxHeight: '58vh', overflow: 'auto' }}
                                        onScroll={() => archerContainerRef?.current?.refreshScreen()}
                                    >
                                        <Spin spinning={isPreviewLoading}>
                                            <Row>
                                                <Col span={24}>
                                                    <label>{t('gantt.links_preview')}:</label>
                                                </Col>
                                                <Col span={24}>
                                                    <DndProvider backend={HTML5Backend}>
                                                        {!isPreviewLoading && (
                                                            <LinksPreview
                                                                linkMode={linkMode}
                                                                linkType={linkType}
                                                                activities={orderedActivities}
                                                                changeOrder={setActivityOrder}
                                                            />
                                                        )}
                                                    </DndProvider>
                                                </Col>
                                            </Row>
                                        </Spin>
                                    </div>
                                    <div className="w-1/4 pl-2">
                                        <Form.Item
                                            label={t('gantt.link_mode')}
                                            name="linkMode"
                                            rules={[{ required: true, message: t('general.required_field') }]}
                                        >
                                            <Select
                                                onChange={(val) => {
                                                    setLinkMode(val);
                                                }}
                                            >
                                                <Option value="LINK_BETWEEN">{t('gantt.link_between')}</Option>
                                                <Option value="LINK_FIRST_WITH_OTHER">
                                                    {t('gantt.link_with_others')}
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={t('general.type')}
                                                    name="type"
                                                    rules={[{ required: true, message: t('general.required_field') }]}
                                                >
                                                    <Select
                                                        onChange={(val) => {
                                                            setLinkType(val);
                                                        }}
                                                        options={[
                                                            { label: 'FD', value: 'FD' },
                                                            { label: 'DD', value: 'DD' },
                                                            { label: 'FF', value: 'FF' },
                                                            { label: 'DF', value: 'DF' },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={10}>
                                                <Form.Item label={t('link_tab.gap')} name="decalage">
                                                    <InputNumber step={1} type="number" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item label={t('link_tab.gap_unit')} name="dayDefinitionId">
                                                    <Select
                                                        options={timeUnits.map((timeUnit) => ({
                                                            label: timeUnit.name,
                                                            value: timeUnit.id,
                                                        }))}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t('link_tab.skip_link')}
                                                    name="unused"
                                                    valuePropName="checked"
                                                >
                                                    <Switch size="small" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="right-0 bottom-0 bg-white text-right flex justify-end">
                                            <Button
                                                disabled={saving}
                                                onClick={() => handleCancel()}
                                                style={{ marginRight: 8 }}
                                            >
                                                {t('general.cancel')}
                                            </Button>
                                            <Button loading={saving} onClick={() => form.submit()} type="primary">
                                                {t('general.save')}
                                            </Button>
                                        </Row>
                                    </div>
                                </div>
                            </Form>
                        </DrawerRow>
                    </div>
                </Spin>
            </Modal>
        </Spin>
    );
};

MultipleLinkCreation.propTypes = {
    shouldUpdate: PropTypes.number.isRequired,
};

export default MultipleLinkCreation;
