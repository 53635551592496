/* eslint no-unused-vars: "off" */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {Tooltip, Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { TabContext } from '../../contexts/app/TabContext';
import { NeedChartContext } from '../../contexts/chart/ChartContext';
import { ReactComponent as RefreshSvg } from '../../assets/icons/refresh.svg';
import { PrintMenuContextProvider } from '../../contexts/app/PrintMenuContext';
import GanttConfigurations from '../plannings/gantt_config';
import {
    NEED_CHART_DATA,
    NEED_CHART_TYPE,
    NEED_HISTOGRAM_TYPE,
    NEED_TYPE,
    SLICE_DATA_TYPE,
} from '../../constants/Needs';
import HistogramTabPrintMenu from './HistogramTabPrintMenu';
import JobsInfo from '../extra/JobsInfo';
import ExportHistogramData from '../menu/ExportHistogramData';


const { Option } = Select;

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
    &:disabled {
        opacity: 0.5;
    }
`;

const HistogramTabTopMenuContainer = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 4px;
    overflow: visible;
    height: 43px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    margin-top: 16px;
    p {
        position: relative;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        font-size: 11px;
        top: -24px;
        left: -5px;
        padding: 2px 4px;
        width: fit-content;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 500ms;
    }
    .button-container {
        top: -19px;
    }
    &:hover {
        border: 1px solid #000;
        p {
            background: rgba(0, 0, 0, 1);
        }
    }
`;
const HistogramTabTopMenu = () => {
    const { refreshPlanningSelected, timeUnits } =
    useContext(PlanningContext);
    const { tabPreferences, updateTabPreferences } = useContext(TabContext);
    const { chartOptions, setChartOptions } = useContext(NeedChartContext);
    // const [disableReloadingButton, setDisableReloadingButton] = useState(disableReloading);
    const { t } = useTranslation('translation', { keyPrefix: 'top_menu' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const scaleConfigs  = GanttConfigurations.scaleConfigs(generalTranslation);
    const scaleTranslation = useTranslation('translation', { keyPrefix: 'scales' }).t;
    const scaleOptions = scaleConfigs.map((i) => {
        const option = { label: scaleTranslation(i.name), value: i.name };
        return option;
    });
    const histogramTranslation = useTranslation('translation', { keyPrefix: 'histogram' }).t;
    const [currentSelectedScaleIndex, setCurrentSelectedScaleIndex] = useState(1);
    // const planningAccess = getPlanningAccess(planningSelected, currentUser);

    useEffect(() => {
        if (tabPreferences.gantt_scales) {
            const index = scaleOptions.findIndex((i) => i.value === tabPreferences.gantt_scales);
            setCurrentSelectedScaleIndex(index);
        }
    }, []);

    // useEffect(() => {
    //     setDisableReloadingButton(disableReloading);
    // }, [disableReloading]);

    const doRefreshGantt = () => {
        refreshPlanningSelected();
    };

    const handleChangeScale = (e) => {
        const scale = scaleConfigs.find((i) => i.name === e);
        if (scale) {
            updateTabPreferences({
                gantt_scales: e,
            });
            const index = scaleOptions.findIndex((i) => i.value === e);
            setCurrentSelectedScaleIndex(index);
        }
    };

    

    const updateHistogramTabChartOptions = (newOption) => {
        setChartOptions((oldOptions) => ({ ...oldOptions, ...newOption }));
    };

    return (
        chartOptions && (
            <div className="w-full flex justify-between items-center bg-secondary-light rounded-md p-1">
                <JobsInfo />
                <div className="flex items-center">
                    <HistogramTabTopMenuContainer className="ml-3">
                        <p>{t('datas')}</p>
                        <div className="button-container relative flex items-center justify-evenly">
                            {/* {!loadingGantt && planningFilter && <FilterModal />} */}
                            <Tooltip title={t('reload_planning')}>
                                <QuickButton
                                    type="button"
                                    className="inline-block bg-primary text-center"
                                    title={t('reload_planning')}
                                    onClick={() => doRefreshGantt()}
                                >
                                    <RefreshSvg style={{ width: '18px', height: '18px' }} className="mx-auto" />
                                </QuickButton>
                            </Tooltip>
                        </div>
                    </HistogramTabTopMenuContainer>
                    <HistogramTabTopMenuContainer className="ml-3">
                        <p>{histogramTranslation('chart_type')}</p>
                        <div className="button-container relative flex items-center justify-evenly">
                            <Radio.Group
                                value={chartOptions?.chartType}
                                onChange={(e) => updateHistogramTabChartOptions({ chartType: e.target.value })}
                                size="small"
                            >
                                <Radio.Button value={NEED_CHART_TYPE.BAR}>
                                    {histogramTranslation('chart_type_bar')}
                                </Radio.Button>
                                <Radio.Button value={NEED_CHART_TYPE.LINE}>
                                    {histogramTranslation('chart_type_line')}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </HistogramTabTopMenuContainer>
                    {chartOptions.chartType === NEED_CHART_TYPE.BAR && (
                        <HistogramTabTopMenuContainer className="ml-3">
                            <p>{histogramTranslation('histogram_type')}</p>
                            <div className="button-container relative flex items-center justify-evenly">
                                <Radio.Group
                                    value={chartOptions?.histogramType}
                                    onChange={(e) => updateHistogramTabChartOptions({ histogramType: e.target.value })}
                                    size="small"
                                >
                                    <Radio.Button value={NEED_HISTOGRAM_TYPE.STACKED}>
                                        {histogramTranslation('histogram_type_stacked')}
                                    </Radio.Button>
                                    <Radio.Button value={NEED_HISTOGRAM_TYPE.UNSTACKED}>
                                        {histogramTranslation('histogram_type_unstacked')}
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        </HistogramTabTopMenuContainer>
                    )}
                    {chartOptions.needType === NEED_TYPE.NON_CONSUMMABLE && (
                        <HistogramTabTopMenuContainer className="ml-3">
                            <p>{histogramTranslation('data_type')}</p>
                            <div className="button-container relative flex items-center justify-evenly">
                                <Radio.Group
                                    value={chartOptions.sliceDataType}
                                    onChange={(e) => updateHistogramTabChartOptions({ sliceDataType: e.target.value })}
                                    size="small"
                                >
                                    <Radio.Button value={SLICE_DATA_TYPE.QUANTITY}>
                                        {histogramTranslation('shown_data_quantity')}
                                    </Radio.Button>
                                    <Radio.Button value={SLICE_DATA_TYPE.EFFORT}>
                                        {histogramTranslation('shown_data_effort')}
                                    </Radio.Button>
                                </Radio.Group>
                                {chartOptions.sliceDataType === SLICE_DATA_TYPE.EFFORT && (
                                    <Tooltip title={histogramTranslation('effort_time_unit')}>
                                        <Select
                                            size="small"
                                            style={{ width: '200px' }}
                                            defaultValue={chartOptions.effortTimeUnitId}
                                            options={timeUnits.map((timeUnit) => ({
                                                label: timeUnit.name,
                                                value: timeUnit.id,
                                            }))}
                                            onChange={(value) => {
                                                updateHistogramTabChartOptions({ effortTimeUnitId: value });
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </HistogramTabTopMenuContainer>
                    )}
                    <HistogramTabTopMenuContainer className="ml-3">
                        <p>{histogramTranslation('data_type')}</p>
                        <div className="button-container relative flex items-center justify-evenly">
                            <Radio.Group
                                value={chartOptions?.dataType}
                                onChange={(e) => updateHistogramTabChartOptions({ dataType: e.target.value })}
                                size="small"
                            >
                                <Radio.Button value={NEED_CHART_DATA.INSTANTANEOUS}>
                                    {histogramTranslation('data_type_instantaneous')}
                                </Radio.Button>
                                {((chartOptions.needType === NEED_TYPE.CONSUMMABLE &&
                                    chartOptions.sliceDataType === SLICE_DATA_TYPE.QUANTITY) ||
                                    (chartOptions.needType === NEED_TYPE.NON_CONSUMMABLE &&
                                        chartOptions.sliceDataType === SLICE_DATA_TYPE.EFFORT)) && (
                                    <Radio.Button value={NEED_CHART_DATA.CUMULATIVE}>
                                        {histogramTranslation('data_type_cumulative')}
                                    </Radio.Button>
                                )}
                            </Radio.Group>
                        </div>
                    </HistogramTabTopMenuContainer>
                </div>
                <div className="flex items-center">
                    <Select
                        value={scaleOptions[currentSelectedScaleIndex].label}
                        style={{ width: 100 }}
                        onChange={handleChangeScale}
                        className="mr-2"
                    >
                        {scaleOptions.map((scale) => (
                            <Option value={scale.value} key={`scale-confg-${scale.value}`}>
                                {scale.label}
                            </Option>
                        ))}
                    </Select>
                    <PrintMenuContextProvider>
                        <HistogramTabPrintMenu />
                    </PrintMenuContextProvider>
                    <ExportHistogramData />
                </div>
            </div>
        )
    );
};

export default HistogramTabTopMenu;
