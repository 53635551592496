import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Select, Spin } from 'antd';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { UserContext } from '../../contexts/app/UserContext';
// import activityService from '../../services/activity.service';
import { generateString } from '../../helpers/string-helper';
import folderService from '../../services/folder.service';
import { USER_ACCESS } from '../../helpers/planning';

/* eslint-disable */
const ShareViewModal = forwardRef(({ onOk, activeView }, ref) => {
    const { t } = useTranslation('translation');

    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [teamOptions, setTeamOptions] = useState([]);
    const { planningSelected } = useContext(PlanningContext);
    const { teamList, currentUser } = useContext(UserContext);

    const [form] = Form.useForm();

    useEffect(() => {
        if (!isVisible) {
            return;
        }
        setLoading(true);
        folderService
            .listPlanningPermissions(planningSelected.id)
            .then((permissions) => {
                let teamsToChoose = [];
                permissions.forEach((permission) => {
                    if ([USER_ACCESS.READ, USER_ACCESS.READWRITE].includes(permission.access)) {
                        teamsToChoose.push({
                            label: permission.team.name,
                            single: permission.team.single,
                            value: permission.team.id,
                        });
                    }
                });
                console.log("🚀 ~ file: ShareViewModal.jsx:45 ~ .then ~ teamList:", teamList)
                if (currentUser.userId !== planningSelected.ownerId) {
                    let ownerSingleTeam = teamList
                        .filter((team) => team.single)
                        .filter((team) => team.userIds.includes(planningSelected.ownerId));
                        
                    ownerSingleTeam = 0 < ownerSingleTeam.length ? ownerSingleTeam[0] : ownerSingleTeam;
                    // Ajout du owner
                    teamsToChoose.push({ label: ownerSingleTeam.name, single: true, value: ownerSingleTeam.id });
                    console.log(
                        '🚀 ~ file: ShareViewModal.jsx:48 ~ teamsToChoose ~ teamsToChoose',
                        teamsToChoose,

                        teamList
                    );
                }
                // filter not include currentUser in list
                setTeamOptions(teamsToChoose);
                setLoading(false);
            })
            .catch((error) =>
                requestError(error, `${t('modal_share_planning.share_planning')}: ${t('general.generic_error')}`)
            );
        // activityService.listPlanningTeamJobs(planningSelected.rootActivityId).then((teamAccess) => {
        //     console.log('🚀 ~ file: ShareViewModal.jsx:34 ~ useEffect ~ teamJobs', teamAccess, teamList);

        //     let currentUserSingleTeam = teamList
        //         .filter((team) => team.single)
        //         .filter((team) => team.userIds.includes(currentUser.userId));
        //     currentUserSingleTeam = 0 < currentUserSingleTeam.length ? currentUserSingleTeam[0] : currentUserSingleTeam;
        //     let teamsToChoose = [];
        //     teamAccess.forEach((access) => {
        //         const team = teamList.find((i) => i.id === access.teamId);
        //         if (team) {
        //             teamsToChoose.push({
        //                 label: team.name,
        //                 single: team.single,
        //                 value: team.id,
        //             });
        //         }
        //     });
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const onFinish = (values) => {
        if (onOk) {
            onOk(values);
            onCancel();
        }
    };

    return (
        <Modal
            title={`${t('planning_tabs.share_view')}: ${activeView?.name}`}
            className="add-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="teamId"
                        label={t('general.users')}
                        rules={[{ required: true, message: t('general.required_field') }]}
                    >
                        <Select showSearch>
                            <Select.OptGroup label={t('general.teams_plural')}>
                                {teamOptions
                                    .filter((team) => !team.single)
                                    .map((team) => (
                                        <Select.Option key={generateString(5)} value={team.value}>
                                            {team.label}
                                        </Select.Option>
                                    ))}
                            </Select.OptGroup>
                            <Select.OptGroup label={t('general.users_plural')}>
                                {teamOptions
                                    .filter((team) => team.single)
                                    .map((team) => (
                                        <Select.Option key={generateString(5)} value={team.value}>
                                            {team.label}
                                        </Select.Option>
                                    ))}
                            </Select.OptGroup>
                        </Select>
                    </Form.Item>

                    <Form.Item className="mb-0 text-right">
                        <Button type="primary" htmlType="submit">
                            {t('general.share')}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
});

export default ShareViewModal;
