import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy, clone, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { flushSync } from 'react-dom';
// import AuthService from '../../services/auth.service';
// import TeamService from '../../services/team.service';
import { notificationSuccess, requestError } from '../../helpers/notification';
import { UserContext } from './UserContext';
import { PlanningContext } from './PlanningContext';
import { TAB_TYPE } from '../../constants/Tabs';
import authService from '../../services/auth.service';
import  { TAB_PREFERENCES_VERSION, tabPreferencesMigratorInstance } from '../../components/utils/tab-preferences/MigratorInstance';
import  { conditionEnum, customFieldsIds, defaultQuery } from '../../components/filters/FilterUtils';
// import userService from '../../services/user.service';

export const TabContext = createContext({
    tabPreferences: null,
    setTabPreferences: () => {},
    updateTabPreferences: () => {},
    planningTabs: [],
    activeTab: null,
});

export const TabContextProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [tabPreferences, setTabPreferences] = useState(null);
    const [planningTabs, setPlanningTabs] = useState([]);
    const [sharedTabs, setSharedTabs] = useState([]);
    const [activeTabFilterData, setActiveTabFilterData] = useState({
        activeFilters: [],
        rawData: [],
    });
    const [activeTab, setActiveTab] = useState(null);
    // const [isLoading, setIsLoading] = useState(false);
    const [refetchTabs, setRefetchTabs] = useState(0);
    const { planningSelected, setPlanningPreferences, setTabFilterData, refreshPlanningSelected } = useContext(PlanningContext);
    const {userPreferences, updateUserPreferences} = useContext(UserContext)

    const updateTabPreferences = useCallback(
        (newPreferences, updateServer = true) =>
            new Promise((resolve) => {
                const updatedPreference = Object.assign(tabPreferences || {}, newPreferences);
                console.log("🚀 ~ file: TabContext.jsx:44 ~ newPromise ~ newPreferences:", newPreferences)
                if (updateServer) {
                    authService
                        .updatePlanningTabWithPreferences(activeTab.id, updatedPreference)
                        .then((tabPreferencesUpdated) => {
                            setTabPreferences(() => ({ ...updatedPreference }));
                            resolve(tabPreferencesUpdated);
                        })
                        .catch((error) => {
                            requestError(error, t('tab_context.error_update_tab_preferences'));
                            resolve(tabPreferences);
                        });
                } else {
                    setTabPreferences(() => ({ ...updatedPreference }));
                }
            }),
        [activeTab, tabPreferences]
    );
    const updateActiveTabFilterData = useCallback(
        (newFilterData, isReplace = false) =>
            new Promise((resolve) => {
                const updatedFilterData = isReplace ? newFilterData : Object.assign(activeTabFilterData || {}, newFilterData);
                // if (updateServer) {

                authService
                    .updatePlanningTabFilterData(activeTab.id, updatedFilterData)
                    .then((tabPreferencesUpdated) => {
                        setActiveTabFilterData(() => updatedFilterData);
                        resolve(tabPreferencesUpdated.filters);
                    })
                    .catch((error) => {
                        requestError(error, t('tab_context.error_update_tab_preferences'));
                        resolve(tabPreferences);
                    });
                // } else {
                setActiveTabFilterData(() => updatedFilterData);
                resolve(updatedFilterData);
                // }
            }),
        [activeTab, activeTabFilterData]
    );

    useEffect(() => {
        const onPlanningSelectedChange = async () => {
            console.log('1. planning Selected change', planningSelected, refetchTabs);
            if (planningSelected) {
                try {
                    let tabs = await authService.getPlanningTabs(planningSelected.id);
                    tabs = sortBy(tabs, (i) => i.order);
                    if (tabs.length === 0) {
                        const newTab = await authService.createPlanningTab(
                            planningSelected.id,
                            TAB_TYPE.GANTT,
                            'Gantt'
                        );
                        notificationSuccess(t('tab_context.tab_added_auto'));
                        tabs = [newTab];
                        // setRefetchTabs((val) => val + 1);
                    }
                    if (!activeTab || planningSelected.id !== activeTab?.planningId) {
                        console.log('changing active tab');
                        const activeTabs = tabs.filter((tab) => tab.display);
                        const preferredActiveTab = activeTabs.find((tab) => tab.id === userPreferences.activeTab?.[planningSelected.id]);
                        if (preferredActiveTab) {
                            setActiveTab(preferredActiveTab);
                        } else {
                            setActiveTab(activeTabs[0] || null);
                        }
                    } else {
                        setActiveTab((oldVal) => ({ ...oldVal }));
                    }
                    console.log('🚀 ~ file: TabContext.jsx:61 ~ onPlanningSelectedChange ~ tabs:', tabs);
                    setPlanningTabs(tabs);
                    const sharedPlanningTabs = await authService.getSharedPlanningTabs(planningSelected.id);
                    setSharedTabs(orderBy(sharedPlanningTabs, ['id'], ['desc']));
                } catch (error) {
                    requestError(error, t('tab_context.get_tabs'));
                }
            }
        };
        onPlanningSelectedChange();
    }, [planningSelected, refetchTabs]);

    const migrateTabPreferences = useCallback(async (currentPreferences) => {
        try {
            const currentVersion = currentPreferences.version || 0;
        if (currentVersion < TAB_PREFERENCES_VERSION) {
            console.log('should migrate preferences');
            const migratedPreferences = tabPreferencesMigratorInstance.migrateObject(currentPreferences, TAB_PREFERENCES_VERSION);
            return (await authService
            .updatePlanningTabWithPreferences(activeTab.id, migratedPreferences)).preferences;
        }
        return currentPreferences;
        } catch (error) {
            console.log('ERROR WHEN MIGRATING');
            return {}
        }
        
    }, [activeTab])

    useEffect(() => {
        const onActiveTabChange = async () => {
            console.log('2. active Tab changed', activeTab);
            if (activeTab) {
                try {
                    await updateUserPreferences({activeTab: {...(userPreferences.activeTab || {}), [planningSelected.id]: activeTab.id}})
                    const tabData = await authService.getPlanningTab(activeTab.id);
                    console.log('🚀XXXXXXXXX', tabData);
                    // MIGRATE IF NEEDED
                    const preferencesToSet = await migrateTabPreferences(tabData?.preferences);
                    console.log("🚀 ~ file: TabContext.jsx:115 ~ onActiveTabChange ~ preferencesToSet:", preferencesToSet)
                    setTabPreferences(() => preferencesToSet|| {});
                    console.log(
                        '🚀 ~ file: TabContext.jsx:123 ~ onActiveTabChange ~ tabData?.filters:',
                        tabData?.filters
                    );
                    const preferenceFilterData = userPreferences?.filters?.[planningSelected.id] || [];
                    // verify raw data
                    const newFilterData = { activeFilters: [], rawData: [], dateFilter: tabData?.filters?.dateFilter || undefined};
                    (tabData?.filters?.activeFilters || []).forEach((id) => {
                        const filter = preferenceFilterData.find((i) => i.id === id);
                        if (filter) {
                            newFilterData.activeFilters.push(filter.id);
                            newFilterData.rawData.push(filter);
                        }
                    });
                    console.log('🚀 ~ file: TabContext.jsx:135 ~ onActiveTabChange ~ newFilterData:', newFilterData);
                    setActiveTabFilterData(newFilterData);
                } catch (error) {
                    requestError(error, t('tab_context.get_tab_preferences'));
                    setTabPreferences({});
                }
            } else {
                setTabPreferences(null);
            }
        };
        onActiveTabChange();
    }, [activeTab]);

    useEffect(() => {
        console.log('3. setting planningPreferences', tabPreferences);
        setPlanningPreferences(() => tabPreferences);
    }, [tabPreferences]);

    useEffect(() => {
        console.log(' setting filtersData', activeTabFilterData);
        setTabFilterData(() => activeTabFilterData);
    }, [activeTabFilterData]);

    const refreshPlanningTabs = (action, object) => {
        const oldPlanningTabs = [];
        flushSync(() => {
            setPlanningTabs((old) => {
                oldPlanningTabs.push(...JSON.parse(JSON.stringify(old)));
                return old;
            });
        });
        switch (action) {
            case 'new': {
                oldPlanningTabs.push(object);
                break;
            }
            case 'edit': {
                const indexTab = oldPlanningTabs.findIndex((i) => i.id === object.id);
                if (indexTab > -1) {
                    oldPlanningTabs[indexTab] = object;
                }
                break;
            }
            case 'delete': {
                const indexTab = oldPlanningTabs.findIndex((i) => i.id === object.id);
                if (indexTab > -1) {
                    oldPlanningTabs.splice(indexTab, 1);
                }
                break;
            }
            default:
        }
        setPlanningTabs(clone(sortBy(oldPlanningTabs, (i) => i.order)));
    };

    const enableDateFilter = async (start, end = null, forceRefresh = true) =>
        // Creation du nouveau filtre de date
        new Promise((resolve) => {
            // creating filter
            const dateFilter = defaultQuery;
            dateFilter.rules = [];
            // adding date conditions
            dateFilter.rules.push({id: crypto.randomUUID(), field: customFieldsIds.startDate, operator: conditionEnum(i18n).LOWER_OR_EQUAL.name, value: end})
            dateFilter.rules.push({id: crypto.randomUUID(), field: customFieldsIds.endDate, operator: conditionEnum(i18n).UPPER_OR_EQUAL.name, value: start});
            console.log("🚀 ~ newPromise ~ dateFilter:", dateFilter)
            updateActiveTabFilterData({dateFilter}).then(() => {
                if (forceRefresh) {
                    window.saveScrollState = false;
                    refreshPlanningSelected();
                }
                resolve(dateFilter);
            });
        });

    const disableDateFilter = useCallback(async (forceRefresh = true) => {
        // Suppression de l'ancien filtre de date si il existe
        if (activeTabFilterData.dateFilter) {
            
                const newValue = activeTabFilterData;
                delete newValue.dateFilter;
                console.log("🚀 ~ file: TabContext.jsx:219 ~ disableDateFilter ~ newValue:", newValue)
                await updateActiveTabFilterData(newValue, true)
                if (forceRefresh) {
                    window.saveScrollState = false;
                    refreshPlanningSelected();
                }
            }
        
    }, [activeTabFilterData]);

    return (
        <TabContext.Provider
            value={{
                tabPreferences,
                setTabPreferences,
                setActiveTab,
                updateTabPreferences,
                planningTabs,
                refreshPlanningTabs,
                activeTab,
                setRefetchTabs,
                sharedTabs,
                activeTabFilterData,
                updateActiveTabFilterData,
                enableDateFilter,
                disableDateFilter
            }}
        >
            {children}
        </TabContext.Provider>
    );
};

TabContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
