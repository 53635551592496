import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Empty } from 'antd';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { CustomFieldsMenuContext } from '../../contexts/custom-fields/CustomFieldsMenuContext';
import CustomFieldEditionForm from './CustomFieldEditionForm';
import CustomFieldDeleteForm from './CustomFieldDeleteForm';
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { ReactComponent as PlusSvg } from '../../assets/icons/plus.svg';
import { JOB_FIELD } from '../../constants/Jobs';


const ListFooters = styled.li`
    margin-top: 4px;
`;

const PlusIcon = styled(PlusSvg)`
    display: inline;
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    width: 110px;
`;

const ActionsColumn = styled.span`
    width: 44px;

    div {
        width: 22px;
    }
`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const CustomFieldsList = () => {
    const {t} = useTranslation('translation', {keyPrefix: 'modal_custom_fields'})
    const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t;
    const { selectedCustomField, setSelectedCustomField, modalContent, setModalContent } =
        useContext(CustomFieldsMenuContext);
    const { planningCustomFields, modeSelected, canAccess } = useContext(PlanningContext);
    const planningAccess = canAccess();

    const addCustomField = () => {
        setModalContent({
            key: 'periods-create-period',
            title: `${t('custom_fields')} - ${generalTranslation('creating')}`,
            body: <CustomFieldEditionForm customField={null} />,
        });
    };

    const editCustomField = (customField) => {
        setModalContent({
            key: 'periods-edit-period',
            title: `${t('custom_fields')} - ${generalTranslation('editing')}`,
            body: <CustomFieldEditionForm customField={customField} />,
        });
    };

    const deleteCustomField = (customField) => {
        setModalContent({
            key: 'periods-delete-period',
            title: `${t('custom_fields')} - ${generalTranslation('deleting')}`,
            body: <CustomFieldDeleteForm customField={customField} />,
        });
    };

    useEffect(() => {
        if (selectedCustomField) {
            const newSelected = planningCustomFields.find(i => i.id === selectedCustomField.id)
            setSelectedCustomField(newSelected);
        } else {
            setSelectedCustomField(planningCustomFields.first());
        }
    }, [planningCustomFields, modalContent]);

    return (
        <ul className="pb-8">
            <li className="p-0 pl-2 mb-1">
                <NameColumnHeader>{t('your_custom_fields')}</NameColumnHeader>
            </li>
            {planningCustomFields.length ? (
                planningCustomFields.map((customField) => (
                    <li key={`custom-field-${customField.id}`} className="p-0 mb-1">
                        <div className="flex items-stretch">
                            <NameColumn
                                className={`flex-1 flex justify-between items-center rounded-l-sm p-2 text-xs cursor-pointer
                                ${
                                    selectedCustomField && selectedCustomField.id === customField.id
                                        ? 'bg-primary'
                                        : 'bg-white'
                                }`}
                                onClick={() => setSelectedCustomField(customField)}
                            >
                                {customField.name !== JOB_FIELD ? customField.name : generalTranslation('job_field')}
                            </NameColumn>
                            {modeSelected !== 'archive' && planningAccess && customField.name !== JOB_FIELD && (
                                <ActionsColumn className="flex items-center">
                                    <div
                                        className={`py-2 h-full rounded-r-sm cursor-pointer custom-field-edit
                                    ${
                                        selectedCustomField && selectedCustomField.id === customField.id
                                            ? 'bg-primary'
                                            : 'bg-white'
                                    }
                                    `}
                                        onClick={() => editCustomField(customField)}
                                    >
                                        <EditSvg width="16" height="16" className="relative text-secondary-dark" />
                                    </div>
                                    <div
                                        className="py-2 text-center cursor-pointer"
                                        onClick={() => deleteCustomField(customField)}
                                    >
                                        <CloseSvg width="16" height="16" className="mx-auto" />
                                    </div>
                                </ActionsColumn>
                            )}
                        </div>
                    </li>
                ))
            ) : (
                <Empty description={t('empty_custom_fields')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {(modeSelected !== 'archive' && planningAccess) && (
                <ListFooters className="absolute bottom-0 left-0 right-0 w-full px-3">
                    <div className="bg-transparent px-2 pb-2">
                        <Button
                            type="secondary"
                            className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                            onClick={addCustomField}
                        >
                            <PlusIcon className="absolute left-0 ml-3" /> {generalTranslation('add')}
                        </Button>
                    </div>
                </ListFooters>
            )}
        </ul>
    );
};

export default CustomFieldsList;
