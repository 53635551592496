import React from 'react';
import { Row, Col, Typography} from 'antd';

const { Title } = Typography;


const ErrorHeader = () => (
    <header>
        <Row className="bg-primary justify-between h-12 px-12">
            <Col span={14} className="flex items-center">
                <Title
                    className="cursor-pointer"
                    onClick={() => window.location.reload()}
                    level={3}
                    style={{ marginBottom: '0px', color: 'white', fontWeight: 'normal' }}
                >
                    Gantt Manager
                </Title>
            </Col>
        </Row>
    </header>
);
export default ErrorHeader;
