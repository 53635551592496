import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Checkbox, Row, Col, Button, Form, Select, Table } from 'antd';
import update from 'immutability-helper';
// import styled from 'styled-components';
import { UserContext } from '../../../contexts/app/UserContext';
import { TabContext } from '../../../contexts/app/TabContext';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import GanttConfigurations from '../gantt_config';
import { JOB_FIELD } from '../../../constants/Jobs';
import DraggableBodyRow from '../../common/tables/DraggableBodyRow';

const { Column } = Table;

const { DEFAULT_COMUMNS } = GanttConfigurations;


const ColumnParams = ({ isPlanning, onOk, onCancel }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'column_params' });
    const columnTranslation = useTranslation('translation', { keyPrefix: 'columns' }).t;
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const baseColumnsOptions = [
        { value: 'id', label: columnTranslation('id'), alignment: 'left', show: true },
        { value: 'description', label: columnTranslation('description'), alignment: 'center', show: true },
        { value: 'name', label: columnTranslation('name'), alignment: 'left', show: true },
        { value: 'startDate', label: columnTranslation('start_date'), alignment: 'center', show: true },
        { value: 'endDate', label: columnTranslation('end_date'), alignment: 'center', show: true },
        { value: 'duration', label: columnTranslation('duration'), alignment: 'center', show: true },
        { value: 'jobs', label: columnTranslation('jobs'), alignment: 'center', show: true },
        { value: 'progress', label: columnTranslation('progress'), alignment: 'center', show: true },
        { value: 'duration_unit', label: columnTranslation('duration_unit'), alignment: 'center', show: true },
        { value: 'calendar', label: columnTranslation('calendar'), alignment: 'center', show: true },
        { value: 'quantity', label: columnTranslation('quantity'), alignment: 'center', show: true },
        { value: 'yield', label: columnTranslation('yield'), alignment: 'center', show: true },
        { value: 'calcul', label: columnTranslation('calcul'), alignment: 'center', show: true },
        { value: 'predecessors', label: columnTranslation('predecessors'), alignment: 'left', show: true },
        { value: 'successors', label: columnTranslation('successors'), alignment: 'left', show: true },
        // { value: 'skills', label: columnTranslation('skills'), alignment: 'left', show: true },
        { value: 'valid', label: columnTranslation('state'), alignment: 'center', show: true },
        // { value: 'split_task', label: 'Affichage en ligne', alignment: 'center', show: true },
    ];
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { planningCustomFields, planningSelected } = useContext(PlanningContext);
    const [columnOptions, setColumnOptions] = useState([]);
    const [currentColumnOptions, setCurrentColumnOptions] = useState([]);
    const { userPreferences, updateUserPreferences } = useContext(UserContext);
    const { tabPreferences, updateTabPreferences } = useContext(TabContext);
    // drag
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = columnOptions[dragIndex];
            setColumnOptions(
                update(columnOptions, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                })
            );
        },
        [columnOptions]
    );
    const updateColumnDisplay = () => {
        // getting show parameters
        const columnsToShow = columnOptions.filter((i) => i.show).map((el) => el.value);
        // getting columns alignment
        const columnsAlignment = columnOptions.reduce((agg, el) => {
            const oldObject = { ...agg };
            oldObject[el.value] = el.alignment;
            return oldObject;
        }, {});
        // getting order
        const columnsOrder = columnOptions.reduce((agg, el, index) => {
            const oldObject = { ...agg };
            // avoid falsy values, avoiding 0 value of order
            oldObject[el.value] = index + 1;
            return oldObject;
        }, {});
        setLoading(true);
        if (isPlanning) {
            // delete sort if column not shown
            let planningSort = tabPreferences?.planning_sort || {};
            if (!columnsToShow.find((i) => i === (planningSort?.field !== 'identity' ? planningSort?.field : 'id'))) {
                console.log('deleting sort');
                planningSort = {};
            }
            updateTabPreferences({
                gantt_columns: columnsToShow,
                gantt_columns_alignment: columnsAlignment,
                gantt_columns_order: columnsOrder,
                planning_sort: planningSort,
            })
                .then(() => {
                    setCurrentColumnOptions(JSON.parse(JSON.stringify(columnOptions)));
                    setLoading(false);
                    onOk();
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            updateUserPreferences({
                default_gantt_columns: columnsToShow,
                default_gantt_columns_alignment: {
                    ...columnsAlignment,
                },
                default_gantt_columns_order: { ...columnsOrder },
            })
                .then(() => {
                    setCurrentColumnOptions(JSON.parse(JSON.stringify(columnOptions)));
                    setLoading(false);
                    if (onOk) onOk();
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    const submitForm = () => {
        if (loading) {
            return;
        }
        updateColumnDisplay();
    };

    useEffect(() => {
        if (isPlanning) {
            const columnOptionsDefault = baseColumnsOptions.concat(
                planningCustomFields.reduce((agg, customField) => {
                    if (customField.name !== JOB_FIELD) {
                        agg.push({
                            value: `customField#${customField.id}`,
                            label: customField.name,
                            alignment: 'center',
                            show: true,
                        });
                    }
                    return agg;
                }, [])
            );
            const shownColumns =
                tabPreferences?.gantt_columns || userPreferences?.default_gantt_columns || DEFAULT_COMUMNS;
            const columnsSettings =
                tabPreferences?.gantt_columns_alignment || userPreferences?.default_gantt_columns_alignment || {};
            const columnsOrder =
                tabPreferences?.gantt_columns_order || userPreferences?.default_gantt_columns_order || {};
            const newVal = columnOptionsDefault.map((val) => {
                const newData = { ...val };
                if (shownColumns.length > 0) {
                    newData.show = shownColumns.indexOf(val.value) !== -1;
                }
                if (columnsSettings[val.value]) {
                    newData.alignment = columnsSettings[val.value];
                }
                newData.order = columnsOrder[val.value] ?? 99;
                return newData;
            });
            const tableValue = newVal.sort((a, b) => a.order - b.order);
            setCurrentColumnOptions(JSON.parse(JSON.stringify(tableValue)));
            setColumnOptions([...tableValue]);
        } else {
            // global config
            const shownColumns = userPreferences?.default_gantt_columns || DEFAULT_COMUMNS;
            const columnsSettings = userPreferences?.default_gantt_columns_alignment || {};
            const columnsOrder = userPreferences?.default_gantt_columns_order || {};
            const newVal = baseColumnsOptions.map((val) => {
                const newData = { ...val };
                if (shownColumns.length > 0) {
                    newData.show = shownColumns.indexOf(val.value) !== -1;
                }
                if (columnsSettings[val.value]) {
                    newData.alignment = columnsSettings[val.value];
                }
                // make last if no order
                newData.order = columnsOrder[val.value] ?? 99;
                return newData;
            });
            // sorting array by order
            const tableValue = newVal.sort((a, b) => a.order - b.order);
            setCurrentColumnOptions(JSON.parse(JSON.stringify(tableValue)));
            setColumnOptions([...tableValue]);
        }
    }, [planningSelected, userPreferences, planningCustomFields, tabPreferences]);

    const handleCancel = () => {
        // reset to default
        setColumnOptions(JSON.parse(JSON.stringify(currentColumnOptions)));
        if (onCancel) {
            onCancel();
        }
    };

    const handleApplyDefault = () => {
        const columnOptionsDefault = baseColumnsOptions.concat(
            planningCustomFields.reduce((agg, customField) => {
                if (customField.name !== JOB_FIELD) {
                    return agg.concat({
                        value: `customField#${customField.id}`,
                        label: customField.name,
                        alignment: 'center',
                        show: true,
                    });
                }
                return agg;
            }, [])
        );
        const shownColumns = userPreferences?.default_gantt_columns || DEFAULT_COMUMNS;
        const columnsSettings = userPreferences?.default_gantt_columns_alignment || {};
        const columnsOrder = userPreferences?.default_gantt_columns_order || {};
        const newVal = columnOptionsDefault.map((val) => {
            const newData = { ...val };
            if (shownColumns.length > 0) {
                newData.show = shownColumns.indexOf(val.value) !== -1;
            }
            if (columnsSettings[val.value]) {
                newData.alignment = columnsSettings[val.value];
            }
            if (columnsOrder[val.value]) {
                // make last if no order

                newData.order = columnsOrder[val.value] ?? 99;
            }
            return newData;
        });
        const tableValue = newVal.sort((a, b) => a.order - b.order);
        setColumnOptions([...tableValue]);
    };

    return (
        <>
            {isPlanning && (
                <Button className="mb-2" onClick={handleApplyDefault}>
                    {t('apply_defaults')}
                </Button>
            )}
            <Form
                form={form}
                layout="vertical"
                name="planning_column_display"
                onFinish={submitForm}
                style={{ minWidth: '400px', height: '500px' }}
            >
                <div style={{ height: '450px', overflowY: 'scroll' }}>
                    <Table
                        sticky
                        pagination={false}
                        dataSource={columnOptions}
                        components={components}
                        onRow={(record, index) => ({
                            index,
                            moveRow,
                        })}
                        rowKey="value"
                    >
                        <Column title={generalTranslation('name')} dataIndex="label" key="name" />
                        <Column
                            title={generalTranslation('show')}
                            dataIndex="show"
                            key="name"
                            render={(value, record, index) => (
                                <Checkbox
                                    checked={record.show}
                                    className="font-thin"
                                    style={{ fontFamily: 'inherit !important' }}
                                    onChange={({ target: { checked } }) => {
                                        setColumnOptions((old) => {
                                            const newVal = [...old];
                                            newVal[index].show = checked;
                                            return newVal;
                                        });
                                    }}
                                />
                            )}
                        />
                        <Column
                            title={t('alignment')}
                            dataIndex="alignment"
                            key="name"
                            render={(val, record, index) => (
                                <Select
                                    value={record.alignment}
                                    className="w-32"
                                    onChange={(value) => {
                                        setColumnOptions((old) => {
                                            const newVal = [...old];
                                            newVal[index].alignment = value;
                                            return newVal;
                                        });
                                    }}
                                >
                                    <Select.Option value="left">{t('column_alignment_left')}</Select.Option>
                                    <Select.Option value="center">{t('column_alignment_center')}</Select.Option>
                                    <Select.Option value="right">{t('column_alignment_right')}</Select.Option>
                                </Select>
                            )}
                        />
                    </Table>
                </div>
                <Row className="mt-3">
                    <Col span={24} className="text-right">
                        <Button htmlType="button" className="mr-2" onClick={handleCancel}>
                            {generalTranslation('cancel')}
                        </Button>
                        <Button loading={loading} type="primary" htmlType="submit">
                            {generalTranslation('save')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

ColumnParams.defaultProps = {
    isPlanning: true,
};

ColumnParams.propTypes = {
    isPlanning: PropTypes.bool,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ColumnParams;
