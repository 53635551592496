import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import '../../assets/styles/wallet/Header.css';
import { Button, Menu, Row, Col, Typography, Dropdown, Avatar } from 'antd';
import { HistoryOutlined, StarOutlined } from '@ant-design/icons';
import LangageChooser from '../langage-selector/LangageChooser';
import LogoutMenu from '../menu/LogoutMenu';
import ProfileMenu from '../menu/ProfileMenu';
import UserManagementMenu from '../menu/UserManagementMenu';
import AuthService from '../../services/auth.service';
import { WalletContext } from '../../contexts/wallet/WalletContext';
import { UserContext } from '../../contexts/app/UserContext';

const { Title } = Typography;

const ButtonContainer = styled.div`
    .anticon {
        vertical-align: 2px !important;
    }
    .ant-btn-text:hover,
    .ant-btn-text.active {
        color: rgba(251, 190, 63, 1);
        background: rgba(0, 0, 0, 0.9);
        border-color: transparent;
    }
`;

const user = AuthService.getCurrentUser();

const WalletHeader = () => {
    const { currentUser } = React.useContext(UserContext);
    const { setTableType, tableType, loadingData } = React.useContext(WalletContext);
    const [tabletype, setTabletype] = useState(null);
    const {t} = useTranslation('translation', {keyPrefix: 'wallet.header'})

    useEffect(() => setTabletype(tableType), [tableType]);

    const menu = (
        <Menu className="avatar-menu">
            <ProfileMenu key="profile" />
            {currentUser?.isPlAdmin && <UserManagementMenu isProgram={false} key="user-management" />}
            <LogoutMenu key="logout" />
        </Menu>
    );
    return (
        <header>
            <Row className="bg-primary justify-between h-12 px-12">
                <Col span={7} className="flex items-center justify-between">
                    <Col span={14} className="flex items-center cursor-pointer" onClick={() => setTableType('Program')}>
                        <Title level={3} style={{ marginBottom: '0px', color: 'white', fontWeight: 'normal' }}>
                            {t('gatt_manager_title')}
                        </Title>
                    </Col>
                    <ButtonContainer>
                        <Col span={10} className="flex">
                            <Button
                                type="text"
                                icon={<HistoryOutlined />}
                                onClick={() =>
                                    tabletype === 'Recent' ? setTableType('Program') : setTableType('Recent')
                                }
                                className={`${tabletype === 'Recent' ? 'active' : ''}`}
                                disabled={loadingData}
                            >
                                {t('recent_tab_title')}
                            </Button>
                            <Button
                                type="text"
                                icon={<StarOutlined />}
                                onClick={() =>
                                    tabletype === 'Favorite' ? setTableType('Program') : setTableType('Favorite')
                                }
                                className={`${tabletype === 'Favorite' ? 'active' : ''} ml-10`}
                                disabled={loadingData}
                            >
                                {t('favorite_tab_title')}
                            </Button>
                        </Col>
                    </ButtonContainer>
                </Col>
                <Col span={2} className="flex justify-end items-center ">
                    <Row className="flex justify-end mr-2">
                        <LangageChooser />
                    </Row>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Avatar className="cursor-pointer">{`${user?.firstName?.charAt(0).toUpperCase() ?? ''}${
                            user?.lastName?.charAt(0).toUpperCase() ?? ''
                        }`}</Avatar>
                    </Dropdown>
                </Col>
            </Row>
        </header>
    );
};

export default WalletHeader;
