import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Modal, Row, Tooltip } from 'antd';

import styled from 'styled-components';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { CustomFieldsMenuContext } from '../../contexts/custom-fields/CustomFieldsMenuContext';
import CustomFieldsList from '../custom-fields/CustomFieldsList';
import CustomFieldsChoicesList from '../custom-fields/CustomFieldsChoicesList';
import { ReactComponent as PencilSvg } from '../../assets/icons/pencil.svg';

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const PanelWrapper = styled.div`
    background: #f8f8f8;
    border-radius: 4px;
    padding: 8px;
    height: auto;
`;

const PanelTitle = styled.h2`
    font-family: 'Uni Sans Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #fbbe3f;
`;

const CustomFieldsModal = () => {
    const {t} = useTranslation('translation', {keyPrefix: 'modal_custom_fields'})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { planningSelected, refreshPlanningSelected } = useContext(PlanningContext);
    const { selectedCustomField, modalContent, shouldUpdatePlanning } = useContext(CustomFieldsMenuContext);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        if (shouldUpdatePlanning) {
            refreshPlanningSelected(true);
        }
    };

    // useEffect(() => {
    //     if (!modalContent) {
    //         setShouldUpdatePlanning(false);
    //     }
    // }, [modalContent]);

    // useEffect(() => setShouldRefresh(shouldUp), [shouldUpdatePlanning]);

    return (
        <>
            <Tooltip title={t('custom_fields')}>
                <QuickButton
                    type="button"
                    className={`inline-block text-center ${isModalVisible ? 'bg-black text-white' : 'bg-primary'}`}
                    title={t('custom_fields')}
                    onClick={() => showModal()}
                >
                    <PencilSvg className="mx-auto" />
                </QuickButton>
            </Tooltip>
            <Modal
                title={
                    modalContent
                        ? modalContent.title
                        : `${t('planning_custom_fields')} ${planningSelected ? planningSelected.name : ''}`
                }
                className="custom-fields-modal"
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={926}
                height={modalContent ? 'auto' : 700}
            >
                {modalContent ? (
                    modalContent.body
                ) : (
                    <Row gutter={[12, 0]} className="h-full justify-between">
                        <Col span={8} className="h-full">
                            <PanelWrapper className="max-h-full overflow-y-auto">
                                <CustomFieldsList />
                            </PanelWrapper>
                        </Col>
                        <Col span={16} className="h-full">
                            <div className="flex flex-col max-h-full overflow-y-auto">
                                <PanelTitle>
                                    {t('custom_field_edition')} :
                                    <span className="pl-2">{selectedCustomField ? selectedCustomField.name : '-'}</span>
                                </PanelTitle>
                                {selectedCustomField && (
                                    <p className="font-bold text-xs text-gray-300">
                                        {t('custom_field_type')}: {selectedCustomField.type.text && t('type_text')}
                                        {selectedCustomField.type.numeric && t('type_numeric')}
                                        {selectedCustomField.type.choice && t('type_choice')}
                                    </p>
                                )}
                                {selectedCustomField && selectedCustomField.type.choice && (
                                    <PanelWrapper>
                                        <CustomFieldsChoicesList />
                                    </PanelWrapper>
                                )}
                            </div>
                        </Col>
                    </Row>
                )}
            </Modal>
        </>
    );
};

export default CustomFieldsModal;
