import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import {Button, Form, Input, Modal, Select, Switch} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import FolderService from '../../../services/folder.service';
import { requestError } from '../../../helpers/notification';


const AddProjectModal = forwardRef(({ parent, items, onFolderAdded }, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'projects' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [isVisible, setVisible] = useState(false);
    const [inheritDir, setInheritDir] = React.useState(false);
    const [parentValue, setParentValue] = React.useState(false);
    const { setFolderSelected, folderSelected } = useContext(PlanningContext);
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        setParentValue(parent);
    }, [parent]);

    const onFinish = (values) => {
        console.log("🚀 ~ file: AddProjectModal.jsx:34 ~ onFinish ~ values:", values)
        FolderService.createFolder(values.name, parentValue || 0 , inheritDir)
            .then((folder) => {
                if (folderSelected) {
                    const childFolders = folderSelected.childFolders || [];
                    setFolderSelected({ ...folderSelected, childFolders: [...childFolders, folder] });
                }

                form.resetFields();
                setVisible(false);

                if (onFolderAdded) {
                    onFolderAdded(folder);
                }
            })
            .catch((error) => {
                requestError(error, t('folder_create_error'));
            });
    };

    const onChangeInheritDir = ()=>{
        setInheritDir(!inheritDir)
    }
    const onChangeParentDir = (value)=>{
        console.log(value)
          setParentValue(value)
    }

    return (
        <Modal
            title={t('add_folder')}
            className="add-project-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="name"
                    label={t('project_name')}
                    rules={[{ required: true, message: t('project_name_required') }]}
                >
                    <Input type="text" placeholder={t('project_name')} />
                </Form.Item>
                <Form.Item label={t('parent_blank_none')}>
                    <Select
                        options={items.map((item) => ({
                            value: item.id,
                            label: item.name,
                        }))}
                        value={parentValue}
                        onChange={onChangeParentDir}
                        showSearch
                        allowClear
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                    <label>
                        <Switch
                            name="inheritDir"
                            disabled={!parentValue}
                            checked={parentValue && inheritDir}
                            onChange={onChangeInheritDir}
                        />
                        <span> {t('copy_permission_parent_folder')}</span>
                    </label>
                </Form.Item>
                <Form.Item className="mb-0">
                    <Button type="primary" htmlType="submit">
                        {generalTranslation('add')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

AddProjectModal.defaultProps = {
    parent: false,
    items: [],
    onFolderAdded: null,
};

AddProjectModal.propTypes = {
    parent: PropTypes.number,
    items: PropTypes.array,
    onFolderAdded: PropTypes.func,
};

export default AddProjectModal;
