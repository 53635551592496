import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import AuthService from './services/auth.service';
import LoginLayoutRoute from './layouts/LoginLayoutRoute';
import Login from './pages/login/Login';
import ForgottenPassword from './pages/login/ForgottenPassword';
import PasswordReset from './pages/login/PasswordReset';
import Wallet from './pages/Wallet';
import { UserContextProvider } from './contexts/app/UserContext';
import { PlanningContextProvider } from './contexts/app/PlanningContext';
import { WalletContextProvider } from './contexts/wallet/WalletContext';
import Planning from './pages/Planning';
import AccessPlanning from './pages/AccessPlanning';
import NotFound from './pages/NotFound';
// import { ResourceContextProvider } from './contexts/resource/ResourceContext';
import { TabContextProvider } from './contexts/app/TabContext';
import { NeedsContextProvider } from './contexts/needs/NeedsContext';
import { PlanningNeedsContextProvider } from './contexts/needs/PlanningNeedsContext';
// import { SliceContextProvider } from './contexts/slicing/SliceContext';
import {VersionContextProvider} from "./contexts/wallet/VersionContext";

const Router = () => {
    const homeComponent = AuthService.getAccessToken() ? (
        <Switch>
            <Route exact path="/">
                <Redirect to="/login" push />
            </Route>
            <Route exact path="/home" />
            <Route exact path="/features" />
            <Route exact path="/pricing" />
            <LoginLayoutRoute exact path="/login" Component={Login} />
            <Route exact path="/program/:idPrg/:mode/planning/:idPlanning" component={AccessPlanning} />
            <UserContextProvider>
                {/* <ResourceContextProvider> */}
                <NeedsContextProvider>
                    <PlanningContextProvider>
                        <PlanningNeedsContextProvider>
                            <TabContextProvider>
                                {/* <SliceContextProvider> */}
                                    <Route exact path="/planning">
                                        <Planning />
                                    </Route>
                                    <Route exact path="/planning/:viewId" component={Planning} />
                                {/* </SliceContextProvider> */}
                            </TabContextProvider>
                        </PlanningNeedsContextProvider>
                    </PlanningContextProvider>
                </NeedsContextProvider>
                {/* </ResourceContextProvider> */}
                <WalletContextProvider>
                    <VersionContextProvider>
                        <Route exact path="/programs">
                            <Wallet />
                        </Route>
                        <Route path="/not-found" component={NotFound} />
                        {/* <Route path="*" render={() => <Redirect to="/not-found" />} /> */}
                    </VersionContextProvider>
                </WalletContextProvider>
            </UserContextProvider>
        </Switch>
    ) : (
        <Switch>
            <Route exact path="/">
                <Redirect to="/home/index.html" push />
            </Route>
            <Route exact path="/home/index.html" />
            <Route exact path="/features/index.html" />
            <Route exact path="/pricing/index.html" />
            <LoginLayoutRoute exact path="/login" Component={Login} />
            <LoginLayoutRoute exact path="/mot-de-passe-oublie" Component={ForgottenPassword} />
            <LoginLayoutRoute exact path="/reinitialisation-mot-de-passe" Component={PasswordReset} />
            <Route exact path="/program/:idPrg/planning/:idPlanning" component={AccessPlanning} />
            <Route path="/not-found" component={NotFound} />
            <Redirect to="/not-found" />
        </Switch>
    );

    return <BrowserRouter forceRefresh={!AuthService.getAccessToken()}>{homeComponent}</BrowserRouter>;
};

export default Router;
