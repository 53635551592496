import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ActivitySlice } from 'slicing-gm';
import { TabContext } from '../app/TabContext';
import { PlanningContext } from '../app/PlanningContext';
import GanttConfigurations from '../../components/plannings/gantt_config';
import { formatResourceTask } from '../../helpers/planning';
import { computeActivityCalendarSlice, timer } from '../../helpers/slicing-helper';
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';
import { xAxisDateFormat } from '../../components/charts/CustomHistogramDateXaxis';
import { TAB_TYPE } from '../../constants/Tabs';
import { restoreScrollState } from '../../components/plannings/gantt_events';
import { NEED_TYPE } from '../../constants/Needs';

// import { requestError } from '../../helpers/notification';

const shouldUpdateSlices = (fields) =>
    fields.filter((item) => {
        const existence = ['allocatedNeed', 'startDate', 'endDate', 'calendarId'].indexOf(item) !== -1;
        console.log('🚀 ~ file: SliceContext.jsx:23 ~ fields.filter ~ existence:', existence);
        return existence;
    }).length > 0;

const CalendarNoWorkingAttribute = {
    hours: 'noWorkingHours',
    days: 'noWorkingDays',
    week: 'noWorkingWeek',
    month: 'noWorkingMonth',
};

const SliceContext = createContext();

const SliceContextProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t;
    const scaleConfigs  = GanttConfigurations.scaleConfigs(generalTranslation);

    const [scale, setScale] = useState(null);
    const [activityCalendarSliceDictionary, setActivityCalendarSliceDictionary] = useState(null);
    const {
        processingGantt,
        planningSelected,
        activitiesDictionary,
        planningPreferences,
        calendarsDictionary,
        updatedDataActivityBroadcast,
    } = useContext(PlanningContext);

    const { activeTab } = useContext(TabContext);

    const showHistogram =
        activeTab?.tabType === TAB_TYPE.GANTT
            ? planningPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value
            : activeTab?.tabType === TAB_TYPE.RESOURCE;

    const generateActivityCalendarSlice = useCallback(
        (activity, timeScale) => {
            const scaleUnit = xAxisDateFormat(generalTranslation)[timeScale].mainScaleUnit;
            const noWorkingPeriods =
                calendarsDictionary?.[activity.calendarId]?.[CalendarNoWorkingAttribute[scaleUnit]] ?? [];
            return computeActivityCalendarSlice(
                [new ActivitySlice(activity.startDate, activity.endDate)],
                noWorkingPeriods,
                scaleUnit,
                activity.startDate,
                activity.endDate
            );
        },
        [calendarsDictionary]
    );

    const updateActivityResourceVirtualTasks = useCallback(
        async (taskId, updatedData, isBroadcast = true, slices = []) => {
            if (window.ganttInstance && planningPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value) {
                window.saveScrollState = false;

                try {
                    // console.log('ADDING RNEED TASKS');
                    const tasksToUpdate = window.ganttInstance.getTaskBy('serverId', updatedData.id, {project: true, task: true, milestone: true});
                    if (isBroadcast) {
                        // deleting resource virtual tasks
                        tasksToUpdate.forEach(({ id }) => {
                            window.ganttInstance.getChildren(id).forEach((childId) => {
                                window.ganttInstance.silent(() => {
                                    const child = window.ganttInstance.getTask(childId);
                                    if (child.isResource) {
                                        window.ganttInstance.deleteTask(childId);
                                    }
                                });
                            });
                        });
                    }

                    let newActivityCalendarSlices = [];
                    if (isBroadcast) {
                        newActivityCalendarSlices = generateActivityCalendarSlice(updatedData, scale);
                        setActivityCalendarSliceDictionary((oldValue) => {
                            const newValue = {...oldValue};
                            newValue[taskId] = newActivityCalendarSlices;
                            return newValue;
                        });
                    } else {
                        newActivityCalendarSlices = slices;
                    }
                    const resourceViewModeObject = RESOURCE_VIEW_MODE(i18n);
                    switch (planningPreferences?.gantt_parameters?.showResources) {
                        case resourceViewModeObject.NEEDS.value: {
                            const activityAllocatedNeeds = updatedData.allocatedNeed || [];
                            [
                                ...activityAllocatedNeeds.nonConsumable.global,
                                ...activityAllocatedNeeds.nonConsumable.planning,
                            ].forEach((allocatedNeed, index) => {
                                tasksToUpdate.forEach(({ id, startDate, endDate, calendarId, type }) => {
                                    window.ganttInstance.silent(() => {

                                    window.ganttInstance.addTask(
                                        {
                                            ...formatResourceTask(
                                                id,
                                                newActivityCalendarSlices,
                                                allocatedNeed.needLabel,
                                                startDate,
                                                endDate,
                                                false
                                            ),
                                            quantity: allocatedNeed.quantity,
                                            calendarId,
                                            needType: NEED_TYPE.NON_CONSUMMABLE,
                                            parentType: type,
                                            class: `resource-task-${allocatedNeed?.needId || 'empty'}`,
                                        },
                                        id,
                                        index
                                    );
                                });
                                });
                            });
                            [
                                ...activityAllocatedNeeds.consumable.global,
                                ...activityAllocatedNeeds.consumable.planning,
                            ].forEach((allocatedNeed, index) => {
                                tasksToUpdate.forEach(({ id, startDate, endDate, calendarId, type }) => {
                                    window.ganttInstance.silent(() => {
                                        window.ganttInstance.addTask(
                                            {
                                                ...formatResourceTask(
                                                    id,
                                                    newActivityCalendarSlices,
                                                    allocatedNeed.needLabel,
                                                    startDate,
                                                    endDate,
                                                    false
                                                ),
                                                quantity: allocatedNeed.quantity,
                                                calendarId,
                                                needType: NEED_TYPE.CONSUMMABLE,
                                                parentType: type,
                                                class: `resource-task-${allocatedNeed?.needId || 'empty'}`,
                                            },
                                            id,
                                            index
                                        );
                                    });
                                });
                            });
                            if (isBroadcast) {
                                window.ganttInstance.render();
                                window.ganttInstance.refreshData();
                            }
                            break;
                        }
                        default:
                            break;
                    }

                    window.saveScrollState = true;
                } catch (error) {
                    console.log('🚀 ~ file: SliceContext.jsx:133 ~ error:', error);
                }
            }
        },
        [planningPreferences, scale, calendarsDictionary]
    );

    const computeActivityCalendarSliceDictionary = useCallback(
        (timescale) => {
            if (showHistogram) {
                const dictionary = {};

                console.log('🚀 STEP 1 - COMPUTING CALENDAR SLICE DICTIONARY');
                // remove all resource tasks
                if (window.ganttInstance) {
                    window.ganttInstance.getTaskByTime().forEach(({ id, isResource }) => {
                        if (isResource) {
                            window.ganttInstance.silent(() => window.ganttInstance.deleteTask(id));
                        }
                    });
                }
                Object.values(activitiesDictionary).forEach((activity) => {
                    // if (activity.allocatedNeed)
                    const allAllocatedNeeds = activity.allocatedNeed
                        ? [
                              ...activity.allocatedNeed.nonConsumable.global,
                              ...activity.allocatedNeed.nonConsumable.planning,
                              ...activity.allocatedNeed.consumable.global,
                              ...activity.allocatedNeed.consumable.planning,
                          ]
                        : [];
                    if (allAllocatedNeeds.length > 0) {
                        dictionary[activity.id] = generateActivityCalendarSlice(activity, timescale);
                        updateActivityResourceVirtualTasks(activity.id, activity, false, dictionary[activity.id]);
                    }
                });
                setActivityCalendarSliceDictionary(dictionary);
                if (window.ganttInstance) {
                    // setTimeout(() => {
                        window.ganttInstance.render();
                    // }, 1000);
                }
            } else {
                setActivityCalendarSliceDictionary(null);
                console.log('AUTO SCROLLING SLICE');
                restoreScrollState(planningPreferences);
            }
        },
        [calendarsDictionary, activitiesDictionary, showHistogram, planningPreferences]
    );

    useEffect(() => {
        if (planningSelected && processingGantt === false) {
            const currentScale = scale || scaleConfigs[1].name;
            // console.log('🚀 ~ file: SliceContext.jsx:88 ~ useEffect ~ currentScale:', currentScale);
            timer('calcul des slices :', () => {
                computeActivityCalendarSliceDictionary(currentScale);
            });
        }
    }, [processingGantt, scale]);

    const reloadChart = () => {
        const currentScale = scale || scaleConfigs[1].name;
        computeActivityCalendarSliceDictionary(currentScale);
    };

    // Update du dictionnaire lors d'un notifier

    useEffect(() => {
        if (
            updatedDataActivityBroadcast && !updatedDataActivityBroadcast.isNewTask &&
            shouldUpdateSlices(updatedDataActivityBroadcast.diffFields) &&
            showHistogram
        ) {
            updateActivityResourceVirtualTasks(
                updatedDataActivityBroadcast.id,
                updatedDataActivityBroadcast.updatedData
            );
        }
    }, [updatedDataActivityBroadcast]);

    useEffect(() => {
        if (showHistogram) {
            if (planningPreferences?.gantt_scales) {
                setScale(planningPreferences?.gantt_scales);
            } else {
                setScale(scaleConfigs[1].name);
            }
        }
    }, [planningPreferences?.gantt_scales]);

    return (
        <SliceContext.Provider
            value={{
                scale,
                activityCalendarSliceDictionary,
                reloadChart,
            }}
        >
            {children}
        </SliceContext.Provider>
    );
};

SliceContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { SliceContext, SliceContextProvider };
