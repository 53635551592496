import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import background from '../assets/images/guest-background.jpg';
import ErrorHeader from './ErrorHeader';

const NotFound = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'not_found' })
    return (
        <div className="h-screen bg-cover overflow-hidden" style={{ backgroundImage: `url('${background}')` }}>
            <ErrorHeader />
            <div className="h-screen flex items-center justify-center text-white" style={{ backgroundColor: 'rgba(0,0,0,0.5)', flexDirection: 'column' }}>
                <p style={{fontSize: '90px'}}>404</p>
                <label>{t('page_not_found')}</label>
                <Link to="/" >{t('goback_msg')}</Link>
            </div>
        </div>
    )
};
;

export default NotFound;
