import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlanningContext } from '../contexts/app/PlanningContext';

const useMailTo = () => {
    const currentUser = localStorage.getItem('user');
    const programInfo = localStorage.getItem('prg');
    const { t } = useTranslation('translation');

    const { planningSelected } = useContext(PlanningContext);

    const infoState = useMemo(
        () => `
  User info:
  ${currentUser}

  Program info:
  ${programInfo}

  Planning info:
  ${JSON.stringify(planningSelected)}
  `,
        [currentUser, programInfo, planningSelected]
    );

    const SUPPORT_EMAIL = 'support@projetlineaire.com';

    const sendMailTo = (subject, body, needConsole = true) => {
        if (needConsole) {
            const consoleContent = JSON.parse(localStorage.getItem('consoleLogs'));
            navigator.clipboard.writeText(consoleContent.join('\n'));
        }
        const mailtoLink = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink, '_blank');
    };
    const sendSSEProblemMail = (SSEStatusError) => {
        const subject = encodeURIComponent(t('sse_test.connexion_error'));
        const body = `
        ${t('mailto.hello')}, 

        ${t('mailto.error_sse')}

        ${t('mailto.debugging_tips')}

        ${t('mailto.error_details')}:
        ${SSEStatusError}

        ${infoState}

        ${t('mailto.debugging_info')}: 
        [${t('mailto.paste_here')}] 
        


    `;
        sendMailTo(subject, body);
        
    };
    const sendLatencyProblemMail = () => {
        const subject = encodeURIComponent( t('mailto.latency_problem'));
        const body = `
        ${t('mailto.hello')}, 
        
        ${t('mailto.error_latency')}
        
        ${t('mailto.latency_msg')}

        ${t('mailto.debugging_tips')}
        
        ${infoState}

        ${t('mailto.debugging_info')}: 
        [${t('mailto.paste_here')}] 
        


    `;

        sendMailTo(subject, body);
    };
    const sendWhitePageMail = () => {
        const subject = encodeURIComponent(t('mailto.white_page_problem'));
        const body = `
        ${t('mailto.hello')}, 
        
        ${t('mailto.white_page_msg')}

        ${t('mailto.debugging_tips')}
        
        ${infoState}
        
        ${t('mailto.debugging_info')}: 
        [${t('mailto.paste_here')}] 

    `;

        sendMailTo(subject, body);
    };

    const sendProblemMail = (mailBody = '') => {
        const subject = encodeURIComponent(t('mailto.some_problem'));
        const body = `
        ${t('mailto.hello')}, 

        ${t('mailto.some_problem_msg')}

        ${mailBody}
        ${infoState}

    `;

        sendMailTo(subject, body, false);
    };

    return { infoState, sendLatencyProblemMail, sendWhitePageMail, sendProblemMail, sendSSEProblemMail };
};

export default useMailTo;
