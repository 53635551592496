import { Popover, Tooltip, Tabs, Spin } from 'antd';
import { detailedDiff } from 'deep-object-diff';
import { clone } from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as ParameterSvg } from '../../assets/icons/setting.svg';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { TabContext } from '../../contexts/app/TabContext';
import { notificationSuccess } from '../../helpers/notification';
import GanttMarkerSetting from './setting/GanttMarkerSetting';
import GanttSettingGlobal from './setting/GanttSettingGlobal';
import LevelSetting from './setting/LevelSetting';

const { TabPane } = Tabs;
// This component is used for customizing Gantt appearance or behaviour
const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const GanttSetting = () => {
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newUserPreferences, setNewUserPreferences] = useState({});
    const { tabPreferences, updateTabPreferences } = useContext(TabContext);
    const { refreshPlanningSelected } = useContext(PlanningContext);
    const {t} = useTranslation('translation', {keyPrefix: 'gantt_setting'})

    useEffect(() => {
        setNewUserPreferences(clone(tabPreferences));
    }, [tabPreferences]);

    // const saveConfig = (refetchCalendar = false, needRefresh = false, refreshGantt) => {
    //     setPopoverVisible(false);
    //     if (refreshGantt) {
    //         refreshPlanningSelected(needRefresh, refetchCalendar);
    //     }
    // };

    // const handleSavePreferences = (newPreferences) => {
    //     setNewUserPreferences(newPreferences);
    //     // const diff = detailedDiff(tabPreferences, newPreferences);
    //     // const diffFields = [
    //     //     ...new Set([...Object.keys(diff.added), ...Object.keys(diff.updated, ...Object.keys(diff.deleted))]),
    //     // ];
    //     // console.log('🚀 ~ file: GanttSetting.jsx:43 ~ handleSavePreferences ~ diffFields', diffFields);
    // };
    const content = (
        <Spin spinning={loading} tip={t('preferences_saving')}>
            <Tabs defaultActiveKey="global" destroyInactiveTabPane>
                <TabPane tab={t('global')} key="global">
                    <GanttSettingGlobal tabPreferences={newUserPreferences} setNewPreferences={setNewUserPreferences} />
                </TabPane>
                <TabPane tab={t('appearance')} key="police">
                    <LevelSetting tabPreferences={newUserPreferences} setNewPreferences={setNewUserPreferences} />
                </TabPane>
                <TabPane tab={t('markers')} key="marker">
                    <GanttMarkerSetting tabPreferences={newUserPreferences} setNewPreferences={setNewUserPreferences} />
                </TabPane>
            </Tabs>
        </Spin>
    );

    const handlePopupVisible = async () => {
        if (!popoverVisible === false) {
            const diff = detailedDiff(tabPreferences, newUserPreferences);
            const diffFields = [
                ...new Set([...Object.keys(diff.added), ...Object.keys(diff.updated, ...Object.keys(diff.deleted))]),
            ];
            if (diffFields.length > 0) {
                setLoading(true);
                await updateTabPreferences({ ...newUserPreferences });
                notificationSuccess(t('gantt_setting'), t("preferences_saved"))
                setLoading(false);
                refreshPlanningSelected(true, true);
            }
        }

        setPopoverVisible(!popoverVisible);
    };

    return (
        <div className="flex items-center">
            <Popover
                placement="bottom"
                title={t('gantt_setting')}
                content={content}
                trigger="click"
                open={popoverVisible}
                onOpenChange={() => handlePopupVisible()}
                className="border-0 shadow-none bg-transparent"
            >
                <Tooltip title={t('gantt_setting')}>
                    <QuickButton
                        type="button"
                        className={`ant-dropdown-link inline-block text-center ${
                            popoverVisible ? 'bg-black text-white' : 'bg-primary'
                        }`}
                        onClick={(e) => e.preventDefault()}
                    >
                        <ParameterSvg className="mx-auto" style={{ width: '24px', height: '24px' }} />
                    </QuickButton>
                </Tooltip>
            </Popover>
        </div>
    );
};

export default GanttSetting;
