import { notification } from 'antd';
import * as Sentry from '@sentry/browser';
import i18n from '../i18n';

notification.config({
    maxCount: 3
  });

export const defaultErrorMessage = 'Une erreur est survenue';

export const notificationSuccess = (notificationTitle, message) => {
    notification.success({
        message: notificationTitle,
        description: message,
    });
};

export const notificationInfo = (notificationTitle, message, otherConfig = {}) => {
    notification.info({
        message: notificationTitle,
        description: message,
        ...otherConfig
    });
};

export const notificationError = (notificationTitle, message, otherConfig = {}) => {
    notification.error({
        message: notificationTitle,
        description: message,
        ...otherConfig
    });
};

export const requestError = (error, notificationTitle) => {
    let description;
    if (typeof error === 'string') {
        description = defaultErrorMessage;
        Sentry.captureMessage(error, {
            extra: {
                usr: window.localStorage.getItem('user')
            }
          });
        console.error(error);
    } else if (error?.response?.data?.type) {
        description = i18n.t('error.'.concat(error.response.data.type));
    } else {
        description = i18n.t('general.generic_error');
        console.error(error);
    }
    Sentry.setUser({
        ...JSON.parse(window.localStorage.getItem('user'))
    })
    

    notification.error({
        message: notificationTitle,
        description,
    });
};
