import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Empty, Popconfirm, Popover, Row, Space, Switch, Typography } from 'antd';
import { sortBy, uniqBy } from 'lodash';

import { PlanningContext } from '../../contexts/app/PlanningContext';
import { UserContext } from '../../contexts/app/UserContext';
import { TabContext } from '../../contexts/app/TabContext';
import FilterList from './FilterList';
import FilterQueryBuilder from './FilterQueryBuilder';
import AddFilterForm from './AddFilterForm';
import {
    FIELD_INPUT_TYPE,
    choiceValidator,
    customFieldsIds,
    dateValidator,
    excludedFields,
    externalValidator,
    genericValidator,
    getCustomFieldInfo,
    getDefaultValue,
    getOperatorsOfType,
    multipleChoiceValidator,
    simpleChoiceValidator,
} from './FilterUtils';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import { ACTIVITY_STATE } from '../../helpers/planning';
import {quantityUnits} from '../plannings/activity_config';
import { NeedsContext } from '../../contexts/needs/NeedsContext';
import { PlanningNeedsContext } from '../../contexts/needs/PlanningNeedsContext';

const PlanningFiltersTab = ({ onSaveChanges, commitChanges }) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'filter_dropdown' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const { planningSelected, activitiesDictionary, allCustomFields, calendarsDictionary, timeUnits } =
        useContext(PlanningContext);
    const { userPreferences } = useContext(UserContext);
    const { activeTabFilterData, tabPreferences } = useContext(TabContext);
    const globalNeedList = useContext(NeedsContext).NeedList;
    const planningNeedList = useContext(PlanningNeedsContext).NeedList;
    const [currentFilterIndex, setCurrentFilterIndex] = useState(null);
    const [filtersData, setFiltersData] = useState(userPreferences?.filters?.[planningSelected.id] || []);
    const [validationFiltersData, setValidationFiltersData] = useState([]);
    const [showOnlyActiveFilters, setShowOnlyActiveFilters] = useState(false);

    const [activeFilters, setActiveFilters] = useState(activeTabFilterData?.activeFilters || []);
    const [queryBuilderFields, setQueryBuilderFields] = useState([]);

    const [isDuplicateFilterFormOpen, setIsDuplicateFilterFormOpen] = useState(false);

    const fieldsMap = useMemo(() => {
        if (queryBuilderFields.length > 0) {
            const fieldsObj = queryBuilderFields.reduce((prevObj, current) => {
                const result = prevObj;
                result[current.name] = current;
                return result;
            }, {});
            return fieldsObj;
        }
        return null;
    }, [queryBuilderFields]);

    useEffect(() => {
        if (fieldsMap) {
            // adding validation state
            const listWithValidation = filtersData.map((i) => ({ invalid: externalValidator(i.data, fieldsMap) }));
            setValidationFiltersData(listWithValidation);
        }
    }, [filtersData, fieldsMap]);

    const getFieldChoice = (customField, dataType) => {
        let choices = [];
        switch (dataType) {
            case 'choice':
                choices = Object.keys(customField.type.choices).map((choice) => ({ label: choice, value: choice }));
                break;
            case JOB_FIELD:
                choices = Object.keys(customField.type.choices).map((choice) => ({ label: choice !== ALL_JOBS ? choice : generalTranslation('all_jobs'), value: choice }));
                break;
            case customFieldsIds.ancestor:
                choices = uniqBy(Object.values(activitiesDictionary)
                    .filter((activity) => activity.subActivitiesId.length)
                    .map((act) => ({ label: act.name, value: act.name })), 'label');
                break;
            case customFieldsIds.calendar:
                choices = Object.values(calendarsDictionary).map((calendar) => ({
                    label: calendar.name,
                    name: calendar.name,
                }));
                break;
            case customFieldsIds.quantityUnit:
                choices = quantityUnits;
                break;
            case customFieldsIds.needs:
                choices = [...globalNeedList, ...planningNeedList]
                break;
            case customFieldsIds.durationAndUnit:
                choices = timeUnits.map((timeUnit) => ({ label: timeUnit.name, value: timeUnit.id }));
                break;
            case customFieldsIds.valid:
                choices = [
                    { label: generalTranslation('validated'), name: ACTIVITY_STATE.VALID },
                    { label: generalTranslation('not_validated'), name: ACTIVITY_STATE.UNVALID },
                    { label: generalTranslation('locked'), name: ACTIVITY_STATE.LOCKED },
                ];
                break;
            default:
                break;
        }
        return sortBy(choices, o => o.label);
    };

    useEffect(() => {
        const allFields = sortBy(allCustomFields, ['name'])
            .filter((field) => excludedFields.indexOf(field.id) === -1)
            .map((customField) => {
                const field = {
                    name: customField.id,
                    label: customField.name === JOB_FIELD ? generalTranslation('job_field') : customField.name,
                };
                field.operators = getOperatorsOfType(customField, i18n);
                const fieldInfo = getCustomFieldInfo(customField);
                field.inputType = fieldInfo.type;
                if (fieldInfo.data === 'date' ) {
                    field.validator = dateValidator;
                }
                if (field.inputType === FIELD_INPUT_TYPE.NUMBER_MIN_MAX && fieldInfo.data === customFieldsIds.progress) {
                    field.min = 0;
                    field.max = 100;
                    field.defaultValue = 0;
                }
                if (field.inputType === FIELD_INPUT_TYPE.NUMBER_MIN_MAX && (fieldInfo.data === customFieldsIds.quantity || fieldInfo.data === customFieldsIds.workrate)) {
                    field.min = 0; 
                    field.defaultValue = 0;
                }
                if (field.inputType === FIELD_INPUT_TYPE.NUMBER_MIN_MAX_SELECT && fieldInfo.data === customFieldsIds.durationAndUnit) {
                    field.min = 0;
                    field.selectChoices = getFieldChoice(customField, fieldInfo.data);
                    field.values = getFieldChoice(customField, fieldInfo.data);
                    field.defaultValue = {value: 0, unit: getDefaultValue(field.selectChoices)};

                }
                if (field.inputType === FIELD_INPUT_TYPE.SELECT_OR_INPUT && fieldInfo.data === JOB_FIELD) {
                    field.values = getFieldChoice(customField, fieldInfo.data);
                    field.defaultValue = getDefaultValue(field.values);
                    console.log("🚀 ~ .map ~ field:", field)
                    field.validator = genericValidator
                }
                if (field.inputType === FIELD_INPUT_TYPE.TREESELECT_OR_INPUT && fieldInfo.data === customFieldsIds.needs) {
                    field.values = getFieldChoice(customField, fieldInfo.data);
                    field.defaultValue = getDefaultValue(field.values, 'name',true )
                    field.fieldNames = { label: 'name', value: 'name', children: 'children' }
                    field.validator = multipleChoiceValidator
                }
                
                // field.hideHours = hideHours;
                if (
                    field.inputType === FIELD_INPUT_TYPE.SELECT ||
                    field.inputType === FIELD_INPUT_TYPE.SEARCH_SELECT
                ) {
                    field.valueEditorType = field.inputType;
                    const choices = getFieldChoice(customField, fieldInfo.data);
                    field.values = choices;
                    switch (field.inputType) {
                        case FIELD_INPUT_TYPE.SEARCH_SELECT:
                            field.validator = (rule) => choiceValidator(rule, choices, 'value')
                            field.defaultValue = getDefaultValue(choices)
                            break;
                        default:
                            field.validator = (rule) => 
                                simpleChoiceValidator(rule, choices)
                            field.defaultValue = getDefaultValue(choices)
                            field.isSimpleChoice = true;
                            break;
                    }
                }
                return field;
            });
        setQueryBuilderFields(allFields);
    }, [allCustomFields]);

    const handleAddNewFilter = (newFilterData) => {
        setFiltersData((oldData) => {
            const newData = [newFilterData, ...oldData];
            setCurrentFilterIndex(0);
            return newData;
        });
    };

    const handleDuplicateFilter = (filterData, originalFilterData) => {
        const filterToAdd = { ...filterData, data: originalFilterData };
        setFiltersData((oldData) => {
            const newData = [filterToAdd, ...oldData];
            setCurrentFilterIndex(0);
            return newData;
        });
    };

    const updateCurrentFilter = useCallback(
        (newCurrentFilterData) => {
            setFiltersData((oldData) => {
                const newData = [...oldData];
                newData[currentFilterIndex] = { ...newData[currentFilterIndex], ...newCurrentFilterData };
                return newData;
            });
        },
        [currentFilterIndex]
    );

    const handleDeleteFilter = useCallback(() => {
        setFiltersData((oldData) => {
            const newData = [...oldData];
            const filterToDelete = oldData[currentFilterIndex];
            setActiveFilters((old) =>
                old.flatMap((id) => {
                    if (id === filterToDelete.id) {
                        return [];
                    }
                    return id;
                })
            );
            newData.splice(currentFilterIndex, 1);
            return newData;
        });
    }, [currentFilterIndex]);

    const isFilterActive = useMemo(
        () => activeFilters.indexOf(filtersData[currentFilterIndex]?.id) !== -1,
        [activeFilters, currentFilterIndex, filtersData]
    );

    const handleFilterActiveChange = useCallback(
        (checked) => {
            if (checked) {
                setActiveFilters((old) => [...old, filtersData[currentFilterIndex].id]);
            } else {
                setActiveFilters((old) => {
                    const newVal = [...old];
                    const indexToRemove = newVal.indexOf(filtersData[currentFilterIndex].id);
                    newVal.splice(indexToRemove, 1);
                    return newVal;
                });
            }
        },
        [currentFilterIndex, filtersData]
    );

    useEffect(() => {
        console.log("🚀 ~ file: PlanningFiltersTab.jsx:229 ~ useEffect ~ activeFilters:", activeFilters)
        onSaveChanges(filtersData, activeFilters);
    }, [filtersData, activeFilters]);
    /* eslint-disable */
    return (
        <Row style={{ height: '600px' }}>
            <Col className="w-1/5">
                <FilterList
                    checkedFilters={activeFilters}
                    setCheckedFilters={setActiveFilters}
                    currentFilterIndex={currentFilterIndex}
                    setCurrentFilterIndex={setCurrentFilterIndex}
                    filtersData={filtersData}
                    validationData={validationFiltersData}
                    onlyActiveFilters={showOnlyActiveFilters}
                    setOnlyActiveFilters={setShowOnlyActiveFilters}
                    onAddNewFilter={handleAddNewFilter}
                />
            </Col>
            <Col className="w-4/5 pl-2" style={{ height: '600px' }}>
                {filtersData[currentFilterIndex] ? (
                        
                        <div  className="w-full h-full overflow-auto">

                    <Card
                        size="small"
                        title={
                            <Typography.Paragraph
                                strong
                                editable={{
                                    onChange: (val) => updateCurrentFilter({ name: val }),
                                }}
                            >
                                {filtersData[currentFilterIndex].name}
                            </Typography.Paragraph>
                        }
                        extra={
                            <Space className="sticky top-0">
                                <Switch
                                    style={{ backgroundColor: isFilterActive ? 'green' : 'orange' }}
                                    checkedChildren={t('enabled')}
                                    unCheckedChildren={t('disabled')}
                                    checked={isFilterActive}
                                    onChange={handleFilterActiveChange}
                                />
                                <Button type="primary" onClick={commitChanges}>{generalTranslation('save')}</Button>
                                <Popover
                                    content={
                                        <AddFilterForm
                                            onNewFilter={(data) => {
                                                setIsDuplicateFilterFormOpen(false);
                                                handleDuplicateFilter(data, filtersData[currentFilterIndex].data);
                                            }}
                                        />
                                    }
                                    title={t('duplicate')}
                                    trigger="click"
                                    placement="bottom"
                                    open={isDuplicateFilterFormOpen}
                                    onOpenChange={setIsDuplicateFilterFormOpen}
                                >
                                    <Button type="primary">{t('duplicate')}</Button>
                                </Popover>
                                <Popconfirm
                                    title={t('delete_filter_confirm')}
                                    onCancel={() => {}}
                                    onConfirm={() => handleDeleteFilter()}
                                    okText={generalTranslation('yes')}
                                    cancelText={generalTranslation('no')}
                                >
                                    <Button type="danger">{generalTranslation('delete')}</Button>
                                </Popconfirm>
                            </Space>
                        }
                        className="w-full h-full"
                        headStyle={{position: 'sticky', top: '0px', background: 'white', zIndex: 100}}
                    >
                        <FilterQueryBuilder
                            fields={queryBuilderFields}
                            currentFilterQuery={filtersData[currentFilterIndex].data}
                            onQueryChange={(query) => updateCurrentFilter({ data: query })}
                            hideHours={tabPreferences?.gantt_parameters?.hideHours}
                        />
                    </Card>
                    </div>
                ) : (
                    <Empty className="w-full h-full border-1 border-solid" description={t('no_selected_filter')} />
                )}
            </Col>
        </Row>
    );
};

export default PlanningFiltersTab;
