import React, { forwardRef, useCallback, useContext, useImperativeHandle, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Popconfirm, Row } from 'antd';
import FolderService from '../../services/folder.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
/* eslint-disable */
import { getPlanningAccess, USER_ACCESS } from '../../helpers/planning';
import { UserContext } from '../../contexts/app/UserContext';
import ProjectExplorer from '../project-explorer/ProjectExplorer';

const ExplorerModal = forwardRef((props, ref) => {
    const [isVisible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {
        setFolderSelected,
        updatePlanning,
        modeSelected,
        setModeSelected,
        folderSelected,
        requestPlanningsInFolderForMode,
    } = useContext(PlanningContext);
    const { updateUserPreferences, currentUser, userPreferences } = useContext(UserContext);
    const { t } = useTranslation('translation', { keyPrefix: 'explorer_modal' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    const onOk = useCallback(async () => {
        if (selectedItem) {
            if (!selectedItem.rootActivityId) {
                setIsLoading(true);
                setFolderSelected(selectedItem);
                await updateUserPreferences({ open_folder: selectedItem, open_planning: null });
                const plannings = await requestPlanningsInFolderForMode(selectedItem.id, modeSelected);
                const planningWithAccess = plannings.filter(
                    (p) => getPlanningAccess(p, currentUser) !== USER_ACCESS.NONE
                );
                if (planningWithAccess.length) {
                    await updatePlanning(planningWithAccess.first());
                } else {
                    await updatePlanning(null);
                }
                setIsLoading(false);
                setVisible(false);
            } else {
                let selectedFolder;
                if (selectedItem.folderId) {
                    selectedFolder = await FolderService.showFolder(selectedItem.folderId);
                    setFolderSelected(selectedFolder);
                }
                await updateUserPreferences({
                    open_folder: selectedFolder || userPreferences.open_folder,
                    open_mode: selectedItem.mode,
                    open_planning: { ...userPreferences.open_planning, [selectedItem.mode]: selectedItem },
                });
                setModeSelected(selectedItem.mode);
                await updatePlanning(selectedItem);
                setVisible(false);
            }
        }
    }, [selectedItem]);
    const handleDeletePlanning = (id) => {
        const deletePlanning =
            modeSelected === 'sandbox' ? FolderService.deleteSandboxPlanning(id) : FolderService.deletePlanning(id);
        deletePlanning.then(() => {
            console.log('deleted');
        });
    };
    const modalFooter = useMemo(
        () => (
            <Row className="flex justify-between">
                <div>
                    {selectedItem?.rootActivityId &&
                        [USER_ACCESS.NONE, USER_ACCESS.READ].indexOf(selectedItem?.access) === -1 && (
                            <Popconfirm
                                title={t('are_you_sure_you_want_to_delete')}
                                okText={generalTranslation('yes')}
                                cancelText={generalTranslation('no')}
                                onConfirm={() => handleDeletePlanning(selectedItem.id)}
                            >
                                <Button type="danger">{t('delete_planning')}</Button>
                            </Popconfirm>
                        )}
                </div>
                <div>
                    <Button type="secondary" className="mr-2" onClick={onCancel}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button type="primary" loading={isLoading} disabled={!selectedItem} onClick={onOk}>
                        {generalTranslation('ok')}
                    </Button>
                </div>
            </Row>
        ),
        [selectedItem, isLoading]
    );

    return (
        <Modal
            title={t('folder_explorer')}
            className="explorer-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            width={window.innerWidth * 0.65}
            bodyStyle={{ padding: 0, height: window.innerHeight * 0.8, overflow: 'auto' }}
            keyboard={false}
            destroyOnClose
            footer={modalFooter}
        >
            <ProjectExplorer
                specificMode={[modeSelected]}
                folder={folderSelected}
                onlyExplorer
                onItemSelected={(item) => setSelectedItem(item)}
            />
        </Modal>
    );
});

export default ExplorerModal;
