/* eslint no-unused-vars: "off" */

import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin, Collapse, Slider, Modal } from 'antd';
import styled from 'styled-components';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
// import { UserContext } from '../../../contexts/app/UserContext';
import ActivityService from '../../../services/activity.service';
import CustomFieldService from '../../../services/custom-field.service';
import { requestError } from '../../../helpers/notification';
import {quantityUnits} from '../activity_config';
import { ACTIVITY_STATE, formatGanttTask } from '../../../helpers/planning';
// import TaskStripes from '../../../helpers/task-stripes';
import { clearEmpties, commonValues } from '../../../helpers/object-helper';
import { ALL_JOBS, JOB_FIELD } from '../../../constants/Jobs';
import { TabContext } from '../../../contexts/app/TabContext';

const { Panel } = Collapse;
const { Option } = Select;
const DrawerRow = styled(Row)`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;

    & > * {
        flex-grow: 1;
    }
`;

const MultipleActivityEdit = ({ activitiesId }) => {
    const {t} = useTranslation('translation', {keyPrefix:'activity_tab'})
    const fieldTranslate = useTranslation('translation', {keyPrefix:'columns'}).t
    const translate = useTranslation().t
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {
        planningCustomFields,
        allCustomFields,
        activitiesDictionary,
        updateActivitiesDictionary,
        // setTasksStripesStyles,
        planningSelected,
        calendarsDictionary,
        timeUnits,
        fetchCalendars,
        setReRenderGantt,
        // setRefreshGantt,
        planningFilter,
        isMultiJobUser,
        isUserJob,
        // userJobsWithoutMulti,
    } = useContext(PlanningContext);
    const [selectedActivities, setSelectedActivities] = useState({});
    const [defaultValues, setDefaultValues] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [disabledFields, setDisabledFields] = useState({});
    const [calendars, setCalendars] = useState([]);
    // const [timeUnits, setTimeUnits] = useState([]);
    const [taskProgress, setTaskProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState([]);
    // const dateFormat = 'DD/MM/YYYY HH:mm';
    const [form] = Form.useForm();
    const [activityCustomFields, setActivityCustomFields] = useState([...planningCustomFields]);
    const [champPmAutoOptions, setChampPmAutoOptions] = useState([]);
    const [selectedJobs, setSelectedJobs] = useState([]);
    const {tabPreferences} = useContext(TabContext)

    // const { userPreferences } = useContext(UserContext);
    const isProject = activitiesId.includes(planningSelected.rootActivityId.toString());

    useEffect(() => {
        (async () => {
            setLoading(true);
            const activitiesList = Object.values(activitiesDictionary).filter(
                (act) => !activitiesId.includes(act.id) && act.activityState === ACTIVITY_STATE.UNVALID
            );
            setActivities(activitiesList);

            const calendarList = Object.values(calendarsDictionary);
            setCalendars(calendarList);
                const autoUnifiedField = [-10, -13, -11];
                setChampPmAutoOptions(
                    allCustomFields.filter((customField) => autoUnifiedField.includes(customField.id))
                );
            const jobsList = Object.keys(planningCustomFields.find((i) => i.name === JOB_FIELD)?.type.choices);

            setJobs(
                jobsList.map((i) => ({
                    value: i,
                    label: i,
                    disabled: isMultiJobUser() ? false : isUserJob([i]),
                }))
            );

            setLoading(false);
        })();
    }, [activitiesId]);

    // yield unit
    const getDurationUnitName = (id) => {
        const timeUnit = timeUnits.find((time) => time.id === id);
        return timeUnit ? timeUnit.name : '-';
    };
    const getQuantityUnitName = (id) => {
        const quantityUnit = quantityUnits.find((unit) => unit.value === id);
        return quantityUnit ? quantityUnit.label : '-';
    };

    const getYieldUnit = (quantityId, durationId) =>
        `${getQuantityUnitName(quantityId)}/${getDurationUnitName(durationId)}`;
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const updateActivityData = async (attr, value) => {
        setLoading(true);
        try {
            let activitiesUpdated = [];

            switch (attr) {
                // case 'identity':
                //     activitiesUpdated = await ActivityService.updateActivityIdentity(ganttTask.id, value);
                //     break;
                case 'text':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'name',
                        value
                    );
                    break;
                case 'description':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'description',
                        value
                    );
                    break;
                case 'job':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'job',
                        value
                    );
                    // eslint-disable-next-line
                    // for (const act of Object.values(selectedActivities)) {
                    //     const updatedActivity = { ...act.data_api, job: value };
                    //     activitiesUpdated.push(updatedActivity);
                    // }
                    break;
                case 'parent': {
                    const updatedActivities = [];
                    // eslint-disable-next-line
                    await ActivityService.updateActivityParent(value, Object.keys(selectedActivities));
                    // eslint-disable-next-line
                    for (const act of Object.values(selectedActivities)) {
                        // deleting links from gantt
                        const parentTask = window.ganttInstance.getTaskByServerId(value);
                        const source = parentTask.$source;
                        // links that come from the task,

                        source.forEach((linkId) => {
                            const link = window.ganttInstance.getLink(linkId);
                            link.is_virtual = true;
                            window.ganttInstance.deleteLink(linkId);
                        });

                        const target = parentTask.$target;
                        // links that come into the task,

                        target.forEach((linkId) => {
                            const link = window.ganttInstance.getLink(linkId);
                            link.is_virtual = true;
                            window.ganttInstance.deleteLink(linkId);
                        });
                        // eslint-disable-next-line
                        // const updatedActivity = await ActivityService.showActivity(act.id);
                        // updatedActivities.push(updatedActivity);
                    }
                    activitiesUpdated = [...updatedActivities];
                    break;
                }
                case 'quantity':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'quantity',
                        value
                    );
                    break;
                case 'quantityUnit':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'quantity-unit',
                        value
                    );
                    break;
                case 'yield':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'yield',
                        value
                    );
                    break;
                case 'champPMAutoId':
                    activitiesUpdated = await ActivityService.updateMultipleActivityChampPm(
                        Object.keys(selectedActivities),
                        value
                    );
                    break;
                case 'calendarId':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'calendar-id',
                        value
                    );
                    break;
                case 'startDate':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'start-date',
                        value.clone().utc(true).valueOf()
                    );
                    break;
                case 'endDate':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'end-date',
                        value.clone().utc(true).valueOf()
                    );
                    break;
                case 'dayDefinitionId':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'time-unit',
                        value
                    );
                    break;
                case 'duration': {
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'duration',
                        value
                    );
                    break;
                }
                case 'progress':
                    activitiesUpdated = await ActivityService.updateMultipleActivityInfo(
                        Object.keys(selectedActivities),
                        'avancement',
                        Number(value)
                    );
                    break;
                case 'activity-state': {
                    // const newStatus = getNewActivityStatus(value, activity);

                    // activitiesUpdated = await ActivityService.updateActivityState(ganttTask.id, newStatus);
                    // if (newStatus === ACTIVITY_STATE.LOCKED) {
                    //     setCannotEdit(true);
                    //     setIsLocked(true);
                    // } else {
                    //     setCannotEdit(
                    //         !isProject
                    //             ? !(isUserJob(ganttTask?.jobId) || isMultiJobUser) || newStatus === ACTIVITY_STATE.VALID
                    //             : newStatus === ACTIVITY_STATE.VALID
                    //     );
                    //     setIsLocked(false);
                    // }
                    // updating childs status
                    // if (activity.type === "project") {
                    //     updateChildTaskStatus(activity.children, value);
                    // }
                    break;
                }
                default:
                    console.error(`${attr} Not implemented `);
            }
            activitiesUpdated.forEach(async (activity) => {
                const activityUpdated = activity;
                const updatedData = {
                    ...activityUpdated,
                    startDate: moment(activityUpdated.startDate).utc(),
                    endDate: moment(activityUpdated.endDate).utc(),
                    yieldUnit: getYieldUnit(activityUpdated.quantityUnit, activityUpdated.dayDefinitionId),
                };
                // updateActivitiesDictionary(updatedData.id, updatedData);
                // // const isGroupement =
                // //     userPreferences?.filtered_group?.[planningSelected.rootActivityId]?.groupingType === 'custom_fields';
                // // // toggle parent value for Gantt (using fake id)
                // if (attr !== 'parent') {
                //     activityUpdated.activityParentId = selectedActivities[activity.id].parent;
                // } else {
                //     activityUpdated.activityParentId = window.ganttInstance.getTaskByServerId(value)?.id;
                // }
                // if (isProject) {
                //     activityUpdated.activityParentId = 0;
                // }
                const ganttState = window.ganttInstance.getState();
                // const durationUnit = timeUnits.find(
                //     (timeUnit) => timeUnit.id === Number(activityUpdated.dayDefinitionId)
                // );
                // update duration for gantt
                // window.ganttInstance.updateTaskByServerId(
                //     activityUpdated.id,
                //     formatGanttTask(activityUpdated, {
                //         durationApi: Number(activityUpdated.duration / durationUnit.duration),
                //         parentId: updatedData.activityParentId, // serverId of parent
                //     })
                // );
                // form.setFieldsValue({
                //     ...updatedData,
                //     duration: Number(activityUpdated.duration / durationUnit.duration),
                // });

                // check if need to reget non working days
                // if isDateFiltered do not do reget
                const isFiltered = planningFilter?.root?.childs
                    ?.filter((child) => child.name === 'Filtre sur les dates')
                    .first();
                if (!isFiltered) {
                    // test startDate and minDate acoording to current ganttState
                    let newMinDate = ganttState.min_date;
                    let newMaxDate = ganttState.max_date;
                    let updateNoworkingDays = false;
                    if (ganttState.min_date > activityUpdated.startDate) {
                        newMinDate = activityUpdated.startDate;
                        updateNoworkingDays = true;
                    }
                    if (ganttState.max_date < activityUpdated.endDate) {
                        newMaxDate = activityUpdated.endDate;
                        updateNoworkingDays = true;
                    }
                    if (updateNoworkingDays) {
                        await fetchCalendars(newMinDate.valueOf(), newMaxDate.valueOf());
                        setReRenderGantt((val) => val + 1);
                    }
                }
            });
            // update color
            // const isColorFiltered = userPreferences.planning_color?.[planningSelected.id];
            // if (attr === 'job' && isColorFiltered && isColorFiltered.name === JOB_FIELD) {
            //     setRefreshGantt((val) => val + 1);
            // }
            window.ganttInstance.refreshData();
        } catch (error) {
            requestError(error, t('error_update_activity'));
            Sentry.captureException(error);
        }
        setLoading(false);
    };
    const onTaskProressChange = (val) => {
        setTaskProgress(val);
        updateActivityData('progress', val);
    };

    const updateCustomFieldValue = async (value, customFieldId) => {
        setLoading(true);
        try {
            const newActivityCustomFields = activityCustomFields.reduce(
                (agg, customField) => Object.assign(agg, { [customField.id]: customField }),
                {}
            );
            newActivityCustomFields[customFieldId].value = value;
            setActivityCustomFields(Object.values(newActivityCustomFields));
            await CustomFieldService.updateCustomFieldMultipleActivityValue(
                customFieldId,
                Object.keys(selectedActivities),
                [value]
            );
            // // UPDATE PERFORMED BY NOTIFIER
            // Object.values(selectedActivities).forEach(async (act) => {
            //     // update style and dictionnary

            //     // const activityUpdated = await ActivityService.showActivity(act.id);
            //     // updateActivitiesDictionary(activityUpdated.id, activityUpdated);
            //     // const durationUnit = timeUnits.find(
            //     //     (timeUnit) => timeUnit.id === Number(activityUpdated.dayDefinitionId)
            //     // );
            //     // window.ganttInstance.updateTask(
            //     //     activityUpdated.id,
            //     //     formatGanttTask(activityUpdated, {
            //     //         durationApi: Number(activityUpdated.duration / durationUnit.duration),
            //     //     })
            //     // );
            // });
            // const isColorFiltered = userPreferences.planning_color?.[planningSelected.id];
            // if (isColorFiltered && isColorFiltered.name !== JOB_FIELD) {
                // setRefreshGantt((val) => val + 1);
                // setTasksStripesStyles(
                //     window.ganttInstance
                //         .serialize()
                //         .data.filter((flattenActivity) => TaskStripes.hasStripes(flattenActivity))
                //         .map((flattenActivity) => TaskStripes.formatForState(flattenActivity))
                // );
            // }

            window.ganttInstance.refreshData();
        } catch (error) {
            requestError(error, t("activity_tab.error_activity_update"));
        }
        setLoading(false);
    };

    const getAllParentOfActivity = (id) => {
        const parentIds = [];
        window.ganttInstance.eachParent((parent) => {
            parentIds.push(parent.id.toString());
        }, id);
        return parentIds;
    };
    const checkParentFieldDisabled = (activitiesList) => {
        let isDisabled = false;
        let activityParentIds = [];
        activitiesList.forEach((act) => {
            activityParentIds = [...activityParentIds, ...getAllParentOfActivity(act.id)];
        });
        const parentIdsAndSelectedIdsIntersection = activityParentIds.filter((value) => activitiesId.includes(value));
        if (parentIdsAndSelectedIdsIntersection.length > 0) {
            isDisabled = true;
        }
        return isDisabled;
    };
    const checkSelectedHasProject = (activitiesList) => {
        const projectActivities = activitiesList.filter((act) => act.type === window.ganttInstance.config.types.customProject);

        if (projectActivities.length > 0) {
            return true;
        }
        return false;
    };
    // check if a field must be disabled
    const checkDisabledField = (activitiesList) => {
        const fieldsDisabled = {};
        // check parent
        fieldsDisabled.parent = checkParentFieldDisabled(activitiesList);
        if (checkSelectedHasProject(activitiesList)) {
            fieldsDisabled.startDate = true;
            fieldsDisabled.endDate = true;
            fieldsDisabled.duration = true;
        }
        setDisabledFields((old) => ({ ...old, ...fieldsDisabled }));
    };

    const computeCalculMode = (activitiesList, commonTaskValues) => {
        let champPMAutoOccurence = 0;
        activitiesList.reduce((agg, current) => {
            if (agg === current.data_api?.champPMAutoId) {
                champPMAutoOccurence += 1;
            }
            return agg;
        }, activitiesList[0].data_api?.champPMAutoId);
        // Pour la combo champPM si il y a des valeurs différents => combo est vide, durée grisé, quantité grisé, rendement grisé.
        if (champPMAutoOccurence === 1 && !commonTaskValues.champPMAutoId) {
            setDisabledFields((old) => ({
                ...old,
                duration: true,
                quantity: true,
                yield: true,
            }));
        }
        if (commonTaskValues.champPMAutoId) {
            const champPMField = allCustomFields.find((i) => i.id === commonTaskValues.champPMAutoId);
            if (champPMField.name === 'Quantité') {
                setDisabledFields((old) => ({
                    ...old,
                    quantity: true,
                }));
            }
            if (champPMField.name === 'Rendement') {
                setDisabledFields((old) => ({
                    ...old,
                    yield: true,
                }));
            }
            if (champPMField.name === 'Durée') {
                setDisabledFields((old) => ({
                    ...old,
                    duration: true,
                }));
            }
        }
        // // Pour la combo champPM si la valeur est nulle pour toutes activités => combo vide, durée non grisé, quantité non grisé, rendement non grisé.
        // if (champPMAutoOccurence === activitiesList.length && !commonTaskValues.champPMAutoId) {

        // }
    };

    const computeDefaultValues = (selectedTasks) => {
        form.resetFields();
        // searching common values between tasks
        const commonTaskValues = clearEmpties(
            selectedTasks.reduce(
                (acc, val) => ({
                    ...commonValues(acc, val),
                }),
                { ...selectedTasks[0] }
            )
        );

        // building defaultValues of form
        const defaultFormValue = {
            ...commonTaskValues,
            startDate: commonTaskValues.startDate ? moment(commonTaskValues.startDate) : null,
            endDate: commonTaskValues.endDate ? moment(commonTaskValues.endDate) : null,
            duration: commonTaskValues.durationApi ?? null,
            quantity: commonTaskValues.data_api.quantity ?? null,
            quantityUnit: commonTaskValues.data_api?.quantityUnit ?? null,
            yield: commonTaskValues.data_api?.yield ?? null,
            champPMAutoId: commonTaskValues.data_api?.champPMAutoId ?? null,
            jobId: Object.values(commonTaskValues.jobId ?? {}) || [],
            parent: commonTaskValues.parentId,
        };
        setDefaultValues({ ...defaultFormValue });
        form.setFieldsValue({ ...defaultFormValue });
        setSelectedJobs(defaultFormValue.jobId)
        if (commonTaskValues.progress) {
            setTaskProgress(commonTaskValues.progress * 100);
        }
        return defaultFormValue;
    };

    const handleJobsChange = useCallback(
        (value) => {
            if (value.length === 0) {
                return;
            }
            let jobsToBeSelected = [];

            if (selectedJobs.indexOf(ALL_JOBS) === -1 && value.indexOf(ALL_JOBS) > -1) {
                jobsToBeSelected = [ALL_JOBS];
            } else {
                jobsToBeSelected = [...value];
            }
            if (selectedJobs.indexOf(ALL_JOBS) > -1) {
                jobsToBeSelected = value.filter((i) => i !== ALL_JOBS);
            }
            setSelectedJobs(jobsToBeSelected);
            updateActivityData('job', jobsToBeSelected);
        },
        [selectedJobs]
    );

    useEffect(() => {
        if (window.ganttInstance && activitiesId.length) {
            setDisabledFields({});
            const selectedTasks = activitiesId.map((i) => window.ganttInstance.getTaskByServerId(i));
            console.log("🚀 ~ file: MultipleActivityEdit.jsx:547 ~ useEffect ~ selectedTasks:", selectedTasks)

            const selectedTasksObject = selectedTasks.reduce(
                (acc, value) => ({
                    ...acc,
                    [value.serverId]: { ...value },
                }),
                {}
            );
            const defaultFormValues = computeDefaultValues(selectedTasks);
            checkDisabledField(selectedTasks);
            computeCalculMode(selectedTasks, defaultFormValues);
            setSelectedActivities(selectedTasksObject);
        }
    }, [activitiesId]);

    const renderCustomField = (customField) => {
        if (customField.type.text) {
            return (
                <Form.Item
                    label={customField.name}
                    name={`customField#${customField.id}`}
                    initialValue={customField.value}
                >
                    <Input
                        value={customField.value}
                        onBlur={(event) => updateCustomFieldValue(event.target.value, customField.id)}
                        disabled={isProject}
                    />
                </Form.Item>
            );
        }

        if (customField.type.numeric) {
            return (
                <Form.Item
                    label={customField.name}
                    name={`customField#${customField.id}`}
                    initialValue={customField.value}
                >
                    <InputNumber
                        onBlur={(event) => updateCustomFieldValue(event.target.value, customField.id)}
                        disabled={isProject}
                        keyboard={false}
                    />
                </Form.Item>
            );
        }

        return (
            <Form.Item
                label={customField.name}
                style={{ width: '115px' }}
                name={`customField#${customField.id}`}
                initialValue={customField.value}
            >
                <Select
                    options={Object.keys(customField.type.choices).reduce((options, customFieldChoiceName) => {
                        options.push({
                            label: customFieldChoiceName,
                            value: customFieldChoiceName,
                        });

                        return options;
                    }, [])}
                    onChange={(value) => updateCustomFieldValue(value, customField.id)}
                    disabled={isProject}
                    allowClear
                />
            </Form.Item>
        );
    };

    return (
        <Spin spinning={loading}>
            <div className="mr-2">
                <Button type="primary" onClick={() => setIsModalVisible(true)}>
                    {translate('general.edit')}
                </Button>
            </div>
            <Modal
                title={translate('gantt.update_multiple')}
                // className="calendar-modal"
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={450}
                height="auto"
            >
                <Spin spinning={loading}>
                    {defaultValues && (
                        <div style={{ height: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>
                            <DrawerRow>
                                <Form
                                    form={form}
                                    layout="horizontal"
                                    labelCol={{ span: 7 }}
                                    wrapperCol={{ span: 17 }}
                                    labelAlign="left"
                                    name="task_form"
                                    className="mt-3"
                                    initialValues={defaultValues}
                                    colon={false}
                                >
                                    <DrawerRow>
                        <Form.Item label={fieldTranslate('parent')} name="parent">
                                            <Select
                                                showSearch
                                                options={activities.reduce((agg, item) => {
                                                    agg.push({ label: item.name, value: item.id });

                                                    return agg;
                                                }, [])}
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(value) => updateActivityData('parent', value)}
                                                disabled={disabledFields.parent}
                                            />
                                        </Form.Item>
                                    </DrawerRow>
                                    <Row gutter={16}>
                                        <Col span={24}>
                            <Form.Item
                                label={fieldTranslate('name')}
                                name="text"
                            >
                                                <Input
                                                    onBlur={(event) => updateActivityData('text', event.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={24}>
                            <Form.Item label={fieldTranslate('description')} name="description">
                                                <Input
                                                    onBlur={(event) =>
                                                        updateActivityData('description', event.target.value)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <DrawerRow>
                        <Form.Item
                            label={fieldTranslate('jobs')}
                            required
                        >
                                            <Select
                                                options={jobs}
                                                value={selectedJobs}
                                                mode="multiple"
                                                onChange={(value) => handleJobsChange(value)}
                                            />
                                        </Form.Item>
                                    </DrawerRow>

                                    <h3>Dates</h3>
                                    <DrawerRow>
                                        <Form.Item
                                            label={fieldTranslate('calendar')}
                                            name="calendarId"
                                            rules={[{ required: true, message: translate('general.required_field') }]}
                                        >
                                            <Select
                                                options={calendars.reduce((agg, calendar) => {
                                                    agg.push({ label: calendar.name, value: calendar.id });

                                                    return agg;
                                                }, [])}
                                                onChange={(value) => updateActivityData('calendarId', value)}
                                            />
                                        </Form.Item>
                                    </DrawerRow>
                                    <DrawerRow>
                                    <div className="ant-form ant-form-vertical" style={{ display: 'flex' }}>
                                            <Form.Item
                                                label={fieldTranslate('start_date')}
                                                name="startDate"
                                                className="pr-1 w-1/2"
                                                labelCol={12}
                                                wrapperCol={12}
                                            >
                                                <DatePicker
                                                    format={
                                                        tabPreferences?.gantt_parameters?.hideHours ||
                                                        tabPreferences?.gantt_parameters?.hideHours === undefined
                                                        ? window.dateStringFormat
                                                        : window.dateStringFormat.concat(' HH:mm')
                                                    }
                                                    showTime={{ format: 'HH:mm' }}
                                                    onSelect={(value) => updateActivityData('startDate', value)}
                                                    onChange={(value) => updateActivityData('startDate', value)}
                                                    disabled={disabledFields.startDate}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={fieldTranslate('end_date')}
                                                name="endDate"
                                                className="pl-1 w-1/2"
                                                labelCol={12}
                                                wrapperCol={12}
                                                style={{ marginLeft: '33px' }}
                                            >
                                                <DatePicker
                                                    format={
                                                        tabPreferences?.gantt_parameters?.hideHours ||
                                                        tabPreferences?.gantt_parameters?.hideHours === undefined
                                                        ? window.dateStringFormat
                                                        : window.dateStringFormat.concat(' HH:mm')
                                                    }
                                                    showTime={{ format: 'HH:mm' }}
                                                    onSelect={(value) => updateActivityData('endDate', value)}
                                                    onChange={(value) => updateActivityData('endDate', value)}
                                                    disabled={disabledFields.endDate}
                                                />
                                            </Form.Item>
                                        </div>
                                    </DrawerRow>
                                    <DrawerRow>
                                        <Form.Item
                                            label={fieldTranslate('duration')}
                                            style={{ marginBottom: 0 }}
                                            labelCol={3}
                                            wrapperCol={21}
                                        >
                                            <Form.Item
                                                name="duration"
                                                style={{
                                                    display: 'inline-block',
                                                    width: 'calc(42% - 3px)',
                                                    marginRight: '8px',
                                                }}
                                            >
                                                <InputNumber
                                                    disabled={disabledFields.duration}
                                                    precision="1"
                                                    min={0}
                                                    onBlur={(event) =>
                                                        updateActivityData('duration', event.target.value)
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="dayDefinitionId"
                                                style={{ display: 'inline-block', width: 'calc(57% - 3px)' }}
                                            >
                                                <Select
                                                    options={timeUnits.reduce((agg, timeUnit) => {
                                                        agg.push({ label: timeUnit.name, value: timeUnit.id });

                                                        return agg;
                                                    }, [])}
                                                    onChange={(value) => updateActivityData('dayDefinitionId', value)}
                                                />
                                            </Form.Item>
                                        </Form.Item>
                                    </DrawerRow>
                                    <DrawerRow>
                                        <Form.Item
                                            name="progress"
                                            label={fieldTranslate('progress')}
                                            style={{
                                                display: 'inline-block',
                                                width: 'calc(42% - 3px)',
                                                marginRight: '8px',
                                            }}
                                            labelCol={{ span: 7 }}
                                            wrapperCol={24}
                                        >
                                            <Row>
                                                <Col span={19}>
                                                    <Slider
                                                        min={0}
                                                        max={100}
                                                        onAfterChange={onTaskProressChange}
                                                        defaultValue={
                                                            typeof taskProgress === 'number' ? taskProgress : 0
                                                        }
                                                    />
                                                </Col>
                                                <Col span={4}>
                                                    <InputNumber
                                                        min={0}
                                                        max={100}
                                                        precision="0"
                                                        formatter={(value) => Number(value).toFixed(0)}
                                                        value={taskProgress}
                                                        onChange={onTaskProressChange}
                                                        style={{ width: '80px' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </DrawerRow>

                                    <Collapse ghost className='activity-modification'>
                                        <Panel header={t('calcul_params')} >
                                            <DrawerRow>
                                                <Form.Item label={fieldTranslate('calcul')} name="champPMAutoId">
                                                    <Select
                                                        showSearch
                                                        options={champPmAutoOptions.reduce((agg, item) => {
                                                            agg.push({ label: item.name, value: item.id });

                                                            return agg;
                                                        }, [])}
                                                        filterOption={(input, option) =>
                                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        onChange={(value) =>
                                                            value
                                                                ? updateActivityData('champPMAutoId', value)
                                                                : updateActivityData('champPMAutoId', 0)
                                                        }
                                                        allowClear
                                                    >
                                                        <Option value="null">{t('no_calculation')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </DrawerRow>
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label={fieldTranslate('quantity')}
                                                        style={{ marginBottom: 0 }}
                                                        labelCol={{ span: 7 }}
                                                        wrapperCol={17}
                                                    >
                                                        <Form.Item
                                                            name="quantity"
                                                            style={{
                                                                display: 'inline-block',
                                                                width: 'calc(50% - 8px)',
                                                                marginRight: '8px',
                                                            }}
                                                        >
                                                            <InputNumber
                                                                onBlur={(event) =>
                                                                    updateActivityData('quantity', event.target.value)
                                                                }
                                                                disabled={disabledFields.quantity}
                                                                min={0}
                                                                controls={false}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="quantityUnit"
                                                            style={{ display: 'inline-block', width: '50%' }}
                                                        >
                                                            <Select
                                                                options={quantityUnits}
                                                                onChange={(value) =>
                                                                    updateActivityData('quantityUnit', value)
                                                                }
                                                                disabled={disabledFields.quantityUnit}
                                                            />
                                                        </Form.Item>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label={fieldTranslate('yield')}
                                                        style={{ marginBottom: 0 }}
                                                        labelCol={{ span: 7 }}
                                                        wrapperCol={17}
                                                    >
                                                        <Form.Item
                                                            name="yield"
                                                            style={{
                                                                display: 'inline-block',
                                                                width: 'calc(50% - 8px)',
                                                                marginRight: '8px',
                                                            }}
                                                        >
                                                            <InputNumber
                                                                onBlur={(event) =>
                                                                    updateActivityData('yield', event.target.value)
                                                                }
                                                                disabled={disabledFields.yield}
                                                                min={0}
                                                                type="number"
                                                                controls={false}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="yieldUnit"
                                                            style={{ display: 'inline-block', width: '50%' }}
                                                            initialValue={getYieldUnit(
                                                                form.getFieldValue('quantityUnit'),
                                                                form.getFieldValue('dayDefinitionId')
                                                            )}
                                                        >
                                                            <Input disabled />
                                                        </Form.Item>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>
                                    <h3>{t('custom_fields')}</h3>
                                    {activityCustomFields
                                        .filter((customField) => customField.name !== JOB_FIELD)
                                        .map((customField) => (
                                            <DrawerRow key={`custom-field-${customField.id}`}>
                                                {renderCustomField(customField)}
                                            </DrawerRow>
                                        ))}
                                    {activityCustomFields.length < 2 && (
                                        <h5>
                                            <br />
                                            {t('no_custom_fields')}
                                        </h5>
                                    )}
                                </Form>
                            </DrawerRow>
                        </div>
                    )}
                </Spin>
            </Modal>
        </Spin>
    );
};

MultipleActivityEdit.propTypes = {
    activitiesId: PropTypes.array.isRequired,
};

export default MultipleActivityEdit;
