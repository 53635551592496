import React, { createContext, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { flushSync } from 'react-dom';
import PrintTemplateService from '../../services/print-template.service';
import { UserContext } from './UserContext';
import { requestError } from '../../helpers/notification';

const PrintTemplatesMenuContext = createContext({
    selectedPrintTemplate: null,
    printTemplates: [],
    isModalVisible: false,
    modalContent: null,
    setSelectedPrintTemplate: () => {},
    setPrintTemplates: () => {},
    refreshPrintTemplate: () => {},
    setIsModalVisible: () => {},
    setModalContent: () => {},
});

const PrintTemplatesMenuContextProvider = ({ children }) => {
    const { t } = useTranslation();
    const [selectedPrintTemplate, setSelectedPrintTemplate] = useState(null);
    const [printTemplates, setPrintTemplates] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState();
    const { canFetchData } = useContext(UserContext);

    const getAllTemplates = async () => {
        try {
            const personalTemplates = await PrintTemplateService.listAllPersonalTemplate();
            const publicTemplates = await PrintTemplateService.listAllPublicTemplate();
            setPrintTemplates([...personalTemplates.map(i => ({...i, personal: true})), ...publicTemplates.map(i => ({...i, personal: false}))]);

        } catch(error) {
            requestError(error,  t('print_template_context.error_get_print_templates'));
        }
    };
    const refreshPrintTemplate = (action, object) => {
        const oldTemplates = [];
        flushSync(() => {
            setPrintTemplates((old) => {
                oldTemplates.push(...JSON.parse(JSON.stringify(old)));
                return old;
            });
        });
        switch (action) {
            case 'new': {
                oldTemplates.push(object);
                break;
            }
            case 'edit': {
                const indexTemplate = oldTemplates.findIndex((i) => i.id === object.id);
                if (indexTemplate > -1) {
                    oldTemplates[indexTemplate] = object;
                }
                break;
            }
            case 'delete': {
                const indexTemplate = oldTemplates.findIndex((i) => i.id === object.id);
                if (indexTemplate > -1) {
                    oldTemplates.splice(indexTemplate, 1);
                }
                break;
            }
            default:
        }
        setPrintTemplates(oldTemplates);
    };

    useEffect(() => {
        if (window._env_.REACT_APP_TEMPLATE_API) {
            getAllTemplates();
        }
    }, [canFetchData]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
        // reset context
        setModalContent();
    };

    return (
        <PrintTemplatesMenuContext.Provider
            value={{
                selectedPrintTemplate,
                setSelectedPrintTemplate,
                printTemplates,
                refreshPrintTemplate,
                isModalVisible,
                setIsModalVisible,
                modalContent,
                setModalContent,
                showModal,
                onModalClose,
                getAllTemplates,
            }}
        >
            {children}
        </PrintTemplatesMenuContext.Provider>
    );
};

PrintTemplatesMenuContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PrintTemplatesMenuContext, PrintTemplatesMenuContextProvider };
