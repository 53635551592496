import React, { useContext, useState, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Tooltip } from 'antd';
import styled from 'styled-components';
import { ReactComponent as FilterSvg } from '../../assets/icons/filter.svg';

import { PlanningContext } from '../../contexts/app/PlanningContext';
import { UserContext } from '../../contexts/app/UserContext';
import { TabContext } from '../../contexts/app/TabContext';
import PlanningFiltersTab from './PlanningFiltersTab';
import { notificationSuccess } from '../../helpers/notification';

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const FilterModal = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'filter_dropdown' });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filtersData, setFiltersData] = useState(null);

    const { planningSelected, refreshPlanningSelected } = useContext(PlanningContext);
    const { userPreferences, updateUserPreferences } = useContext(UserContext);
    const { updateActiveTabFilterData, activeTabFilterData } = useContext(TabContext);

    const [saving, setSaving] = useState(false);

    let hasBeenUpdated = false;

    const saveFilters = useCallback( async (saveOnly = false) => {
        if (filtersData) {
            const hasChanged =
                !isEqual(filtersData?.filters, userPreferences?.filters?.[planningSelected.id] || []) ||
                !isEqual(filtersData?.activeFilters, activeTabFilterData.activeFilters || []);
                console.log("🚀 ~ file: FilterModal.jsx:69 ~ saveFilters ~ hasBeenUpdated:", hasBeenUpdated)
                if (hasChanged) {
                console.log('saving filters');
                // saving filters into user preferences
                setSaving(true);
                // saving filters into user preferences
                await updateUserPreferences({
                    filters: {
                        ...userPreferences?.filters,
                        [planningSelected.id]: filtersData.filters,
                    },
                });
                // saving active filters into active tab data
                const filterDataUpdated = {
                    activeFilters: filtersData.activeFilters,
                    rawData: filtersData.filters.flatMap((filter) => {
                        if (filtersData.activeFilters.indexOf(filter.id) === -1) {
                            return [];
                        }
                        return filter;
                    }),
                };
                const shouldReload = !isEqual(filterDataUpdated.rawData, activeTabFilterData.rawData || []);
                await updateActiveTabFilterData(filterDataUpdated);
                notificationSuccess(t('filters'), t('saved_changes'));
                // only reload planning if rawData changed
                if ((shouldReload && !saveOnly)) {
                    refreshPlanningSelected();
                }
                hasBeenUpdated = true;
                setSaving(false);
            } else if (hasBeenUpdated) {
                refreshPlanningSelected();
            } 
        }
    }, [filtersData])

    const handleCancel = useCallback(async () => {
        if (filtersData) {
            await saveFilters();
        }
        hasBeenUpdated = false;
        setIsModalVisible(false);
    }, [filtersData]);

    const handleSaveChanges = async (filters, activeFilters) => {
        setFiltersData({ filters, activeFilters });
    };

    return (
        <>
            <Tooltip title={t('filters')}>
                <QuickButton
                    type="button"
                    className={`ant-dropdown-link inline-block text-center 
                    ${
                        isModalVisible || (activeTabFilterData.activeFilters || []).length > 0
                            ? 'bg-black text-primary'
                            : 'bg-primary'
                    }`}
                    title={t('filters')}
                    onClick={() => setIsModalVisible(true)}
                >
                    <FilterSvg className={`mx-auto ${saving ? ' animate-bounce' : ''}`} />
                </QuickButton>
            </Tooltip>
            <Modal
                title={`${t('filters')}`}
                className="filter-modal"
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={1500}
                height={700}
                maskClosable={false}
            >
                <Spin spinning={saving}>
                    <PlanningFiltersTab onSaveChanges={handleSaveChanges} commitChanges={() => saveFilters(true)} />
                </Spin>
            </Modal>
        </>
    );
};

export default FilterModal;
