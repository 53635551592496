import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { ReactComponent as ColumnsSvg } from '../../assets/icons/columns.svg';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import ColumnParams from './drawer/ColumnParams';


const ColumnDisplayFilter = () => {
    const {t} = useTranslation('translation', {keyPrefix: 'gantt_toolbar'})
    const [popoverVisible, setPopoverVisible] = useState(false);
    const { refreshPlanningSelected } = useContext(PlanningContext);

    const saveConfig = () => {
        setPopoverVisible(false);
        refreshPlanningSelected();
    }

    const content = (
        <div style={{ maxWidth:'600px'}}>

            <ColumnParams onOk={saveConfig} onCancel={() => setPopoverVisible(false)} />
        </div>
    );

    return (
        <div className="flex items-center h-100">
            <Popover
                placement="bottom"
                content={content}
                trigger="click"
                open={popoverVisible}
                onOpenChange={() => setPopoverVisible(!popoverVisible)}
                className="border-0 shadow-none bg-transparent"
            >
                <div className="flex items-center">
                    <ColumnsSvg /> {t('columns')}
                </div>
            </Popover>
        </div>
    );
};

export default ColumnDisplayFilter;
